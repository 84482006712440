
import React, { useEffect, useState } from 'react';
import { Col, Form, Input, Row } from 'antd';
import BaseSelect from '../../../components/Elements/BaseSelect';
import BaseRadioList from '../../../components/Elements/BaseRadios';
import FamilyRelationships from '../FamilyRelationships';
import { getCustomerSourceActive } from '../../../redux/actions/CustomerSourceActions';
import { getBranchActive } from '../../../redux/actions/BranchActions';
import { getCustomerGroupActive } from '../../../redux/actions/CustomerGroup';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerCardStatus } from '../../../utils/helpers';

const Interaction = (props) => {

    const { form } = props;

    const [customerFamily, setCustomerFamily] = useState([]);

    const customerSources = useSelector((state) => state.CustomerSourceReducer.customer_source_active_list);
    const customerStatus = useSelector((state) => state.CustomerStatusReducer.customerStatusActiveList);
    const customerInteractiveType = useSelector((state) => state.CustomerInteractiveTypeReducer.customerInteractiveTypeActiveList);
    const customerInteractiveStatus = useSelector((state) => state.CustomerInteractiveStatusReducer.customerInteractiveStatusActiveList);
    const customerGroups = useSelector((state) => state.CustomerGroup.active_list);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCustomerSourceActive());
        dispatch(getBranchActive());
        dispatch(getCustomerGroupActive());
    }, [dispatch]);

    form.setFieldsValue({
        customer_source_id: null,
        group_ids: [],
        customer_status_id: null,
        fb_Interactive_type_id: null,
        fb_Interactive_status_id: null,
        card_status: 'none'
    });

    const getCustomerFamily = data => {
        console.log(data)
        setCustomerFamily(data)
    }


    return (
        <div>
            <Row gutter={16}>
                <Col xs={24} md={6}>
                    <Form.Item
                        label="Nguồn"
                        name="customer_source_id"
                        rules={[{ required: true, message: 'Bắt buộc chọn' }]}
                    >
                        <BaseSelect
                            options={customerSources || []}
                            optionValue="id"
                            optionLabel="name"
                            showSearch
                            placeholder="Chọn một bản ghi"
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                    <Form.Item
                        label="Mã số sổ khám"
                        name="medical_book_number"
                        rules={[{ required: true, message: 'Bắt buộc nhập' }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                    <Form.Item
                        label="Mã số thẻ cũ"
                        name="card_number"
                        rules={[{ required: false, message: 'Bắt buộc nhập' }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                    <Form.Item
                        label="Nhóm"
                        name="group_ids"
                        rules={[{ required: false, message: 'Bắt buộc chọn' }]}
                    >
                        <BaseSelect
                            options={customerGroups ?? []}
                            optionValue="id"
                            optionLabel="name"
                            placeholder="Chọn 1 hoặc nhiều nhóm"
                            showSearch={true}
                            mode="multiple"
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={24}>
                    <Form.Item
                        label="Trạng thái thẻ"
                        name="card_status"
                        rules={[{ required: false, message: 'Bắt buộc nhập' }]}
                    >
                        <BaseRadioList
                            options={CustomerCardStatus ?? []}
                        />
                    </Form.Item>
                </Col>

                <Col xs={24} md={8}>
                    <Form.Item
                        label='Trạng thái khách hàng'
                        name="customer_status_id"
                        rules={[{ required: true, message: 'Bắt buộc chọn' }]}
                    >
                        <BaseSelect
                            options={customerStatus || []}
                            optionValue="id"
                            optionLabel="name"
                            showSearch
                            placeholder="Chọn một bản ghi"
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                    <Form.Item
                        label='Loại tương tác'
                        name="fb_Interactive_type_id"
                        rules={[{ required: true, message: 'Bắt buộc chọn' }]}
                    >
                        <BaseSelect
                            options={customerInteractiveType || []}
                            optionValue="id"
                            optionLabel="name"
                            showSearch
                            placeholder="Chọn một bản ghi"
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                    <Form.Item
                        label='Trạng thái tương tác'
                        name="fb_Interactive_status_id"
                        rules={[{ required: true, message: 'Bắt buộc chọn' }]}
                    >
                        <BaseSelect
                            options={customerInteractiveStatus || []}
                            optionValue="id"
                            optionLabel="name"
                            showSearch
                            placeholder="Chọn một bản ghi"
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                    <Form.Item
                        label="Tên facebook"
                        name="fb_name"
                        rules={[{ required: false, message: 'Bắt buộc nhập' }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                    <Form.Item
                        label="Link facebook"
                        name="fb_link"
                        rules={[{ required: false, message: 'Bắt buộc nhập' }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                    <Form.Item
                        label="Link fanpage"
                        name="fb_source"
                        rules={[{ required: false, message: 'Bắt buộc nhập' }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16} className='mb-5'>
                <Col xs={24} md={24}>
                    <FamilyRelationships getItems={(data) => getCustomerFamily(data)} />
                </Col>
            </Row>
        </div>
    )
}

export default Interaction;