import React, { useState, useRef, useEffect } from "react";
import { Typography } from "antd";

const { Paragraph } = Typography;

const ExpandableText = ({ text, rows = 2 }) => {
    const [expanded, setExpanded] = useState(false);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const contentRef = useRef(null);

    const countWords = (text) => {
        if (typeof text !== "string" || !text.trim()) return 0;
        return text.replace(/<[^>]*>/g, "").trim().split(/\s+/).length;
    };

    const checkOverflow = () => {
        if (contentRef.current) {
            const { scrollHeight, clientHeight } = contentRef.current;
            const newOverflowState = scrollHeight > clientHeight;
            if (newOverflowState !== isOverflowing) {
                setIsOverflowing(newOverflowState);
            }
        }
    };

    useEffect(() => {
        checkOverflow();

        const resizeObserver = new ResizeObserver(() => {
            requestAnimationFrame(checkOverflow);
        });

        if (contentRef.current) {
            resizeObserver.observe(contentRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [text]);

    useEffect(() => {
        const observer = new MutationObserver(() => {
            requestAnimationFrame(checkOverflow);
        });

        if (contentRef.current) {
            observer.observe(contentRef.current, { attributes: true, childList: true, subtree: true });
        }

        return () => {
            observer.disconnect();
        };
    }, []);

    const handleToggle = () => {
        setExpanded((prev) => !prev);
        requestAnimationFrame(checkOverflow);
    };

    const isTextLongEnough = countWords(text) >= 10;

    return (
        <div>
            <Paragraph
                ref={contentRef}
                style={{
                    marginBottom: 0,
                    maxHeight: !expanded ? `${rows * 1.5}em` : "none",
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitLineClamp: !expanded ? rows : "none",
                    WebkitBoxOrient: "vertical",
                }}
            >
                <span dangerouslySetInnerHTML={{ __html: text }} />
            </Paragraph>

            {isTextLongEnough && (expanded || isOverflowing) && (
                <span
                    style={{ color: "#1890ff", cursor: "pointer", marginLeft: 5 }}
                    onClick={handleToggle}
                >
                    {expanded ? "Thu gọn" : "Xem thêm"}
                </span>
            )}
        </div>
    );
};

export default ExpandableText;