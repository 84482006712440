import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Button, Row, Col, Drawer, Space, Alert } from 'antd';
import { isMobile } from 'react-device-detect';
import { updateAssignment } from '../../redux/actions/CommissionRevenue';
import BaseSelect from '../../components/Elements/BaseSelect';

class AssignmentsEmployeeForm extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            errorMsg: null
        };
    }

    async handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    this.setState({ isLoading: true });
                    values.type = 'employee';
                    await this.props.updateAssignment(this.props.currentItem.id, values);
                    this.onClose();

                    this.props.loadData()
                    this.setState({ isLoading: false });
                } catch (error) {
                    if (error.response && error.response.data) {
                        // Chuyển lỗi thành JSX để hỗ trợ HTML
                        const errorMsgJSX = (
                            <span dangerouslySetInnerHTML={{ __html: error.response.data.msg }} />
                        );
                        this.setState({ errorMsg: errorMsgJSX });
                    } else {
                        this.setState({ errorMsg: <span>Đã xảy ra lỗi, vui lòng thử lại!</span> });
                    }
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onClose() {
        this.setState({ errorMsg: null });
        this.props.onCancel();
        this.props.form.resetFields();
    }

    onSetMessage = () => {
        this.setState({ errorMsg: null });
    };


    render() {
        const { isLoading, errorMsg } = this.state;
        var { visible, currentItem, config } = this.props;
        const { getFieldDecorator } = this.props.form;

        return (
            <Drawer
                visible={visible}
                title="Cập nhật nhân viên áp dụng"
                onOk={(e) => this.handleSubmit(e)}
                onClose={() => this.onClose()}
                maskClosable={false}
                width={isMobile ? '100%' : '70%'}
                footer={
                    <div style={{ textAlign: 'right', }}>
                        <Space>
                            <Button key="back" onClick={() => this.onClose()}>
                                Huỷ
                            </Button>
                            <Button
                                loading={isLoading}
                                key="submit"
                                type="primary"
                                onClick={(e) => this.handleSubmit(e)}
                            >
                                Cập nhật
                            </Button>
                        </Space>
                    </div>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        {errorMsg && (
                            <Alert
                                message={errorMsg}
                                type='error'
                                showIcon
                                className="mb-2 mt-2"
                                closable
                                onClose={() => this.onSetMessage(null, null)}
                            />
                        )}
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Form.Item
                                    label="Nhân viên áp dụng"
                                >
                                    {getFieldDecorator('employee_ids', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Vui lòng chọn!',
                                            },
                                        ],
                                        initialValue: currentItem?.employee_ids ?? []
                                    })(
                                        <BaseSelect
                                            options={config?.users || []}
                                            optionValue="user_id"
                                            optionLabel="full_name"
                                            showSearch
                                            mode='multiple'
                                            placeholder="Chọn một hoặc nhiều bản ghi"
                                            style={{ width: '100%' }}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    : null
                }
            </Drawer>
        )
    }
}

function mapStateToProps(state) {
    return {
        config: state.config
    }
}

function mapDispatchToProps(dispatch) {
    return {
        updateAssignment: (id, data) => dispatch(updateAssignment(id, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'AssignmentsEmployeeForm' })(AssignmentsEmployeeForm));
