import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Button, Input, Space, Row, Col, DatePicker, Drawer } from 'antd';
import { UserOutlined } from "@ant-design/icons";
import { isMobile } from 'react-device-detect';
import BaseRadios from '../../components/Elements/BaseRadios';
import { updateAfterServiceCare } from '../../redux/actions/CustomerScheduleActions';
import { _newLine, afterServiceCare, isValidDateForm } from '../../utils/helpers';

class AfterServiceCareForm extends Component {
    constructor() {
        super();
        this.state = {

        };
    }

    async handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isLoading: true });
                try {
                    await this.props.updateAfterServiceCare(this.props.currentItem.id, values);
                    this.props.reloadData();
                    this.onCancel();
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onCancel() {
        this.props.form.resetFields();
        this.props.onCancel();
        this.setState({ isLoading: false });
    }

    render() {
        var { isLoading } = this.state;
        var { visible, currentItem } = this.props;
        const { getFieldDecorator, getFieldValue } = this.props.form;

        return (
            <Drawer
                visible={visible}
                title="Cập nhật kết quả CSKH"
                onClose={() => this.onCancel()}
                maskClosable={false}
                width={isMobile ? '100%' : '40%'}
                footer={
                    <div style={{ textAlign: 'right', }}>
                        <Space>
                            <Button key="back" onClick={() => this.onCancel()}>
                                Huỷ
                            </Button>
                            <Button
                                loading={isLoading}
                                key="submit"
                                type="primary"
                                onClick={(e) => this.handleSubmit(e)}
                            >
                                Cập nhật
                            </Button>
                        </Space>
                    </div>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={24} className='mb-3'>
                                <h6><UserOutlined /> Khách hàng: <span className='color-primary'>{currentItem?.customer_name} - {currentItem?.customer_code}</span></h6>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Form.Item label="CSKH sau dịch vụ">
                                    {getFieldDecorator('after_service_care', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vui lòng chọn!',
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.after_service_care : 1
                                    })(
                                        <BaseRadios
                                            optionValue="value"
                                            optionLabel="label"
                                            options={afterServiceCare || []}
                                        />
                                    )}
                                </Form.Item>
                            </Col>

                            {getFieldValue('after_service_care') !== 0 && (
                                <>
                                    <Col xs={24} md={24}>
                                        <Form.Item label="Thời điểm">
                                            {getFieldDecorator('care_updated_at', {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: 'Vui lòng chọn!',
                                                    },
                                                ],
                                                initialValue: null //isValidDateForm(currentItem.care_updated_at, true)
                                            })(
                                                <DatePicker
                                                    style={{ width: '100%' }}
                                                    showTime={{ format: 'HH:mm' }}
                                                    format="DD/MM/YYYY HH:mm"
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Form.Item label="Kết quả">
                                            {getFieldDecorator('after_service_care_note', {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: "Vui lòng điền!",
                                                    },
                                                ],
                                                initialValue: currentItem ? _newLine(currentItem.after_service_care_note) : ""
                                            })(
                                                <Input.TextArea rows={8} />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </>
                            )}
                        </Row>
                    </Form>
                    : null}
            </Drawer>
        )
    }
}

function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
        updateAfterServiceCare: (id, data) => dispatch(updateAfterServiceCare(id, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'AfterServiceCareForm' })(AfterServiceCareForm));
