import api from '../../utils/api';
import {
    GET_LIST_COMMISSION,
    GET_SPECIFIC_COMMISSION,
    UPDATE_SPECIFIC_COMMISSION,
    CREATE_NEW_COMMISSION,
    REMOVE_COMMISSION,
    COMMISSION_STATISTICAL
} from './types';
import { NotificationManager } from 'react-notifications';

export const getCommissions = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/commission', { params: filter }).then(res => {
            dispatch({ type: GET_LIST_COMMISSION, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getSpecificCommission = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/commission/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_COMMISSION, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createCommission = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/commission', data).then(res => {
            dispatch({ type: CREATE_NEW_COMMISSION, payload: res.data.data });
            NotificationManager.success("Thêm mới bản ghi thành công!");
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updateCommission = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/commission/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_COMMISSION, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeCommission = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/commission`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_COMMISSION, payload: ids });
            NotificationManager.success("Xoá bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}


export const getStatistical = (filter) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return api.get('/commission/statistical/total', { params: filter }).then(res => {
            dispatch({
                type: COMMISSION_STATISTICAL,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}