import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Button, Input, Space, Row, Col, DatePicker, Modal, Select } from 'antd';
import { } from "@ant-design/icons";
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import { _newLine } from '../../utils/helpers';
// actions
import { getAllVoucherByActive, voucherApplyCustomer, voucherCustomerUpdate } from '../../redux/actions/VoucherActions';

const { Option } = Select;

class VoucherApplyCustomerForm extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            voucherQuery: ''
        };
    }

    componentDidMount() {
        // this.props.getAllVoucherByActive(); // Load nặng không cần tải trước
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isLoading: true });
                try {
                    const { currentCustomer } = this.props;
                    values.used_date = values.used_date ? values.used_date.format("YYYY-MM-DD") : null;
                    values.customer_id = currentCustomer.id;
                    if (this.props.currentItem) {
                        await this.props.voucherCustomerUpdate(this.props.currentItem.used_id, values);
                        this.onClose();
                    } else {
                        await this.props.voucherApplyCustomer(values);
                        this.onClose();
                    }
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onClose() {
        this.props.form.resetFields();
        this.props.reloadData();
        this.props.onCancel();
        this.setState({
            isLoading: false
        });
    }

    onSearchVoucher(val) {
        this.setState({ voucherQuery: val }, () => {
            this.props.getAllVoucherByActive({ keyword: val });
        })
    }

    handleScroll(e) {
        this.props.getAllVoucherByActive({ keyword: this.state.voucherQuery, page: this.props.pagination.currentPage + 1 });
    }

    render() {
        var { isLoading } = this.state;
        var { visible, currentItem, currentCustomer, voucher_active_list } = this.props;
        const { getFieldDecorator } = this.props.form;
        //console.log(currentItem)
        return (
            <Modal
                visible={visible}
                title={`${currentItem ? "Sửa voucher" : "Thêm voucher"}: ${currentCustomer?.full_name} - ${currentCustomer?.code}`}
                onOk={(e) => this.submit(e)}
                onCancel={() => this.onClose()}
                maskClosable={false}
                width={isMobile ? '100%' : '50%'}
                footer={
                    <div style={{ textAlign: 'right', }}>
                        <Space>
                            <Button key="back" onClick={() => this.onClose()}>
                                Huỷ
                            </Button>
                            <Button
                                loading={isLoading}
                                key="submit"
                                type="primary"
                                onClick={(e) => this.submit(e)}
                            >
                                Cập nhật
                            </Button>
                        </Space>
                    </div>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        <Form.Item label={<>Voucher: </>}>
                            {getFieldDecorator("voucher_ids", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Vui lòng chọn!",
                                    },
                                ],
                                initialValue: currentItem ? currentItem?.voucher_ids : []
                            })(
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Chọn 1 hoặc nhiều voucher"
                                    optionLabelProp="label"
                                    allowClear={true}
                                    mode={currentItem && currentItem.voucher_ids ? '' : 'multiple'}
                                    showSearch={true}
                                    onPopupScroll={(e) => this.handleScroll(e)}
                                    onSearch={(val) => this.onSearchVoucher(val)}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {
                                        voucher_active_list && voucher_active_list.length ? (
                                            <>
                                                {
                                                    voucher_active_list.map((item) => {
                                                        return (
                                                            <Option key={item.id} value={item.id} label={item.name}>{item.name}</Option>
                                                        )
                                                    })
                                                }
                                            </>
                                        ) : null
                                    }
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label="Ngày khách hàng sử dụng">
                            {getFieldDecorator('used_date', {
                                rules: [
                                    {
                                        required: false,
                                        message: 'Vui lòng điền!',
                                    },
                                ],
                                initialValue: currentItem ? moment(currentItem.used_date).isValid() ? moment(currentItem.used_date) : null : ''
                            })(<DatePicker
                                format="DD/MM/YYYY"
                                style={{ width: '100%' }}
                                placeholder="Chọn ngày"
                            />)}
                        </Form.Item>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Form.Item label="Ghi chú">
                                    {getFieldDecorator('used_note', {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng điền!",
                                            },
                                        ],
                                        initialValue: currentItem ? _newLine(currentItem.used_note) : ""
                                    })(
                                        <Input.TextArea />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    : null}
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    return {
        voucher_active_list: state.VoucherReducer.voucher_active_list,
        pagination: state.VoucherReducer.pagination
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAllVoucherByActive: (filter) => dispatch(getAllVoucherByActive(filter)),
        voucherApplyCustomer: (data) => dispatch(voucherApplyCustomer(data)),
        voucherCustomerUpdate: (id, data) => dispatch(voucherCustomerUpdate(id, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'VoucherApplyCustomerForm' })(VoucherApplyCustomerForm));
