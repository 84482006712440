import React, { useState } from 'react';
import { Form, Input, Radio, Table } from 'antd';

const CheckDetailForm = (props) => {

    const {
        listName,
        data,
        columns = [],
        requiredColWidth = '300px',
        placeholder = "Ghi rõ chi tiết cụ thể tại đây",
        radioLabels = { yes: "Có, ghi rõ", no: "Không" }
    } = props;

    const [occurrenceVisible, setOccurrenceVisible] = useState({});

    const handleRadioChange = (index, value) => {
        setOccurrenceVisible(prev => ({ ...prev, [index]: value === "yes" }));
    };

    const defaultColumns = [];

    // Cột bắt buộc (nếu không có trong `columns`)
    const requiredColumns = [
        {
            title: "",
            dataIndex: "has_occurrence",
            width: requiredColWidth,
            render: (_, record, index) => (
                <Form.Item
                    name={[index, "has_occurrence"]}
                    initialValue={record.has_occurrence}
                    rules={[{ required: true, message: "Bắt buộc chọn" }]}
                >
                    <Radio.Group onChange={(e) => handleRadioChange(index, e.target.value)}>
                        <Radio value="no">{radioLabels.no}</Radio>
                        <Radio value="yes">{radioLabels.yes}</Radio>
                    </Radio.Group>
                </Form.Item>
            )
        }
    ];

    const occurrenceDetailColumn = {
        title: "",
        dataIndex: "occurrence_detail",
        render: (_, record, index) => (
            occurrenceVisible[index] && (
                <Form.Item
                    name={[index, "occurrence_detail"]}
                    rules={[{ required: true, message: "Bắt buộc nhập" }]}
                >
                    <Input.TextArea rows={1} placeholder={placeholder} />
                </Form.Item>
            )
        )
    };

    // Kiểm tra xem `has_occurrence` đã có trong `columns` chưa
    const hasOccurrenceColumn = columns.some(col => col.dataIndex === "has_occurrence");

    // Loại bỏ `occurrence_detail` khỏi danh sách `columns`
    const filteredColumns = columns.filter(col => col.dataIndex !== "occurrence_detail");

    // Hợp nhất cột:
    const mergedColumns = [
        ...filteredColumns, // Cột tùy chỉnh luôn đứng trước
        ...defaultColumns, // Cột mặc định (Loại)
        ...(hasOccurrenceColumn ? [] : requiredColumns), // Bổ sung `has_occurrence` nếu bị thiếu
        occurrenceDetailColumn // Đảm bảo "Chi tiết" luôn nằm cuối
    ];

    return (
        <Form.List name={listName}>
            {() => (
                <Table
                    showHeader={false}
                    dataSource={data}
                    columns={mergedColumns}
                    rowKey={(record, index) => index}
                    pagination={false}
                    size="small"
                    bordered={false}
                    scroll={{ x: 'max-content' }}
                    locale={{ emptyText: "Chưa có dữ liệu" }}
                />
            )}
        </Form.List>
    );
};

export default CheckDetailForm;


// import React, { useEffect, useState } from 'react';
// import { Form, Input, Radio, Table } from 'antd';


// const CheckDetailForm = (frops) => {

//     const { form, listName } = frops;

//     const [data, setData] = useState([]);
//     const [occurrenceVisible, setOccurrenceVisible] = useState({});

//     useEffect(() => {
//         setData([
//             { key: "allergy", label: "Dị ứng", occurrence_detail: "", has_occurrence: "no" },
//             { key: "personal", label: "Bản thân", occurrence_detail: "", has_occurrence: "no" },
//             { key: "family", label: "Gia đình", occurrence_detail: "", has_occurrence: "no" },
//         ]);
//         form.setFieldsValue({ PatientAllergyInfo: data });
//     }, [form]);


//     const columns = [
//         {
//             title: "",
//             dataIndex: "label",
//             width: '100px',
//             render: (_, record, index) => (
//                 <>
//                     <span> {record.label}</span >
//                     <Form.Item name={[index, "label"]} initialValue={record.label} hidden>
//                         <Input />
//                     </Form.Item>
//                 </>
//             )
//         },
//         {
//             title: "",
//             dataIndex: "has_occurrence",
//             width: '200px',
//             render: (_, record, index) => (
//                 <Form.Item name={[index, "has_occurrence"]} initialValue={record.has_occurrence} rules={[{ required: true, message: "Bắt buộc chọn" }]}>
//                     <Radio.Group
//                         onChange={(e) => {
//                             setOccurrenceVisible(prev => ({ ...prev, [index]: e.target.value === "yes" }));
//                         }}
//                     >
//                         <Radio value="no">Không</Radio>
//                         <Radio value="yes">Có</Radio>
//                     </Radio.Group>
//                 </Form.Item>
//             )
//         },
//         {
//             title: "",
//             dataIndex: "occurrence_detail",
//             render: (_, record, index) => (
//                 occurrenceVisible[index] && (
//                     <Form.Item name={[index, "occurrence_detail"]} rules={[{ required: true, message: "Bắt buộc nhập" }]}>
//                         <Input placeholder="Ghi rõ" />
//                     </Form.Item>
//                 )
//             )
//         }
//     ];

//     return (
//         <>
//             <Form.List
//                 name={listName}
//             >
//                 {() => (
//                     <>
//                         <Table
//                             showHeader={false}
//                             dataSource={data}
//                             columns={columns}
//                             rowKey={(record, index) => index}
//                             pagination={false}
//                             size='small'
//                             bordered={false}
//                             locale={{ emptyText: "Chưa có dữ liệu" }}
//                             scroll={{
//                                 x: 'max-content'
//                             }}
//                         />
//                     </>
//                 )}
//             </Form.List>
//         </>
//     )
// }

// export default CheckDetailForm;