import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Drawer, Descriptions, Divider, Button, Row, Col, Space, Typography, Tag, Alert, Table } from 'antd'
import { EditOutlined } from '@ant-design/icons';
import { checkPermission } from '../../utils/permission';
import ReactHtmlParser from 'react-html-parser';
import { ConvertCustomerScheduleType, isValidDate, ConvertCustomerScheduleStatus, RenderPaymentMethods, RenderAfterServiceCare } from '../../utils/helpers';
import { isMobile } from 'react-device-detect';
import CustomerScheduleForm from './CustomerScheduleForm';
import CustomerScheduleUpdateStatusForm from './CustomerScheduleUpdateStatusForm';
import HtmlParser from 'react-html-parser';
import NumberFormat from 'react-number-format';

const { Paragraph } = Typography;

class CustomerScheduleDetail extends Component {

    constructor() {
        super();
        this.state = {
            isOpenEditForm: false,
            isOpenStatusForm: false
        };
    }

    handleCancel() {
        this.props.onCancel();
    }

    toggleOpenEditForm(isOpenEditForm) {
        this.setState({ isOpenEditForm: isOpenEditForm });
    }

    toggleOpenUpdateStatusForm(isOpenStatusForm) {
        this.setState({ isOpenStatusForm: isOpenStatusForm });
    }

    reloadData() {
        this.props.reloadData();
        this.props.reloadData2();
    }

    render() {
        var { isOpenEditForm, isOpenStatusForm } = this.state;
        var { visible, currentItem } = this.props;

        const createColumns = (timeField, resultField) => [
            {
                title: 'Thời điểm tư vấn',
                dataIndex: timeField,
                key: timeField,
                width: '140px',
                render: (text) => {
                    return text ? (
                        <div>{isValidDate(text)}</div>
                    ) : (
                        <Tag>N/A</Tag>
                    );
                }
            },
            {
                title: 'Kết quả tư vấn',
                dataIndex: resultField,
                key: resultField,
                width: '300px',
                maxWidth: '300px',
                render: (text, record) => {
                    return <>{HtmlParser(record[resultField])}</>;
                }
            }
        ];

        const columns = createColumns('time_telesale', 'result_content');
        const columns2 = createColumns('time_consultant', 'result_content');

        const payment = currentItem?.latest_customer_payment || {};
        const { payment_method, installment_months, monthly_payment } = payment;
        const isInstallment = ["tragoptainhakhoa", "tragoptainganhang"].includes(payment_method);

        return (
            <>
                <Drawer
                    visible={visible}
                    title={`Chi tiết lịch hẹn`}
                    onClose={() => this.handleCancel()}
                    maskClosable={false}
                    width={isMobile ? '100%' : '55%'}
                    extra={
                        <Space style={{ flexWrap: 'nowrap' }}>
                            {
                                checkPermission('customer_schedule', 'update') ? (
                                    <Button type="primary" onClick={() => this.toggleOpenEditForm(true)} icon={<EditOutlined />}>Sửa</Button>
                                ) : null
                            }
                            {
                                checkPermission('customer_schedule', 'update_status') ? (
                                    <Button disabled={currentItem?.status == 'dachuyenlich'} type="primary" onClick={() => this.toggleOpenUpdateStatusForm(true)} icon={<EditOutlined />}>Cập nhật trạng thái</Button>
                                ) : null
                            }
                        </Space>
                    }
                >
                    {visible ? (
                        <div>
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Kiểu lịch hẹn">
                                            {currentItem ? ConvertCustomerScheduleType(currentItem.name) : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Tên khách hàng">
                                            {currentItem ? currentItem.customer_name : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Mã khách hàng">
                                            <Paragraph style={{ margin: 0 }} copyable={
                                                {
                                                    text: currentItem ? currentItem.customer_code : null,
                                                    tooltips: false
                                                }
                                            }>
                                                <Tag color='#1890ff'>{currentItem ? currentItem.customer_code : null}</Tag>
                                            </Paragraph>
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Cơ sở">
                                            {currentItem ? currentItem.branch_name : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Thời gian">
                                            {isValidDate(currentItem.time)}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label={`Thời điểm ${currentItem.name == 'GOILAI' ? 'gọi' : 'đến'}`}>
                                            {isValidDate(currentItem?.time_to)}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Trạng thái">
                                            {currentItem ? ConvertCustomerScheduleStatus(currentItem.status) : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Hình thức thanh toán">
                                            <span>{RenderPaymentMethods(payment_method)}</span>
                                            {isInstallment && (
                                                <span style={{ fontStyle: 'italic' }}>
                                                    {installment_months} tháng,&nbsp;
                                                    <NumberFormat value={monthly_payment} displayType="text" suffix="đ" thousandSeparator />
                                                    /tháng
                                                </span>
                                            )}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Cập nhật trạng thái">
                                            {currentItem ? currentItem.updated_user : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Nhân viên tư vấn trực tiếp">
                                            {currentItem ? currentItem?.staff_consultant_direct : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Bác sĩ thực hiện">
                                            {currentItem ? currentItem?.doctors : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="CSKH sau dịch vụ">
                                            {currentItem ? RenderAfterServiceCare(currentItem.after_service_care) : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Ngày CSKH">
                                            {isValidDate(currentItem.care_updated_at)}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Nhân viên CSKH">
                                            {currentItem ? currentItem?.care_updated_user?.full_name : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                            </Row>
                            <Row gutter={16} className='mt-2'>
                                <Col xs={24} md={24}>
                                    <h6 className="h6-title">Kết quả CSKH:</h6>
                                    {currentItem ? ReactHtmlParser(currentItem.after_service_care_note) : null}
                                    <Divider className="no-margin mt-2" />
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Ngày tạo">
                                            {isValidDate(currentItem.created_at)}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Người tạo">
                                            {currentItem ? currentItem.created_user : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Col>
                            </Row>
                            <Row gutter={16} className='mt-3'>
                                <Col xs={24} md={24}>
                                    <Alert
                                        message={<span style={{ fontWeight: '600' }}>Ghi chú khách hàng</span>}
                                        description={currentItem ? ReactHtmlParser(currentItem.customer_note) : null}
                                        type="info"
                                        className='fix-alert-element'
                                    />
                                </Col>
                            </Row>
                            <Row gutter={16} className='mt-3'>
                                <Col xs={24} md={24}>
                                    <h6 className="h6-title">Ghi chú:</h6>
                                    {currentItem ? ReactHtmlParser(currentItem.note) : null}
                                    <Divider className="no-margin mt-3" />
                                </Col>
                            </Row>
                            <Table
                                className='mt-4 custom-table'
                                title={() => <>Kết quả telesale</>}
                                rowKey="id"
                                size="small"
                                dataSource={currentItem?.telesale}
                                columns={columns}
                                pagination={false}
                                scroll={{
                                    x: 'max-content'
                                }}
                                locale={{ emptyText: "Chưa có dữ liệu" }}
                            />
                            <Table
                                className='mt-4 mb-4  custom-table'
                                title={() => <>Kết quả tư vấn trực tiếp</>}
                                rowKey="id"
                                size="small"
                                dataSource={currentItem?.consultant_direct}
                                columns={columns2}
                                pagination={false}
                                scroll={{
                                    x: 'max-content'
                                }}
                                locale={{ emptyText: "Chưa có dữ liệu" }}
                            />
                        </div>
                    ) : null}

                </Drawer>
                <CustomerScheduleForm
                    visible={isOpenEditForm}
                    currentItem={currentItem}
                    reloadData={() => this.props.reloadData()}
                    onCancel={() => this.toggleOpenEditForm(false)}
                />
                <CustomerScheduleUpdateStatusForm
                    visible={isOpenStatusForm}
                    currentItem={currentItem}
                    reloadData={() => this.reloadData()}
                    onCancel={() => this.toggleOpenUpdateStatusForm(false)}
                />
            </>
        )
    }
}
function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CustomerScheduleDetail' })(CustomerScheduleDetail));
