import api from '../../utils/api';
import {
    GET_LIST_CUSTOMER,
    GET_ALL_CUSTOMERS_WITH_CONCATENATE,
    GET_SPECIFIC_CUSTOMER,
    GET_SPECIFIC_CUSTOMER_BY_FB_NAME,
    UPDATE_SPECIFIC_CUSTOMER,
    CREATE_NEW_CUSTOMER,
    REMOVE_CUSTOMER,
    CREATE_NEW_CUSTOMER_FROM_CHAT,
    GET_LIST_CUSTOMER_ACTIVE,
    UPDATE_SPECIFIC_CUSTOMER_MEDIA,
    TRANSFER_CUSTOMERS_TO_STAFF,
    IMPORT_EXCEL_CUSTOMER,
    GET_PAYMENT_RECEIPT_CUSTOMER,
    GET_STATISTICAL_CUSTOMER,
    CUSTOMER_EXPORT_EXCEL
} from './types';
import { NotificationManager } from 'react-notifications';

export const getListCustomer = (filter, concatenate = false) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/customer', { params: filter }).then(res => {
            if (!concatenate) {
                dispatch({
                    type: GET_LIST_CUSTOMER,
                    payload: res.data.data
                });
            } else {
                dispatch({
                    type: GET_ALL_CUSTOMERS_WITH_CONCATENATE,
                    payload: res.data.data
                });
            }
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response?.data?.msg);
            reject(err);
        })
    })
}

export const getListCustomerActive = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/customer/by-active', { params: filter }).then(res => {
            dispatch({
                type: GET_LIST_CUSTOMER_ACTIVE,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response?.data?.msg);
            reject(err);
        })
    })
}

export const getSpecificCustomer = (id, filter = {}) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/customer/${id}`, { params: filter }).then(res => {
            dispatch({ type: GET_SPECIFIC_CUSTOMER, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response?.data?.msg);
            reject(err);
        })
    })
}

export const getCustomerByFacebookName = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/customer/facebook/name`, { params: filter }).then(res => {
            dispatch({ type: GET_SPECIFIC_CUSTOMER_BY_FB_NAME, payload: res.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response?.data?.msg);
            reject(err);
        })
    })
}

export const createCustomer = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/customer', data).then(res => {
            dispatch({ type: CREATE_NEW_CUSTOMER, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response?.data?.msg);
            reject(err);
        })
    })
}

export const createCustomerFromChat = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/customer/create/from-chat', data).then(res => {
            dispatch({ type: CREATE_NEW_CUSTOMER_FROM_CHAT, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response?.data?.msg);
            reject(err);
        })
    })
}

export const updateCustomer = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/customer/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_CUSTOMER, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response?.data?.msg);
            reject(err);
        })
    })
}

export const updateCustomerProfile = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/customer/update/profile/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_CUSTOMER, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response?.data?.msg);
            reject(err);
        })
    })
}

export const updateCustomerFromChat = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/customer/update/from-chat`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_CUSTOMER, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response?.data?.msg);
            reject(err);
        })
    })
}

export const removeCustomer = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/customer`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_CUSTOMER, payload: ids });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response?.data?.msg);
            reject(err);
        })
    })
}

export const updateCustomerMedia = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/customer/media/update/${id}`).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_CUSTOMER_MEDIA, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response?.data?.msg);
            reject(err);
        })
    })
}

export const transferCustomerToStaff = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/customer/transfer-to-staff', data).then(res => {
            dispatch({ type: TRANSFER_CUSTOMERS_TO_STAFF, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response?.data?.msg);
            reject(err);
        })
    })
}

export const customerImportExcel = (data, header) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/customer/import', data, header).then((res) => {
            dispatch({
                type: IMPORT_EXCEL_CUSTOMER,
                payload: res,
            });
            resolve(res);
        }).catch(err => {
            NotificationManager.error(err.response?.data?.msg);
            reject(err);
        });
    });
}

// Payment history by cuatomer_id
export const getListPaymentReceiptCustomer = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/customer/payment/history/${id}`).then(res => {
            dispatch({ type: GET_PAYMENT_RECEIPT_CUSTOMER, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response?.data?.msg);
            reject(err);
        })
    })
}

export const getStatistical = (filter) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return api.get('/customer/statistical/total', { params: filter }).then(res => {
            //console.log(res.data.data)
            dispatch({
                type: GET_STATISTICAL_CUSTOMER,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response?.data?.msg);
            reject(err);
        });
    });
}

export const exportExcel = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post(`/customer/export`, filter).then(res => {
            dispatch({ type: CUSTOMER_EXPORT_EXCEL, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            console.log(err.response);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    })
}