import api from '../../utils/api';
import {
    GET_LIST_COMMISSION_RULE,
    GET_SPECIFIC_COMMISSION_RULE,
    UPDATE_SPECIFIC_COMMISSION_RULE,
    CREATE_NEW_COMMISSION_RULE,
    REMOVE_COMMISSION_RULE,
} from './types';
import { NotificationManager } from 'react-notifications';

export const getCommissionRules = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/commission-rule', { params: filter }).then(res => {
            dispatch({ type: GET_LIST_COMMISSION_RULE, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getSpecificCommissionRule = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/commission-rule/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_COMMISSION_RULE, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createCommissionRule = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/commission-rule', data).then(res => {
            dispatch({ type: CREATE_NEW_COMMISSION_RULE, payload: res.data.data });
            NotificationManager.success("Thêm mới bản ghi thành công!");
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updateCommissionRule = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/commission-rule/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_COMMISSION_RULE, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updateAssignment = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/commission-rule/assignment/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_COMMISSION_RULE, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            //NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeCommissionRule = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/commission-rule`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_COMMISSION_RULE, payload: ids });
            NotificationManager.success("Xoá bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}
