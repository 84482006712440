import api from '../../utils/api';
import {
    GET_ADDITINOAL_COST_CUSTOMER,
    REMOVE_ADDITINOAL_COST,
    CREATE_ADDITINOAL_COST,
    UPDATE_SPECIFIC_ADDITINOAL_COST,
    GET_SPECIFIC_ADDITINOAL_COST
} from './types';
import { NotificationManager } from 'react-notifications';

export const getAdditionalCostCustomer = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/additional-cost/customer/${id}`).then(res => {
            dispatch({ type: GET_ADDITINOAL_COST_CUSTOMER, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response?.data?.msg);
            reject(err);
        })
    })
}

export const createAdditionalCost = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/additional-cost', data).then(res => {
            dispatch({ type: CREATE_ADDITINOAL_COST, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response?.data?.msg);
            reject(err);
        })
    })
}

export const updateAdditionalCost = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/additional-cost/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_ADDITINOAL_COST, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response?.data?.msg);
            reject(err);
        })
    })
}

export const getSpecificAdditionalCost = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/additional-cost/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_ADDITINOAL_COST, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response?.data?.msg);
            reject(err);
        })
    })
}

export const removeAdditionalCost = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/additional-cost`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_ADDITINOAL_COST, payload: ids });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response?.data?.msg);
            reject(err);
        })
    })
}
