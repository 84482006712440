// auth
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const GET_AUTH_USER = 'GET_AUTH_USER';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const UPDATE_AUTH_USER = 'UPDATE_AUTH_USER';
export const CHANGE_PIN_CODE = "CHANGE_PIN_CODE";
export const RESET_PIN_CODE = "RESET_PIN_CODE";

// User Actions
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_SPECIFIC_USER = "GET_SPECIFIC_USER";
export const CREATE_NEW_USER = "CREATE_NEW_USER";
export const UPDATE_SPECIFIC_USER = "UPDATE_SPECIFIC_USER";
export const REMOVE_USERS = "REMOVE_USERS";

// permission
export const GET_ALL_PERMISSIONS = "GET_ALL_PERMISSIONS";
export const UPDATE_ROLE_PERMISSIONS = "UPDATE_ROLE_PERMISSIONS";
export const GET_PERMISSIONS_BY_ROLE = "GET_PERMISSIONS_BY_ROLE";
export const GET_PERMISSIONS_BY_USER = "GET_PERMISSIONS_BY_USER";

// role
export const GET_ALL_ROLES = "GET_ALL_ROLES";
export const CREATE_NEW_ROLE = "CREATE_NEW_ROLE";
export const UPDATE_SPECIFIC_ROLE = "UPDATE_SPECIFIC_ROLE";
export const REMOVE_ROLES = "REMOVE_ROLES";
export const GET_SPECIFIC_ROLE = "GET_SPECIFIC_ROLE";

// config
export const GET_CONFIG = "GET_CONFIG";
export const SET_CONFIG = "SET_CONFIG";

// Service
export const GET_LIST_SERVICE = "GET_LIST_SERVICE";
export const GET_SPECIFIC_SERVICE = "GET_SPECIFIC_SERVICE";
export const UPDATE_SPECIFIC_SERVICE = "UPDATE_SPECIFIC_SERVICE";
export const CREATE_NEW_SERVICE = "CREATE_NEW_SERVICE";
export const REMOVE_SERVICE = "REMOVE_SERVICE";
export const GET_SERVICE_ACTIVE = "GET_SERVICE_ACTIVE";

// Danh sách khách hàng
export const GET_LIST_CUSTOMER = "GET_LIST_CUSTOMER";
export const GET_ALL_CUSTOMERS_WITH_CONCATENATE = "GET_ALL_CUSTOMERS_WITH_CONCATENATE";
export const GET_SPECIFIC_CUSTOMER = "GET_SPECIFIC_CUSTOMER";
export const GET_SPECIFIC_CUSTOMER_BY_FB_NAME = "GET_SPECIFIC_CUSTOMER_BY_FB_NAME";
export const UPDATE_SPECIFIC_CUSTOMER = "UPDATE_SPECIFIC_CUSTOMER";
export const CREATE_NEW_CUSTOMER = "CREATE_NEW_CUSTOMER";
export const REMOVE_CUSTOMER = "REMOVE_CUSTOMER";
export const CREATE_NEW_CUSTOMER_FROM_CHAT = "CREATE_NEW_CUSTOMER_FROM_CHAT";
export const GET_LIST_CUSTOMER_ACTIVE = "GET_LIST_CUSTOMER_ACTIVE";
export const UPDATE_SPECIFIC_CUSTOMER_MEDIA = "UPDATE_SPECIFIC_CUSTOMER_MEDIA";
export const TRANSFER_CUSTOMERS_TO_STAFF = "TRANSFER_CUSTOMERS_TO_STAFF"
export const IMPORT_EXCEL_CUSTOMER = "IMPORT_EXCEL_CUSTOMER";
export const GET_PAYMENT_RECEIPT_CUSTOMER = "GET_PAYMENT_RECEIPT_CUSTOMER";
export const GET_STATISTICAL_CUSTOMER = "GET_STATISTICAL_CUSTOMER";
export const CUSTOMER_EXPORT_EXCEL = "CUSTOMER_EXPORT_EXCEL";

// Customer payment
export const GET_CUSTOMER_PAYMENT_CUSTOMER = "GET_CUSTOMER_PAYMENT_CUSTOMER";
export const REMOVE_CUSTOMER_PAYMENT = "REMOVE_CUSTOMER_PAYMENT";
export const CREATE_CUSTOMER_PAYMENT = "CREATE_CUSTOMER_PAYMENT";
export const UPDATE_SPECIFIC_CUSTOMER_PAYMENT = "UPDATE_SPECIFIC_CUSTOMER_PAYMENT";
export const GET_SPECIFIC_CUSTOMER_PAYMENT = "GET_SPECIFIC_CUSTOMER_PAYMENT";

// Nhóm khách hàng
export const GET_LIST_CUSTOMER_GROUP = "GET_LIST_CUSTOMER_GROUP";
export const GET_SPECIFIC_CUSTOMER_GROUP = "GET_SPECIFIC_CUSTOMER_GROUP";
export const UPDATE_SPECIFIC_CUSTOMER_GROUP = "UPDATE_SPECIFIC_CUSTOMER_GROUP";
export const CREATE_NEW_CUSTOMER_GROUP = "CREATE_NEW_CUSTOMER_GROUP";
export const REMOVE_CUSTOMER_GROUP = "REMOVE_CUSTOMER_GROUP";
export const GET_CUSTOMER_GROUP_ACTIVE = "GET_CUSTOMER_GROUP_ACTIVE";

// Form dữ liệu khách hàng
export const UPDATE_SPECIFIC_CUSTOMER_DATA = "UPDATE_SPECIFIC_CUSTOMER_DATA";
export const CREATE_NEW_CUSTOMER_DATA = "CREATE_NEW_CUSTOMER_DATA";

// Tra cứu khách hàng
export const LOOKUP_GET_LIST_CUSTOMER = "LOOKUP_GET_LIST_CUSTOMER";

// Tra cứu khách hàng phụ tá
export const LIST_CUSTOMER_DOCTOR_ASSISTANT = "LIST_CUSTOMER_DOCTOR_ASSISTANT";


// Lich hen
export const GET_LIST_CUSTOMER_SCHEDULE = "GET_LIST_CUSTOMER_SCHEDULE";
export const GET_SPECIFIC_CUSTOMER_SCHEDULE = "GET_SPECIFIC_CUSTOMER_SCHEDULE";
export const UPDATE_SPECIFIC_CUSTOMER_SCHEDULE = "UPDATE_SPECIFIC_CUSTOMER_SCHEDULE";
export const CREATE_NEW_CUSTOMER_SCHEDULE = "CREATE_NEW_CUSTOMER_SCHEDULE";
export const REMOVE_CUSTOMER_SCHEDULE = "REMOVE_CUSTOMER_SCHEDULE";
export const UPDATE_SPECIFIC_CUSTOMER_SCHEDULE_STATUS = "UPDATE_SPECIFIC_CUSTOMER_SCHEDULE_STATUS";
export const TRANSFER_SCHEDULE_TO_STAFF = "TRANSFER_SCHEDULE_TO_STAFF";
export const GET_SCHEDULE_LIST_BY_CUSTOMER = "GET_SCHEDULE_LIST_BY_CUSTOMER";
export const CHANGE_CUSTOMER_SCHEDULE = "CHANGE_CUSTOMER_SCHEDULE";
export const UPDATE_SPECIFIC_AFTER_SERVICE_CARE = "UPDATE_SPECIFIC_AFTER_SERVICE_CARE";

// Hạng khách hàng
export const GET_LIST_CUSTOMER_CLASS = "GET_LIST_CUSTOMER_CLASS";
export const GET_SPECIFIC_CUSTOMER_CLASS = "GET_SPECIFIC_CUSTOMER_CLASS";
export const UPDATE_SPECIFIC_CUSTOMER_CLASS = "UPDATE_SPECIFIC_CUSTOMER_CLASS";
export const CREATE_NEW_CUSTOMER_CLASS = "CREATE_NEW_CUSTOMER_CLASS";
export const REMOVE_CUSTOMER_CLASS = "REMOVE_CUSTOMER_CLASS";
export const GET_CUSTOMER_CLASS_ACTIVE = "GET_CUSTOMER_CLASS_ACTIVE";

// Quan hệ gia đình
export const GET_LIST_RELATIONSHIP_TYPES = "GET_LIST_RELATIONSHIP_TYPES";
export const GET_SPECIFIC_RELATIONSHIP_TYPES = "GET_SPECIFIC_RELATIONSHIP_TYPES";
export const UPDATE_SPECIFIC_RELATIONSHIP_TYPES = "UPDATE_SPECIFIC_RELATIONSHIP_TYPES";
export const CREATE_NEW_RELATIONSHIP_TYPES = "CREATE_NEW_RELATIONSHIP_TYPES";
export const REMOVE_RELATIONSHIP_TYPES = "REMOVE_RELATIONSHIP_TYPES";
export const GET_RELATIONSHIP_TYPES_ACTIVE = "GET_RELATIONSHIP_TYPES_ACTIVE";

// Trạng thái khách hàng
export const GET_LIST_CUSTOMER_STATUS = "GET_LIST_CUSTOMER_STATUS";
export const GET_SPECIFIC_CUSTOMER_STATUS = "GET_SPECIFIC_CUSTOMER_STATUS";
export const UPDATE_SPECIFIC_CUSTOMER_STATUS = "UPDATE_SPECIFIC_CUSTOMER_STATUS";
export const CREATE_NEW_CUSTOMER_STATUS = "CREATE_NEW_CUSTOMER_STATUS";
export const REMOVE_CUSTOMER_STATUS = "REMOVE_CUSTOMER_STATUS";
export const GET_CUSTOMER_STATUS_ACTIVE = "GET_CUSTOMER_STATUS_ACTIVE";

// Trạng thái tương tác khách hàng
export const GET_LIST_CUSTOMER_INTERACTIVE_STATUS = "GET_LIST_CUSTOMER_INTERACTIVE_STATUS";
export const GET_SPECIFIC_CUSTOMER_INTERACTIVE_STATUS = "GET_SPECIFIC_CUSTOMER_INTERACTIVE_STATUS";
export const UPDATE_SPECIFIC_CUSTOMER_INTERACTIVE_STATUS = "UPDATE_SPECIFIC_CUSTOMER_INTERACTIVE_STATUS";
export const CREATE_NEW_CUSTOMER_INTERACTIVE_STATUS = "CREATE_NEW_CUSTOMER_INTERACTIVE_STATUS";
export const REMOVE_CUSTOMER_INTERACTIVE_STATUS = "REMOVE_CUSTOMER_INTERACTIVE_STATUS";
export const GET_CUSTOMER_INTERACTIVE_STATUS_ACTIVE = "GET_CUSTOMER_INTERACTIVE_STATUS_ACTIVE";

// Loại tương tác
export const GET_LIST_CUSTOMER_INTERACTIVE_TYPE = "GET_LIST_CUSTOMER_INTERACTIVE_TYPE";
export const GET_SPECIFIC_CUSTOMER_INTERACTIVE_TYPE = "GET_SPECIFIC_CUSTOMER_INTERACTIVE_TYPE";
export const UPDATE_SPECIFIC_CUSTOMER_INTERACTIVE_TYPE = "UPDATE_SPECIFIC_CUSTOMER_INTERACTIVE_TYPE";
export const CREATE_NEW_CUSTOMER_INTERACTIVE_TYPE = "CREATE_NEW_CUSTOMER_INTERACTIVE_TYPE";
export const REMOVE_CUSTOMER_INTERACTIVE_TYPE = "REMOVE_CUSTOMER_INTERACTIVE_TYPE";
export const GET_CUSTOMER_INTERACTIVE_TYPE_ACTIVE = "GET_CUSTOMER_INTERACTIVE_TYPE_ACTIVE";

// Nguồn khách hàng
export const GET_LIST_CUSTOMER_SOURCE = "GET_LIST_CUSTOMER_SOURCE";
export const GET_SPECIFIC_CUSTOMER_SOURCE = "GET_SPECIFIC_CUSTOMER_SOURCE";
export const UPDATE_SPECIFIC_CUSTOMER_SOURCE = "UPDATE_SPECIFIC_CUSTOMER_SOURCE";
export const CREATE_NEW_CUSTOMER_SOURCE = "CREATE_NEW_CUSTOMER_SOURCE";
export const REMOVE_CUSTOMER_SOURCE = "REMOVE_CUSTOMER_SOURCE";
export const GET_CUSTOMER_SOURCE_ACTIVE = "GET_CUSTOMER_SOURCE_ACTIVE";

// Page manager
export const GET_LIST_PAGE = "GET_LIST_PAGE";
export const GET_SPECIFIC_PAGE = "GET_SPECIFIC_PAGE";
export const CONNECT_PAGE = "CONNECT_PAGE";
export const INSERT_INFO_PAGE = "INSERT_INFO_PAGE";
export const GET_PAGE_BY_PAGEID = "GET_PAGE_BY_PAGEID";
export const UPDATE_STATTUS_PAGE = "UPDATE_STATTUS_PAGE";
export const GET_LIST_PAGE_ACTIVE = "GET_LIST_PAGE_ACTIVE";
export const UPDATE_SPECIFIC_PAGE = "UPDATE_SPECIFIC_PAGE";

// Regions
export const GET_LIST_PROVINCES = "GET_LIST_PROVINCES";
export const GET_LIST_DISTRICTS = "GET_LIST_DISTRICTS";
export const GET_LIST_WARDS = "GET_LIST_WARDS";

// Stringee
export const STRINGEE_GET_TOKEN = "STRINGEE_GET_TOKEN";

//  Quản lý cơ sở
export const GET_LIST_BRANCH = "GET_LIST_BRANCH";
export const GET_SPECIFIC_BRANCH = "GET_SPECIFIC_BRANCH";
export const UPDATE_SPECIFIC_BRANCH = "UPDATE_SPECIFIC_BRANCH";
export const CREATE_NEW_BRANCH = "CREATE_NEW_BRANCH";
export const REMOVE_BRANCH = "REMOVE_BRANCH";
export const GET_BRANCH_ACTIVE = "GET_BRANCH_ACTIVE";

// Role group
export const GET_LIST_ROLE_GROUP = "GET_LIST_ROLE_GROUP";
export const GET_SPECIFIC_ROLE_GROUP = "GET_SPECIFIC_ROLE_GROUP";
export const UPDATE_SPECIFIC_ROLE_GROUP = "UPDATE_SPECIFIC_ROLE_GROUP";
export const CREATE_NEW_ROLE_GROUP = "CREATE_NEW_ROLE_GROUP";
export const REMOVE_ROLE_GROUP = "REMOVE_ROLE_GROUP";
export const GET_ROLE_GROUP_ACTIVE = "GET_ROLE_GROUP_ACTIVE";

// Danh sách bệnh
export const GET_LIST_DISEASES = "GET_LIST_DISEASES";
export const GET_SPECIFIC_DISEASES = "GET_SPECIFIC_DISEASES";
export const UPDATE_SPECIFIC_DISEASES = "UPDATE_SPECIFIC_DISEASES";
export const CREATE_NEW_DISEASES = "CREATE_NEW_DISEASES";
export const REMOVE_DISEASES = "REMOVE_DISEASES";
export const GET_DISEASES_ACTIVE = "GET_DISEASES_ACTIVE";

// Nhóm máu
export const GET_LIST_BLOOD_TYPE = "GET_LIST_BLOOD_TYPE";
export const GET_SPECIFIC_BLOOD_TYPE = "GET_SPECIFIC_BLOOD_TYPE";
export const UPDATE_SPECIFIC_BLOOD_TYPE = "UPDATE_SPECIFIC_BLOOD_TYPE";
export const CREATE_NEW_BLOOD_TYPE = "CREATE_NEW_BLOOD_TYPE";
export const REMOVE_BLOOD_TYPE = "REMOVE_BLOOD_TYPE";
export const GET_BLOOD_TYPE_ACTIVE = "GET_BLOOD_TYPE_ACTIVE";

// Dịch vụ của khách hàng
export const CUSTOMER_SERVICES_GET_LIST = "CUSTOMER_SERVICES_GET_LIST";
export const CUSTOMER_SERVICES_GET_SPECIFIC = "CUSTOMER_SERVICES_GET_SPECIFIC";
export const CUSTOMER_SERVICES_UPDATE_SPECIFIC = "CUSTOMER_SERVICES_UPDATE_SPECIFIC";
export const CUSTOMER_SERVICES_CREATE_NEW = "CUSTOMER_SERVICES_CREATE_NEW";
export const CUSTOMER_SERVICES_REMOVE = "CUSTOMER_SERVICES_REMOVE";
export const CUSTOMER_SERVICES_GET_STATISTICAL = "CUSTOMER_SERVICES_GET_STATISTICAL";
export const CUSTOMER_SERVICES = "CUSTOMER_SERVICES";
export const CUSTOMER_SERVICES_CREATE_EXAM_VISIT = "CUSTOMER_SERVICES_CREATE_EXAM_VISIT";
export const CUSTOMER_SERVICES_UPDATE_EXAM_VISIT = "CUSTOMER_SERVICES_UPDATE_EXAM_VISIT";
export const CUSTOMER_SERVICES_REMOVE_EXAM_VISIT = "CUSTOMER_SERVICES_REMOVE_EXAM_VISIT";
export const CUSTOMER_SERVICES_GET_SPECIFIC_EXAM_VISIT = "CUSTOMER_SERVICES_GET_SPECIFIC_EXAM_VISIT";
export const CUSTOMER_SERVICES_EXPORT_EXCEL = "CUSTOMER_SERVICES_EXPORT_EXCEL";

// Phiếu thu
export const GET_LIST_CASH_RECEIPT = "GET_LIST_CASH_RECEIPT";
export const GET_SPECIFIC_CASH_RECEIPT = "GET_SPECIFIC_CASH_RECEIPT";
export const UPDATE_SPECIFIC_CASH_RECEIPT = "UPDATE_SPECIFIC_CASH_RECEIPT";
export const CREATE_NEW_CASH_RECEIPT = "CREATE_NEW_CASH_RECEIPT";
export const REMOVE_CASH_RECEIPT = "REMOVE_CASH_RECEIPT";
export const GET_LIST_CASH_RECEIPT_WITH_CONCATENATE = "GET_LIST_CASH_RECEIPT_WITH_CONCATENATE";
export const GET_STATISTICAL_CASH_RECEIPT = "GET_STATISTICAL_CASH_RECEIPT";
export const IMPORT_EXCEL_RECEIPT = "IMPORT_EXCEL_RECEIPT";
export const GET_CASH_RECEIPT_BY_CUSTOMER = "GET_CASH_RECEIPT_BY_CUSTOMER";

// Phiếu chi
export const GET_LIST_PAYMENT_VOUCHER = "GET_LIST_PAYMENT_VOUCHER";
export const GET_SPECIFIC_PAYMENT_VOUCHER = "GET_SPECIFIC_PAYMENT_VOUCHER";
export const UPDATE_SPECIFIC_PAYMENT_VOUCHER = "UPDATE_SPECIFIC_PAYMENT_VOUCHER";
export const CREATE_NEW_PAYMENT_VOUCHER = "CREATE_NEW_PAYMENT_VOUCHER";
export const REMOVE_PAYMENT_VOUCHER = "REMOVE_PAYMENT_VOUCHER";
export const GET_LIST_PAYMENT_VOUCHER_WITH_CONCATENATE = "GET_LIST_PAYMENT_VOUCHER_WITH_CONCATENATE";

// So quy
export const GET_LIST_CASH_BOOK = "GET_LIST_CASH_BOOK";
export const GET_SPECIFIC_CASH_BOOK = "GET_SPECIFIC_CASH_BOOK";
export const UPDATE_SPECIFIC_CASH_BOOK = "UPDATE_SPECIFIC_CASH_BOOK";
export const CREATE_NEW_CASH_BOOK = "CREATE_NEW_CASH_BOOK";
export const REMOVE_CASH_BOOK = "REMOVE_CASH_BOOK";
export const GET_STATISTICAL_CASH_BOOK = "GET_STATISTICAL_CASH_BOOK";

// Voucher
export const GET_ALL_VOUCHER = "GET_ALL_VOUCHER";
export const CREATE_NEW_VOUCHER = "CREATE_NEW_VOUCHER";
export const UPDATE_SPECIFIC_VOUCHER = "UPDATE_SPECIFIC_VOUCHER";
export const REMOVE_VOUCHER = "REMOVE_VOUCHER";
export const GET_SPECIFIC_VOUCHER = "GET_SPECIFIC_VOUCHER";
export const GET_ALL_VOUCHER_WITH_CONCATENATE = "GET_ALL_VOUCHER_WITH_CONCATENATE";
export const GET_ALL_VOUCHER_BY_ACTIVE = "GET_ALL_VOUCHER_BY_ACTIVE";
export const VOUCHER_APPLY_CUSTOMER = "VOUCHER_APPLY_CUSTOMER";
export const VOUCHER_CUSTOMER_REMOVE = "VOUCHER_CUSTOMER_REMOVE";
export const VOUCHER_CUSTOMER_UPDATE = "VOUCHER_CUSTOMER_UPDATE";
export const VOUCHER_CUSTOMER_GET_USED = "VOUCHER_CUSTOMER_GET_USED";
export const VOUCHER_CUSTOMER_GET_USED_BY_CUSTOMER = "VOUCHER_CUSTOMER_GET_USED_BY_CUSTOMER";

// Nhóm sản phẩm
export const GET_LIST_PRODUCT_GROUP = "GET_LIST_PRODUCT_GROUP";
export const GET_SPECIFIC_PRODUCT_GROUP = "GET_SPECIFIC_PRODUCT_GROUP";
export const UPDATE_SPECIFIC_PRODUCT_GROUP = "UPDATE_SPECIFIC_PRODUCT_GROUP";
export const CREATE_NEW_PRODUCT_GROUP = "CREATE_NEW_PRODUCT_GROUP";
export const REMOVE_PRODUCT_GROUP = "REMOVE_PRODUCT_GROUP";
export const GET_PRODUCT_GROUP_ACTIVE = "GET_PRODUCT_GROUP_ACTIVE";

// Nhà cung cấp
export const GET_ALL_PRODUCT_SUPPLIER = "GET_ALL_PRODUCT_SUPPLIER";
export const GET_PRODUCT_SUPPLIER = "GET_PRODUCT_SUPPLIER";
export const CREATE_PRODUCT_SUPPLIER = "CREATE_PRODUCT_SUPPLIER";
export const UPDATE_PRODUCT_SUPPLIER = "UPDATE_PRODUCT_SUPPLIER";
export const REMOVE_PRODUCT_SUPPLIER = "REMOVE_PRODUCT_SUPPLIER";
export const GET_PRODUCT_SUPPLIER_ACTIVE = "GET_PRODUCT_SUPPLIER_ACTIVE";

// Quản lý kho
export const GET_ALL_PRODUCT_WAREHOUSE = "GET_ALL_PRODUCT_WAREHOUSE";
export const GET_PRODUCT_WAREHOUSE = "GET_PRODUCT_WAREHOUSE";
export const CREATE_PRODUCT_WAREHOUSE = "CREATE_PRODUCT_WAREHOUSE";
export const UPDATE_PRODUCT_WAREHOUSE = "UPDATE_PRODUCT_WAREHOUSE";
export const REMOVE_PRODUCT_WAREHOUSE = "REMOVE_PRODUCT_WAREHOUSE";
export const GET_PRODUCT_WAREHOUSE_ACTIVE = "GET_PRODUCT_WAREHOUSE_ACTIVE";

// Sản phẩm
export const GET_ALL_PRODUCT = "GET_ALL_PRODUCT";
export const CREATE_NEW_PRODUCT = "CREATE_NEW_PRODUCT";
export const UPDATE_SPECIFIC_PRODUCT = "UPDATE_SPECIFIC_PRODUCT";
export const REMOVE_PRODUCT = "REMOVE_PRODUCT";
export const GET_SPECIFIC_PRODUCT = "GET_SPECIFIC_PRODUCT";
export const GET_ALL_PRODUCT_WITH_CONCATENATE = "GET_ALL_PRODUCT_WITH_CONCATENATE";
export const GET_STATISTICAL_PRODUCT = "GET_STATISTICAL_PRODUCT";

// Phiếu nhập kho
export const GET_ALL_PRODUCT_RECEIPT_IMPORT = "GET_ALL_PRODUCT_RECEIPT_IMPORT";
export const CREATE_NEW_PRODUCT_RECEIPT_IMPORT = "CREATE_NEW_PRODUCT_RECEIPT_IMPORT";
export const UPDATE_SPECIFIC_PRODUCT_RECEIPT_IMPORT = "UPDATE_SPECIFIC_PRODUCT_RECEIPT_IMPORT";
export const REMOVE_PRODUCT_RECEIPT_IMPORT = "REMOVE_PRODUCT_RECEIPT_IMPORT";
export const GET_SPECIFIC_PRODUCT_RECEIPT_IMPORT = "GET_SPECIFIC_PRODUCT_RECEIPT_IMPORT";
export const PRODUCT_RECEIPT_IMPORT_EXPORT_EXCEL = "PRODUCT_RECEIPT_IMPORT_EXPORT_EXCEL";

//Phiếu xuất kho
export const GET_ALL_PRODUCT_RECEIPT_EXPORT = "GET_ALL_PRODUCT_RECEIPT_EXPORT";
export const CREATE_NEW_PRODUCT_RECEIPT_EXPORT = "CREATE_NEW_PRODUCT_RECEIPT_EXPORT";
export const UPDATE_SPECIFIC_PRODUCT_RECEIPT_EXPORT = "UPDATE_SPECIFIC_PRODUCT_RECEIPT_EXPORT";
export const REMOVE_PRODUCT_RECEIPT_EXPORT = "REMOVE_PRODUCT_RECEIPT_EXPORT";
export const GET_SPECIFIC_PRODUCT_RECEIPT_EXPORT = "GET_SPECIFIC_PRODUCT_RECEIPT_EXPORT";
export const PRODUCT_RECEIPT_EXPORT_EXPORT_EXCEL = "PRODUCT_RECEIPT_EXPORT_EXPORT_EXCEL";

// UNIT
export const GET_LIST_UNIT = "GET_LIST_UNIT";
export const GET_SPECIFIC_UNIT = "GET_SPECIFIC_UNIT";
export const UPDATE_SPECIFIC_UNIT = "UPDATE_SPECIFIC_UNIT";
export const CREATE_NEW_UNIT = "CREATE_NEW_UNIT";
export const REMOVE_UNIT = "REMOVE_UNIT";
export const GET_UNIT_ACTIVE = "GET_UNIT_ACTIVE";

// Tinh trang san pham
export const GET_LIST_PRODUCT_STATUS = "GET_LIST_PRODUCT_STATUS";
export const GET_SPECIFIC_PRODUCT_STATUS = "GET_SPECIFIC_PRODUCT_STATUS";
export const UPDATE_SPECIFIC_PRODUCT_STATUS = "UPDATE_SPECIFIC_PRODUCT_STATUS";
export const CREATE_NEW_PRODUCT_STATUS = "CREATE_NEW_PRODUCT_STATUS";
export const REMOVE_PRODUCT_STATUS = "REMOVE_PRODUCT_STATUS";
export const GET_PRODUCT_STATUS_ACTIVE = "GET_PRODUCT_STATUS_ACTIVE";

// Guide
export const GET_ALL_GUIDE = "GET_ALL_GUIDE";
export const GET_SPECIFIC_GUIDE = "GET_SPECIFIC_GUIDE";
export const UPDATE_SPECIFIC_GUIDE = "UPDATE_SPECIFIC_GUIDE";
export const CREATE_NEW_GUIDE = "CREATE_NEW_GUIDE";
export const REMOVE_GUIDE = "REMOVE_GUIDE";

// Báo cáo công việc
export const GET_ALL_WORK_REPORT = "GET_ALL_WORK_REPORT";
export const GET_SPECIFIC_WORK_REPORT = "GET_SPECIFIC_WORK_REPORT";
export const UPDATE_SPECIFIC_WORK_REPORT = "UPDATE_SPECIFIC_WORK_REPORT";
export const CREATE_NEW_WORK_REPORT = "CREATE_NEW_WORK_REPORT";
export const REMOVE_WORK_REPORT = "REMOVE_WORK_REPORT";
export const COMMENT_WORK_REPORT = "COMMENT_WORK_REPORT";

// Comment
export const CREATE_COMMENT = "CREATE_COMMENT";
export const REMOVE_COMMENT = "REMOVE_COMMENT";

// Telegram
export const GET_ALL_TELEGRAM = "GET_ALL_TELEGRAM";
export const GET_SPECIFIC_TELEGRAM = "GET_SPECIFIC_TELEGRAM";
export const UPDATE_SPECIFIC_TELEGRAM = "UPDATE_SPECIFIC_TELEGRAM";
export const CREATE_NEW_TELEGRAM = "CREATE_NEW_TELEGRAM";
export const REMOVE_TELEGRAM = "REMOVE_TELEGRAM";

// Customer telesale
export const GET_LIST_CUSTOMER_TELESALE = "GET_LIST_CUSTOMER_TELESALE";
export const GET_SPECIFIC_CUSTOMER_TELESALE = "GET_SPECIFIC_CUSTOMER_TELESALE";
export const UPDATE_SPECIFIC_CUSTOMER_TELESALE = "UPDATE_SPECIFIC_CUSTOMER_TELESALE";
export const REMOVE_CUSTOMER_TELESALE = "REMOVE_CUSTOMER_TELESALE";

// Potential customer
export const GET_LIST_CUSTOMER_POTENTIAL = "GET_LIST_CUSTOMER_POTENTIAL";
export const GET_SPECIFIC_CUSTOMER_POTENTIAL = "GET_SPECIFIC_CUSTOMER_POTENTIAL";
export const UPDATE_SPECIFIC_CUSTOMER_POTENTIAL = "UPDATE_SPECIFIC_CUSTOMER_POTENTIAL";
export const CREATE_NEW_CUSTOMER_POTENTIAL = "CREATE_NEW_CUSTOMER_POTENTIAL";
export const REMOVE_CUSTOMER_POTENTIAL = "REMOVE_CUSTOMER_POTENTIAL";
export const GET_LIST_CUSTOMER_POTENTIA_ACTIVE = "GET_LIST_CUSTOMER_POTENTIA_ACTIVE";
export const IMPORT_EXCEL = "IMPORT_EXCEL";
export const GET_LIST_NAME_CUSTOMER_POTENTIAL = "GET_LIST_NAME_CUSTOMER_POTENTIAL";

// Customer consultant
export const GET_LIST_CUSTOMER_CONSULTANT = "GET_LIST_CUSTOMER_CONSULTANT";
export const GET_SPECIFIC_CUSTOMER_CONSULTANT = "GET_SPECIFIC_CUSTOMER_CONSULTANT";
export const UPDATE_SPECIFIC_CUSTOMER_CONSULTANT = "UPDATE_SPECIFIC_CUSTOMER_CONSULTANT";
export const REMOVE_CUSTOMER_CONSULTANT = "REMOVE_CUSTOMER_CONSULTANT";

// Đăng ký thanh toán
export const GET_LIST_PAYMENT_RESISTRATION = "GET_LIST_PAYMENT_RESISTRATION";
export const GET_SPECIFIC_PAYMENT_RESISTRATION = "GET_SPECIFIC_PAYMENT_RESISTRATION";
export const UPDATE_SPECIFIC_PAYMENT_RESISTRATION = "UPDATE_SPECIFIC_PAYMENT_RESISTRATION";
export const CREATE_NEW_PAYMENT_RESISTRATION = "CREATE_NEW_PAYMENT_RESISTRATION";
export const REMOVE_PAYMENT_RESISTRATION = "REMOVE_PAYMENT_RESISTRATION";

// Quay thưởng
export const GET_LIST_REWARD_HISTORIES = "GET_LIST_REWARD_HISTORIES";
export const GET_LIST_REWARD_CUSTOMER_HAS = "GET_LIST_REWARD_CUSTOMER_HAS";
export const GET_SPECIFIC_REWARD_HISTORIES = "GET_SPECIFIC_REWARD_HISTORIES";
export const RANDOM_CUSTOMERS = "RANDOM_CUSTOMERS";
export const REMOVE_REWARD_HISTORIES = "REMOVE_REWARD_HISTORIES";
export const REMOVE_REWARD_CUSTOMER_HAS = "REMOVE_REWARD_CUSTOMER_HAS";
export const REWARD_CONFIG = "REWARD_CONFIG";
export const REWARD_GET_CONFIG = "REWARD_GET_CONFIG";
export const SPIN_REWARD = "SPIN_REWARD";
export const UPDATE_SPECIFIC_SPIN_HISTORIES = "UPDATE_SPECIFIC_SPIN_HISTORIES";


// posts
export const GET_LIST_POSTS = "GET_LIST_POSTS";
export const GET_ONE_POSTS = "GET_ONE_POSTS";
export const CREATE_POSTS = "CREATE_POSTS";
export const UPDATE_POSTS = "UPDATE_POSTS";
export const REMOVE_POSTS = "REMOVE_POSTS";


// Lịch sử xem số điện thoại khách hàng
export const GET_LIST_VIEW_PHONENUMBER = 'GET_LIST_VIEW_PHONENUMBER';
export const CREATE_NEW_VIEW_PHONENUMBER = 'CREATE_NEW_VIEW_PHONENUMBER';
export const REMOVE_VIEW_PHONENUMBER = 'REMOVE_VIEW_PHONENUMBER';

// Firebase
export const ADD_DEVICE_TOKEN = "ADD_DEVICE_TOKEN";
export const GET_LIST_NOTIFICATIONS = "GET_LIST_NOTIFICATIONS";
export const GET_COUNT_NOTIFICATIONS = "GET_COUNT_NOTIFICATIONS";
export const UPDATE_IS_READ_NOTIFICATION = "UPDATE_IS_READ_NOTIFICATION";

// Quản lý dự án
export const GET_LIST_PROJECT = "GET_LIST_PROJECT";
export const GET_SPECIFIC_PROJECT = "GET_SPECIFIC_PROJECT";
export const UPDATE_SPECIFIC_PROJECT = "UPDATE_SPECIFIC_PROJECT";
export const CREATE_NEW_PROJECT = "CREATE_NEW_PROJECT";
export const REMOVE_PROJECT = "REMOVE_PROJECT";
export const GET_PROJECT_ACTIVE = "GET_PROJECT_ACTIVE";
export const CLONE_PROJECT = "CLONE_PROJECT";

// Công việc của dự án
export const GET_ALL_PROJECT_BASED_JOB = "GET_ALL_PROJECT_BASED_JOB";
export const GET_SPECIFIC_PROJECT_BASED_JOB = "GET_SPECIFIC_PROJECT_BASED_JOB";
export const UPDATE_SPECIFIC_PROJECT_BASED_JOB = "UPDATE_SPECIFIC_PROJECT_BASED_JOB";
export const CREATE_NEW_PROJECT_BASED_JOB = "CREATE_NEW_PROJECT_BASED_JOB";
export const REMOVE_PROJECT_BASED_JOB = "REMOVE_PROJECT_BASED_JOB";
export const UPDATE_RESULT_PROJECT_BASED_JOB = "UPDATE_RESULT_PROJECT_BASED_JOB";
export const UPDATE_STATUS_PROJECT_BASED_JOB = "UPDATE_STATUS_PROJECT_BASED_JOB";
export const ASSIGN_PROJECT_BASED_JOB = "ASSIGN_PROJECT_BASED_JOB";
export const GET_MAX_VALUE_PROJECT_BASED_JOB = "GET_MAX_VALUE_PROJECT_BASED_JOB";

// Danh sách bác sĩ và phụ tá
export const GET_DOCTOR_ASSISTANT_STATUS = "GET_DOCTOR_ASSISTANT_STATUS";
export const DOCTOR_ASSISTANT_STATUS_ASSIGN = "DOCTOR_ASSISTANT_STATUS_ASSIGN";

// D/s bệnh nhân đang chờ
export const GET_LIST_PATIENT_WAITING = "GET_LIST_PATIENT_WAITING";

// Functional list
export const GET_LIST_FUNCTIONAL_AREA = "GET_LIST_FUNCTIONAL_AREA";
export const GET_SPECIFIC_FUNCTIONAL_AREA = "GET_SPECIFIC_FUNCTIONAL_AREA";
export const UPDATE_SPECIFIC_FUNCTIONAL_AREA = "UPDATE_SPECIFIC_FUNCTIONAL_AREA";
export const CREATE_NEW_FUNCTIONAL_AREA = "CREATE_NEW_FUNCTIONAL_AREA";
export const REMOVE_FUNCTIONAL_AREA = "REMOVE_FUNCTIONAL_AREA";
export const GET_FUNCTIONAL_AREA_ACTIVE = "GET_FUNCTIONAL_AREA_ACTIVE";

// Chức năng của khu vực
export const GET_ALL_FUNCTIONAL = "GET_ALL_FUNCTIONAL";
export const GET_SPECIFIC_FUNCTIONAL = "GET_SPECIFIC_FUNCTIONAL";
export const UPDATE_SPECIFIC_FUNCTIONAL = "UPDATE_SPECIFIC_FUNCTIONAL";
export const CREATE_NEW_FUNCTIONAL = "CREATE_NEW_FUNCTIONAL";
export const REMOVE_FUNCTIONAL = "REMOVE_FUNCTIONAL";

// Công việc
export const GET_ALL_JOBS = "GET_ALL_JOBS";
export const GET_SPECIFIC_JOBS = "GET_SPECIFIC_JOBS";
export const UPDATE_SPECIFIC_JOBS = "UPDATE_SPECIFIC_JOBS";
export const CREATE_NEW_JOBS = "CREATE_NEW_JOBS";
export const REMOVE_JOBS = "REMOVE_JOBS";

// Phân ca làm việc
export const GET_ALL_SHIFT_SCHEDULING = "GET_ALL_SHIFT_SCHEDULING";
export const GET_SPECIFIC_SHIFT_SCHEDULING = "GET_SPECIFIC_SHIFT_SCHEDULING";
export const UPDATE_SPECIFIC_SHIFT_SCHEDULING = "UPDATE_SPECIFIC_SHIFT_SCHEDULING";
export const CREATE_NEW_SHIFT_SCHEDULING = "CREATE_NEW_SHIFT_SCHEDULING";
export const REMOVE_SHIFT_SCHEDULING = "REMOVE_SHIFT_SCHEDULING";

// Customer medical
export const GET_LIST_CUSTOMER_MEDICAL = "GET_LIST_CUSTOMER_MEDICAL";
export const CREATE_NEW_CUSTOMER_MEDICAL = "CREATE_NEW_CUSTOMER_MEDICAL";

// Lịch sử thay đổi
export const GET_LIST_HISTORY = "GET_LIST_HISTORY";
export const GET_SPECIFIC_HISTORY = "GET_SPECIFIC_HISTORY";
export const REMOVE_HISTORY = "REMOVE_HISTORY";

// Loại thẻ
export const GET_ALL_CARD_TYPE = "GET_ALL_CARD_TYPE";
export const GET_CARD_TYPE = "GET_CARD_TYPE";
export const CREATE_CARD_TYPE = "CREATE_CARD_TYPE";
export const UPDATE_CARD_TYPE = "UPDATE_CARD_TYPE";
export const REMOVE_CARD_TYPE = "REMOVE_CARD_TYPE";
export const GET_CARD_TYPE_ACTIVE = "GET_CARD_TYPE_ACTIVE";

// Theo dõi thẻ đã phát hành
export const GET_LIST_CARD_RELEASE_TRACKING = "GET_LIST_CARD_RELEASE_TRACKING";
export const GET_SPECIFIC_CARD_RELEASE_TRACKING = "GET_SPECIFIC_CARD_RELEASE_TRACKING";
export const UPDATE_SPECIFIC_CARD_RELEASE_TRACKING = "UPDATE_SPECIFIC_CARD_RELEASE_TRACKING";
export const CREATE_NEW_CARD_RELEASE_TRACKING = "CREATE_NEW_CARD_RELEASE_TRACKING";
export const REMOVE_CARD_RELEASE_TRACKING = "REMOVE_CARD_RELEASE_TRACKING";

// Số lượng thẻ được phát hành
export const GET_LIST_CARD_ISSUED_COUNT = "GET_LIST_CARD_ISSUED_COUNT";
export const GET_SPECIFIC_CARD_ISSUED_COUNT = "GET_SPECIFIC_CARD_ISSUED_COUNT";
export const UPDATE_SPECIFIC_CARD_ISSUED_COUNT = "UPDATE_SPECIFIC_CARD_ISSUED_COUNT";
export const CREATE_NEW_CARD_ISSUED_COUNT = "CREATE_NEW_CARD_ISSUED_COUNT";
export const REMOVE_CARD_ISSUED_COUNT = "REMOVE_CARD_ISSUED_COUNT";

// Khách hàng chờ làm thẻ
export const GET_LIST_CARD_PENDING_CUSTOMER = "GET_LIST_CARD_PENDING_CUSTOMER";
export const CARD_PENDING_CUSTOMER_ASSIGN_TO_STAFF = "CARD_PENDING_CUSTOMER_ASSIGN_TO_STAFF";
export const CARD_PENDING_CUSTOMER_EXPORT_EXCEL = "CARD_PENDING_CUSTOMER_EXPORT_EXCEL";


// Tùy chọn cột hiển thị
export const GET_LIST_USER_COLUMN_PREFERENCES = "GET_LIST_USER_COLUMN_PREFERENCES";
export const CREATE_NEW_USER_COLUMN_PREFERENCES = "CREATE_NEW_USER_COLUMN_PREFERENCES";

// Phụ tá: Danh sách bệnh nhân đã thực hiện và lịch sắp tới
export const ASSISTANT_LIST_CUSTOMER_HAS_PERFORMED = "ASSISTANT_LIST_CUSTOMER_HAS_PERFORMED";
export const ASSISTANT_LIST_SCHEDULE_UPCOMING = "ASSISTANT_LIST_SCHEDULE_UPCOMING";
// Bác sĩ: Danh sách bệnh nhân đã thực hiện và lịch sắp tới
export const DOCTOR_LIST_CUSTOMER_HAS_PERFORMED = "DOCTOR_LIST_CUSTOMER_HAS_PERFORMED";
export const DOCTOR_LIST_SCHEDULE_UPCOMING = "DOCTOR_LIST_SCHEDULE_UPCOMING";

// Thống kê tổng hợp
export const SUMMARY_STATISTICS = "SUMMARY_STATISTICS";

// Quản lý media
export const GET_LIST_MEDIA = "GET_LIST_MEDIA";
export const GET_ONE_MEDIA = "GET_ONE_MEDIA";
export const CREATE_MEDIA = "CREATE_MEDIA";
export const UPDATE_MEDIA = "UPDATE_MEDIA";
export const REMOVE_MEDIA = "REMOVE_MEDIA";

export const GET_LIST_MEDIA_LIBRARY = "GET_LIST_MEDIA_LIBRARY";
export const GEt_SPECIFIC_MEDIA_LIBRARY = "GEt_SPECIFIC_MEDIA_LIBRARY";
export const CREATE_MEDIA_LIBRARY = "CREATE_MEDIA_LIBRARY";
export const UPDATE_MEDIA_LIBRARY = "UPDATE_MEDIA_LIBRARY";
export const REMOVE_MEDIA_LIBRARY = "REMOVE_MEDIA_LIBRARY";
export const GET_MEDIA_LIBRARY_CUSTOMER = "GET_MEDIA_LIBRARY_CUSTOMER";
export const GET_MEDIA_LIBRARY_CUSTOMER_SERVICE = "GET_MEDIA_LIBRARY_CUSTOMER_SERVICE";

// Quản lý danh mục media
export const GET_LIST_MEDIA_CATEGORIES = "GET_LIST_MEDIA_CATEGORIES";
export const GET_SPECIFIC_MEDIA_CATEGORIES = "GET_SPECIFIC_MEDIA_CATEGORIES";
export const UPDATE_SPECIFIC_MEDIA_CATEGORIES = "UPDATE_SPECIFIC_MEDIA_CATEGORIES";
export const CREATE_NEW_MEDIA_CATEGORIES = "CREATE_NEW_MEDIA_CATEGORIES";
export const REMOVE_MEDIA_CATEGORIES = "REMOVE_MEDIA_CATEGORIES";
export const GET_MEDIA_CATEGORIES_ACTIVE = "GET_MEDIA_CATEGORIES_ACTIVE";

// Quản lý danh mục document categories
export const GET_LIST_DOCUMENT_CATEGORIES = "GET_LIST_DOCUMENT_CATEGORIES";
export const GET_SPECIFIC_DOCUMENT_CATEGORIES = "GET_SPECIFIC_DOCUMENT_CATEGORIES";
export const UPDATE_SPECIFIC_DOCUMENT_CATEGORIES = "UPDATE_SPECIFIC_DOCUMENT_CATEGORIES";
export const CREATE_NEW_DOCUMENT_CATEGORIES = "CREATE_NEW_DOCUMENT_CATEGORIES";
export const REMOVE_DOCUMENT_CATEGORIES = "REMOVE_DOCUMENT_CATEGORIES";
export const GET_DOCUMENT_CATEGORIES_ACTIVE = "GET_DOCUMENT_CATEGORIES_ACTIVE";

// Quản lý document
export const GET_LIST_DOCUMENTS = "GET_LIST_DOCUMENTS";
export const GET_ONE_DOCUMENTS = "GET_ONE_DOCUMENTS";
export const CREATE_DOCUMENTS = "CREATE_DOCUMENTS";
export const UPDATE_DOCUMENTS = "UPDATE_DOCUMENTS";
export const REMOVE_DOCUMENTS = "REMOVE_DOCUMENTS";
export const GET_DOCUMENTS_BY_CATEGORY = "GET_DOCUMENTS_BY_CATEGORY";

export const GET_LIST_COMMISSION_RULE = "GET_LIST_COMMISSION_RULE";
export const GET_SPECIFIC_COMMISSION_RULE = "GET_SPECIFIC_COMMISSION_RULE";
export const UPDATE_SPECIFIC_COMMISSION_RULE = "UPDATE_SPECIFIC_COMMISSION_RULE";
export const CREATE_NEW_COMMISSION_RULE = "CREATE_NEW_COMMISSION_RULE";
export const REMOVE_COMMISSION_RULE = "REMOVE_COMMISSION_RULE";

export const GET_LIST_COMMISSION_REVENUE = "GET_LIST_COMMISSION_REVENUE";
export const GET_SPECIFIC_COMMISSION_REVENUE = "GET_SPECIFIC_COMMISSION_REVENUE";
export const UPDATE_SPECIFIC_COMMISSION_REVENUE = "UPDATE_SPECIFIC_COMMISSION_REVENUE";
export const CREATE_NEW_COMMISSION_REVENUE = "CREATE_NEW_COMMISSION_REVENUE";
export const REMOVE_COMMISSION_REVENUE = "REMOVE_COMMISSION_REVENUE";

export const GET_ADDITINOAL_COST_CUSTOMER = "GET_ADDITINOAL_COST_CUSTOMER";
export const REMOVE_ADDITINOAL_COST = "REMOVE_ADDITINOAL_COST";
export const CREATE_ADDITINOAL_COST = "CREATE_ADDITINOAL_COST";
export const UPDATE_SPECIFIC_ADDITINOAL_COST = "UPDATE_SPECIFIC_ADDITINOAL_COST";
export const GET_SPECIFIC_ADDITINOAL_COST = "GET_SPECIFIC_ADDITINOAL_COST";

export const GET_LIST_COMMISSION = "GET_LIST_COMMISSION";
export const GET_SPECIFIC_COMMISSION = "GET_SPECIFIC_COMMISSION";
export const UPDATE_SPECIFIC_COMMISSION = "UPDATE_SPECIFIC_COMMISSION";
export const CREATE_NEW_COMMISSION = "CREATE_NEW_COMMISSION";
export const REMOVE_COMMISSION = "REMOVE_COMMISSION";
export const COMMISSION_STATISTICAL = "COMMISSION_STATISTICAL";