import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DeleteOutlined, EditOutlined, CheckCircleTwoTone, CloseCircleTwoTone, ReloadOutlined } from '@ant-design/icons';
import { Modal, Divider, Tag, Button, Tooltip, Alert, Row, Col } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _ from 'lodash';
import qs from 'qs';
import CommissionRuleForm from './CommissionRuleForm';
import { checkPermission } from '../../utils/permission';
import { cleanObject, isValidDate, RenderCommissionRules, RenderTeams, teams } from '../../utils/helpers';

import { getCommissionRules, getSpecificCommissionRule, removeCommissionRule } from '../../redux/actions/CommissionRule';
import ExpandableText from '../../components/ExpandableText';
import DynamicTable from '../../components/DynamicTable';
import NumberFormat from 'react-number-format';
import AssignmentsServiceForm from './AssignmentsServiceForm';
import AssignmentsEmployeeForm from './AssignmentsEmployeeForm';
import BaseSelect from '../../components/Elements/BaseSelect';

const { confirm } = Modal;

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/commission-rule',
        breadcrumbName: 'Cấu hình quy tắc tính hoa hồng',
    },
]

class CommissionRuleList extends Component {
    state = {
        isLoading: false,
        selectedRowKeys: [],
        isOpenForm: false,
        currentItem: null,
        openAssignServiceForm: false,
        openAssignEmployeeForm: false
    }

    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.loadData(query);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.loadData(query);
        }
    }

    loadData(query) {
        this.setState({ isLoading: true });
        this.props.getCommissionRules(query).then(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }

        query = cleanObject(query);

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        });
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    toggleOpenForm(isOpenForm, currentItem = null) {
        this.setState({ isOpenForm: isOpenForm, currentItem: currentItem });
    }

    onEdit(id) {
        this.props.getSpecificCommissionRule(id).then(res => {
            this.toggleOpenForm(true, res);
        })
    }

    toggleOpenAssignServiceForm(openAssignServiceForm, currentItem = null) {
        this.setState({ openAssignServiceForm: openAssignServiceForm, currentItem: currentItem });
    }

    onOpenAssignServiceForm(id) {
        this.props.getSpecificCommissionRule(id).then(res => {
            this.toggleOpenAssignServiceForm(true, res);
        })
    }

    toggleOpenAssignEmployeeForm(openAssignEmployeeForm, currentItem = null) {
        this.setState({ openAssignEmployeeForm: openAssignEmployeeForm, currentItem: currentItem });
    }

    onOpenAssignEmployeeForm(id) {
        this.props.getSpecificCommissionRule(id).then(res => {
            this.toggleOpenAssignEmployeeForm(true, res);
        })
    }

    onRemove() {
        this.props.removeCommissionRule(this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: []
            });
            setTimeout(() => {
                Modal.destroyAll();
            }, 800);
            this.loadData();
        })
    }

    openAlert(id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá những bản ghi này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.removeCommissionRule(id).then(() => {
                setTimeout(() => {
                    Modal.destroyAll();
                }, 800);
                this.loadData();
            }),
            onCancel() { },
        })
    }

    render() {
        var { commission_rules, pagination } = this.props;
        var { isLoading, selectedRowKeys, isOpenForm, currentItem, openAssignServiceForm, openAssignEmployeeForm } = this.state;

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;

        var query = qs.parse(this.props.location.search.slice(1));

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: '50px',
                sorter: true
            },
            {
                title: '#',
                width: '80px',
                align: 'center',
                key: 'action',
                render: (text, record) => (
                    <div>
                        {
                            checkPermission('commission_rule', 'update') ? (
                                <>
                                    <EditOutlined className='color-primary' style={{ cursor: 'pointer' }} onClick={() => this.onEdit(record.id)} />
                                    <Divider type='vertical' />
                                </>
                            ) : null
                        }
                        {
                            checkPermission('commission_rule', 'remove') ? (
                                <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} onClick={() => this.openAlert([record.id])} />
                            ) : null
                        }
                    </div>
                )
            },
            {
                title: 'Tên',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: 'Bộ phận',
                dataIndex: 'team',
                key: 'team',
                width: '100px',
                render: (text, record) => {
                    return (<>{RenderTeams(text)}</>)
                }
            },
            {
                title: 'Quy tắc',
                dataIndex: 'commission_rule',
                key: 'commission_rule',
                width: '100px',
                render: (text, record) => {
                    return (<>{RenderCommissionRules(text)}</>)
                }
            },
            {
                title: 'Tỷ lệ',
                dataIndex: 'commission_rate',
                key: 'commission_rate',
                width: '80px',
                render: (text, record) => {
                    if (record.commission_rule == 'percentage') {
                        return (<>{text}%</>)
                    } else if (record.commission_rule == 'fixed') {
                        return (<NumberFormat value={text} displayType={'text'} suffix="đ" thousandSeparator={true} />)
                    }
                }
            },
            {
                title: 'Dịch vụ áp dụng',
                dataIndex: 'services',
                key: 'services',
                width: '200px',
                render: (_, record) => {
                    return (<>
                        {record?.services
                            ?.filter(item => item.service) // Lọc những mục hợp lệ
                            .map((item, index) => (
                                <Tag style={{ border: 'none' }} color="blue" key={index}>{item.service.name}</Tag>
                            )) || '-'}

                        <Tooltip title="Bấm để cập nhật" placement="left">
                            <EditOutlined className='color-primary' style={{ cursor: 'pointer' }} onClick={() => this.onOpenAssignServiceForm(record.id)} />
                        </Tooltip>
                    </>)
                }
            },
            {
                title: 'Nhân viên áp dụng',
                dataIndex: 'employees',
                key: 'employees',
                width: '250px',
                render: (_, record) => {
                    return (<>
                        {
                            record?.employees
                                ?.filter(item => item.employee) // Lọc những mục hợp lệ
                                .map((item, index) => (
                                    <Tag style={{ border: 'none' }} color="blue" key={index}>{item.employee.full_name}</Tag>
                                )) || '-'
                        }
                        <Tooltip title="Bấm để cập nhật" placement="left">
                            <EditOutlined className='color-primary' style={{ cursor: 'pointer' }} onClick={() => this.onOpenAssignEmployeeForm(record.id)} />
                        </Tooltip>
                    </>)
                }
            },
            {
                title: 'Ghi chú',
                dataIndex: 'description',
                key: 'description',
                width: '200px',
                render: (text, record) => {
                    return (<ExpandableText text={text} rows={2} />)
                }
            },
            {
                title: 'Trạng thái',
                dataIndex: 'status',
                key: 'status',
                width: '100px',
                align: 'center',
                render: (text, record) => {
                    if (text == 1) return <CheckCircleTwoTone twoToneColor="#52c41a" />;
                    if (text == 0) return <CloseCircleTwoTone twoToneColor="#e74c3c" />;
                }
            },
            {
                title: 'Người tạo',
                dataIndex: 'created_by',
                key: 'created_by',
                sorter: true,
                width: '200px',
                render: (text, record) => {
                    return (<>{record?.created_user?.full_name}</>)
                }
            },
            {
                title: 'Ngày tạo',
                dataIndex: 'created_at',
                key: 'created_at',
                sorter: true,
                width: '140px',
                render: (text, record) => {
                    return (
                        <div>{isValidDate(text)}</div>
                    )
                }
            },
        ];

        return (
            <div>
                <PageTitle routes={routes} title="Cấu hình quy tắc tính hoa hồng" />
                <div>
                    <TableActionBar
                        isShowAddButton={checkPermission('commission_rule', 'create')}
                        isShowDeleteButton={checkPermission('commission_rule', 'remove')}
                        onAdd={() => this.toggleOpenForm(true)}
                        onDelete={() => this.onRemove()}
                        disabled={!selectedRowKeys.length ? true : false}
                        showFilter={false}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                    >
                        <BaseSelect
                            options={teams || []}
                            showSearch={true}
                            className="table-button"
                            placeholder="Bộ phận"
                            style={{ width: '150px' }}
                            defaultValue={query?.team || null}
                            onChange={(value) => this.onChangeFilter("team", value)}
                        />
                        <Button className="table-button" icon={<ReloadOutlined />} loading={isLoading} onClick={() => this.loadData(query)}>Refresh</Button>
                        {
                            hasSelected ? <span>Đang chọn {selectedRowKeys.length} bản ghi</span> : ''
                        }
                    </TableActionBar>
                </div>
                <Row gutter={16}>
                    <Col xs={24} md={24} className='mb-3'>
                        <Alert
                            description={<><strong>Lưu ý: </strong>
                                Một quy tắc có thể áp dụng cho nhiều nhân viên và nhiều dịch vụ khác nhau.
                                Tuy nhiên, trong cùng một bộ phận, mỗi nhân viên chỉ được áp dụng duy nhất một quy tắc cho mỗi dịch vụ.
                            </>}
                            type="warning"
                        />
                    </Col>
                </Row>
                <div className='has-custom-column-table-btn'>
                    <DynamicTable
                        rowKey="id"
                        dataSource={commission_rules || []}
                        columns={columns}
                        isLoading={isLoading}
                        pagination={pagination}
                        rowSelection={rowSelection}
                        moduleKey="commission_rule"
                        onChangeTable={(pagination, sorter) => this.onChangeTable(pagination, sorter)}
                    />
                </div>
                <CommissionRuleForm
                    visible={isOpenForm}
                    currentItem={currentItem}
                    onCancel={() => this.toggleOpenForm(false)}
                    loadData={() => this.loadData(query)}
                />

                <AssignmentsServiceForm
                    visible={openAssignServiceForm}
                    currentItem={currentItem}
                    onCancel={() => this.toggleOpenAssignServiceForm(false)}
                    loadData={() => this.loadData(query)}
                />

                <AssignmentsEmployeeForm
                    visible={openAssignEmployeeForm}
                    currentItem={currentItem}
                    onCancel={() => this.toggleOpenAssignEmployeeForm(false)}
                    loadData={() => this.loadData(query)}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        commission_rules: state.CommissionRule.commission_rules,
        pagination: state.CommissionRule.pagination,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getCommissionRules: (filter) => dispatch(getCommissionRules(filter)),
        getSpecificCommissionRule: (id) => dispatch(getSpecificCommissionRule(id)),
        removeCommissionRule: (ids) => dispatch(removeCommissionRule(ids))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommissionRuleList);
