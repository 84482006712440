import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DeleteOutlined, EditOutlined, CheckCircleTwoTone, CloseCircleTwoTone, ReloadOutlined } from '@ant-design/icons';
import { Table, Button, Modal, Tooltip, Tag, Divider, Skeleton } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _ from 'lodash';
import qs from 'qs';
import TextTruncate from 'react-text-truncate';
import ServiceForm from './ServiceForm';
import { checkPermission } from '../../utils/permission';
import { cleanObject, isValidDate, stripHtml } from '../../utils/helpers';
import HtmlParser from 'react-html-parser';
import NumberFormat from 'react-number-format';

// actions
import { getListService, getSpecificService, removeService } from '../../redux/actions/ServicesActions';

const { confirm } = Modal;

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/service',
        breadcrumbName: 'Danh mục dịch vụ',
    },
]

class ServiceList extends Component {
    state = {
        isLoading: false,
        selectedRowKeys: [],
        isOpenForm: false,
        currentItem: null
    }

    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getListService(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getListService(query).then(() => {
                this.setState({
                    isLoading: false
                })
            })
        }
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }

        query = cleanObject(query);

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        });
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    toggleOpenForm(isOpenForm, currentItem = null) {
        this.setState({ isOpenForm: isOpenForm, currentItem: currentItem });
    }

    onEdit(id) {
        this.props.getSpecificService(id).then(res => {
            this.toggleOpenForm(true, res);
        })
    }

    onRemove() {
        this.props.removeService(this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: []
            });
            setTimeout(() => {
                Modal.destroyAll();
            }, 800);
        })
    }
    openAlert(id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá những bản ghi này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.removeService(id).then(() => {
                setTimeout(() => {
                    Modal.destroyAll();
                }, 800);
            }),
            onCancel() { },
        })
    }

    reloadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getListService(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    render() {
        var { service_list, pagination } = this.props;
        var { isLoading, selectedRowKeys, isOpenForm, currentItem } = this.state;
        //console.log(service_list)
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;

        var query = qs.parse(this.props.location.search.slice(1));

        const columns = [

            {
                title: 'Tên',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: 'Mã dịch vụ',
                dataIndex: 'code',
                key: 'code',
                render: (text, record) => {
                    return (
                        <Tag>{text}</Tag>
                    )
                }
            },
            {
                title: 'Giá dịch vụ',
                dataIndex: 'price',
                key: 'pice',
                render: (text, record) => {
                    if (text) {
                        return (
                            <NumberFormat value={record.price || 0} displayType={'text'} suffix="đ" thousandSeparator={true} />
                        )
                    }
                }
            },
            {
                title: 'Ghi chú',
                dataIndex: 'note',
                key: 'note',
                width: '200px',
                render: (text, record) => {
                    return (
                        <div style={{ wordBreak: "break-all" }}>
                            <Tooltip title={HtmlParser(text)} placement="topLeft">
                                <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: 'Người tạo',
                dataIndex: 'created_user',
                key: 'created_user',
                sorter: true,
                render: (text, record) => {
                    return (<>{text}</>)
                }
            },
            {
                title: 'Ngày tạo',
                dataIndex: 'created_at',
                key: 'created_at',
                sorter: true,
                render: (text, record) => {
                    return (
                        <div>{isValidDate(text)}</div>
                    )
                }
            },
            {
                title: 'Trạng thái',
                dataIndex: 'status',
                key: 'status',
                align: 'center',
                render: (text, record) => {
                    if (text == 1) return <CheckCircleTwoTone twoToneColor="#52c41a" />;
                    if (text == 0) return <CloseCircleTwoTone twoToneColor="#e74c3c" />;
                }
            },
            {
                title: 'Actions',
                width: '80px',
                align: 'center',
                render: (text, record) => (
                    <div>
                        {
                            checkPermission('services', 'update') ? (
                                <>
                                    <EditOutlined className='color-primary' style={{ cursor: 'pointer' }} onClick={() => this.onEdit(record.id)} />
                                    <Divider type='vertical' />
                                </>
                            ) : null
                        }
                        {
                            checkPermission('services', 'remove') ? (
                                <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} onClick={() => this.openAlert([record.id])} />
                            ) : null
                        }
                    </div>
                )
            },
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: '50px'
            }
        ];

        return (
            <div>
                <PageTitle routes={routes} title="Dịch vụ" />
                <div>
                    <TableActionBar
                        isShowAddButton={checkPermission('services', 'create')}
                        isShowDeleteButton={checkPermission('services', 'remove')}
                        onAdd={() => this.toggleOpenForm(true)}
                        onDelete={() => this.onRemove()}
                        disabled={!selectedRowKeys.length ? true : false}
                        showFilter={false}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                    >
                        <Button className="table-button" icon={<ReloadOutlined />} loading={isLoading} onClick={() => this.reloadData()}>Refresh</Button>
                        {
                            hasSelected ? <span style={{ marginLeft: '10px' }}>Đang chọn {selectedRowKeys.length} bản ghi</span> : ''
                        }
                    </TableActionBar>
                </div>
                {
                    service_list && service_list.length ? (
                        <Table
                            rowKey="id"
                            size="small"
                            dataSource={service_list}
                            columns={columns}
                            loading={isLoading}
                            rowSelection={rowSelection}
                            defaultExpandAllRows={true}
                            pagination={{
                                pageSize: pagination.perPage,
                                current: pagination.currentPage,
                                total: pagination.total,
                                showTotal: total => `Tổng ${total} bản ghi`
                            }}
                            onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                            scroll={{
                                x: 'max-content'
                            }}
                        />
                    ) : (
                        <Skeleton active />
                    )
                }

                <ServiceForm
                    visible={isOpenForm}
                    currentItem={currentItem}
                    reloadData={() => this.componentDidMount()}
                    onCancel={() => this.toggleOpenForm(false)}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        service_list: state.ServiceReducer.service_list,
        pagination: state.ServiceReducer.pagination,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getListService: (filter) => dispatch(getListService(filter)),
        getSpecificService: (id) => dispatch(getSpecificService(id)),
        removeService: (ids) => dispatch(removeService(ids))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceList);
