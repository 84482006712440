import api from '../../utils/api';
import {
    GET_CUSTOMER_PAYMENT_CUSTOMER,
    REMOVE_CUSTOMER_PAYMENT,
    CREATE_CUSTOMER_PAYMENT,
    UPDATE_SPECIFIC_CUSTOMER_PAYMENT,
    GET_SPECIFIC_CUSTOMER_PAYMENT
} from './types';
import { NotificationManager } from 'react-notifications';

export const getCustomerPaymentCustomer = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/customer-payment/customer/${id}`).then(res => {
            dispatch({ type: GET_CUSTOMER_PAYMENT_CUSTOMER, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response?.data?.msg);
            reject(err);
        })
    })
}

export const createCustomerPayment = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/customer-payment', data).then(res => {
            dispatch({ type: CREATE_CUSTOMER_PAYMENT, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response?.data?.msg);
            reject(err);
        })
    })
}

export const updateCustomerPayment = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/customer-payment/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_CUSTOMER_PAYMENT, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response?.data?.msg);
            reject(err);
        })
    })
}

export const getSpecificCustomerPayment = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/customer-payment/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_CUSTOMER_PAYMENT, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response?.data?.msg);
            reject(err);
        })
    })
}

export const removeCustomerPayment = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/customer-payment`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_CUSTOMER_PAYMENT, payload: ids });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response?.data?.msg);
            reject(err);
        })
    })
}
