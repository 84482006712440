import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Drawer, Button, Input, Space, Row, Col, InputNumber, DatePicker, Modal, Collapse, Descriptions, Divider, Empty, Card, Spin, Radio } from 'antd';
import { DatabaseOutlined, UserAddOutlined, UserOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import { NotificationManager } from 'react-notifications';
import { updateService } from '../../redux/actions/CustomerServicesActions';
import { customDataImageUpload, isValidDateForm, _newLine, isValidDate, CustomerServiceStatus } from '../../utils/helpers';
import moment from 'moment';
import UploadImage from '../../components/UploadImage';
import CurrentImage from './items/CurrentImage';
import Consultant from './items/Consultant';
import ExaminationVisit from './items/ExaminationVisit';

const { Panel } = Collapse;
const { confirm } = Modal;

class CustomerServiceEditForm extends Component {
    constructor() {
        super();
        this.state = {
            saveLoading: false,
            resetUploadImagesBefore: true,
            resetUploadImagesAfter: true,
            currentConsultant: [],
            items: [],
            visibleConsultant: false,
            currentServiceId: null,
            currentExaminationVisit: [],
            visibleExaminationVisit: false
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.visible && !prevProps.visible) {
            this.setState({
                items: this.props.currentData?.items || [],
            });
        }
    }

    async handleSave(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            delete values.images_before;
            delete values.images_after;
            if (!values.selected_at) {
                NotificationManager.warning('Vui lòng chọn ngày chốt.');
                return;
            }
            if (!err) {
                this.setState({ saveLoading: true });
                try {
                    const { items } = this.state;

                    const data = items.map(item => {
                        return {
                            ...item,
                            images_before: customDataImageUpload(item.images_before, item.bulk_images_before),
                            images_after: customDataImageUpload(item.images_after, item.bulk_images_after)
                        }
                    });

                    values.items = data;
                    values.service_type = 'CHOT';
                    console.log(values);
                    await this.props.updateService(this.props.currentData.id, values);
                    this.props.reloadData();
                    this.setState({ saveLoading: false });
                    //this.onClose();


                } catch (error) {
                    this.setState({ saveLoading: false });
                }
            }
        });
    }

    onClose() {
        this.props.onCancel();
        this.props.form.resetFields();
        this.setState({
            saveLoading: false,
            resetUploadImagesBefore: true,
            resetUploadImagesAfter: true,
            items: [],
        });
    }

    async onChangeItem(name, value, itemId) {
        let items = [...this.state.items];
        let index = items.findIndex(item => item.id == itemId);
        let currentItem = items[index];

        // Cập nhật giá trị cho thuộc tính đang thay đổi
        currentItem[name] = value;

        // Nếu thay đổi quantity hoặc sale_price thì tính lại amount
        if (name === 'quantity' || name === 'sale_price') {
            currentItem.amount = this.calculateAmount(currentItem);
        }

        if (name === 'amount') {
            currentItem.sale_price = Math.round(currentItem.amount / currentItem.quantity);
        }

        // Cập nhật lại items và render lại
        items[index] = currentItem;
        this.renderNewItem(items);
    }

    renderNewItem(items = []) {
        let new_items = items.map((item, index) => {
            return {
                ...item,
                note: _newLine(item.note)
            }
        })
        // Cập nhật state với các giá trị mới
        this.setState({ items: new_items }, () => {
            // Tính toán và cập nhật các giá trị cần thiết
            let total_amount = new_items.reduce((total, item) => total + item.amount, 0);
            this.props.form.setFieldsValue({
                total_amount: total_amount
            });
        });
    }

    calculateAmount(item) {
        return item.quantity * item.sale_price;
    }

    onDelete(id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Xóa dịch vụ này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => {
                let items = this.state.items.filter(item => {
                    return item.id != id;
                });
                this.renderNewItem(items)
            },
            onCancel() { },
        })
    }

    addConsultant = (serviceId) => {
        // Tìm đối tượng trong items có id trùng với serviceId
        const consultantData = this.state.items.find(item => item.id === serviceId)?.consultant;
        if (consultantData) {
            this.setState({ currentConsultant: consultantData });
        } else {
            console.log("Không tìm thấy consultant cho serviceId này.");
        }
        this.setState({ visibleConsultant: true, currentServiceId: serviceId });
    };

    addExaminationVisit = (serviceId) => {
        // Tìm đối tượng trong items có id trùng với serviceId
        const examinationVisitData = this.state.items.find(item => item.id === serviceId)?.examination_visit;
        if (examinationVisitData) {
            this.setState({ currentExaminationVisit: examinationVisitData });
        } else {
            console.log("Không tìm thấy examination visit cho serviceId này.");
        }
        this.setState({ visibleExaminationVisit: true, currentServiceId: serviceId });
    }

    getExaminationVisit = (data) => {
        const { currentServiceId } = this.state;
        const { config } = this.props;

        // Lặp qua data và bổ sung trường full_name cho từng doctor_charge_id, assistant_doctor_id trong mảng
        const updatedData = data.map(item => {
            const doctors = item.doctor_charge_id.map(doctorId => {
                const user = config?.users?.find(user => user.user_id === doctorId);
                return user ? user.full_name : '';
            });

            const assistant = item.assistant_doctor_id.map(assistantId => {
                const user = config?.users?.find(user => user.user_id === assistantId);
                return user ? user.full_name : '';
            });

            return {
                ...item,
                doctor_charge_names: doctors,
                assistant_doctor_names: assistant
            };
        });

        // Cập nhật state với mảng data đã được bổ sung trường full_name
        this.setState((prevState) => {
            const updatedItems = prevState.items.map(item =>
                item.id === currentServiceId ? { ...item, examination_visit: updatedData } : item
            );
            return {
                items: updatedItems,
                examination_visit: updatedData
            };
        }, () => {
            NotificationManager.success("Cập nhật lần khám thành công.");
        });
    };

    getConsultant = (data) => {
        const { currentServiceId } = this.state;
        const { config } = this.props;

        // Lặp qua data và bổ sung trường full_name từ config.users
        const updatedData = data.map(item => {
            const user = config?.users?.find(user => user.user_id === item.user_id);
            return {
                ...item,
                full_name: user ? user.full_name : ''  // Nếu tìm thấy user thì lấy full_name, nếu không thì để trống
            };
        });

        // Cập nhật state với mảng data đã được bổ sung trường full_name
        this.setState((prevState) => {
            const updatedItems = prevState.items.map(item =>
                item.id === currentServiceId ? { ...item, consultant: updatedData } : item
            );
            return {
                items: updatedItems,
                consultant: updatedData
            };
        }, () => {
            NotificationManager.success("Cập nhật nhân viên tư vấn thành công.");
        });
    };

    onChangeImagesBefore = (data, itemId) => {
        this.setState((prevState) => {
            const updatedItems = prevState.items.map(item =>
                item.id === itemId ? { ...item, images_before: data } : item
            );
            return {
                items: updatedItems,
                resetUploadImagesBefore: false,
                images_before: data
            };
        }, () => {
            NotificationManager.success("Tải ảnh before thành công.");
        });
    };

    onChangeImagesAfter = (data, itemId) => {
        this.setState((prevState) => {
            const updatedItems = prevState.items.map(item =>
                item.id === itemId ? { ...item, images_after: data } : item
            );
            return {
                items: updatedItems,
                resetUploadImagesAfter: false,
                images_after: data
            };
        }, () => {
            NotificationManager.success("Tải ảnh after thành công.");
        });
    }

    getImages(data) {
        this.setState((prevState) => {
            const updatedItems = prevState.items.map(item =>
                item.id === data.itemKey
                    ? {
                        ...item,
                        ...(data.filedName === 'images_before'
                            ? { bulk_images_before: data.data }
                            : { bulk_images_after: data.data }
                        )
                    }
                    : item
            );
            return {
                items: updatedItems
            };
        });
    }

    render() {
        var { items, resetUploadImagesBefore,
            resetUploadImagesAfter, saveLoading,
            currentConsultant, currentExaminationVisit } = this.state;
        var { visible, currentData, currentCustomer } = this.props;
        const { getFieldDecorator } = this.props.form;

        return (
            <Drawer
                visible={visible}
                title={'Sửa dịch vụ chốt'}
                onOk={(e) => this.handleSave(e)}
                onClose={() => this.onClose()}
                maskClosable={false}
                width={isMobile ? '100%' : '50%'}
                footer={
                    <div style={{ textAlign: 'right', }}>
                        <Space>
                            <Button key="back" onClick={() => this.onClose()}>
                                Huỷ
                            </Button>
                            <Button
                                loading={saveLoading}
                                key="submit"
                                type="primary"
                                onClick={(e) => this.handleSave(e)}
                            >
                                Lưu
                            </Button>
                        </Space>
                    </div>
                }
            >
                {visible ?
                    <Form layout="vertical" className='customer-data-customer-service-form'>
                        <Row gutter={16}>
                            {items.length > 0 ? (
                                <Col xs={24} md={24} className='mb-3'>
                                    <h6><DatabaseOutlined /> <span className='color-primary'>Dịch vụ: {items[0]?.name}</span></h6>
                                </Col>
                            ) : null}
                            <Col xs={24} md={24} className='mb-3'>
                                <h6><UserOutlined /> <span className='color-primary'>{`${currentCustomer?.full_name} - ${currentCustomer?.code}`}</span></h6>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={24} md={24} className='mb-2'>
                                <Spin tip="Loading..." spinning={saveLoading}>
                                    {items.length > 0 ? items.map((item, index) => {
                                        return (
                                            <div key={item.id}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Ngày chốt">
                                                        {getFieldDecorator('selected_at', {
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: 'Vui lòng điền!',
                                                                },
                                                            ],
                                                            initialValue: currentData ? isValidDateForm(currentData.selected_at, true) : moment()
                                                        })(
                                                            <DatePicker
                                                                className='w-100'
                                                                format="DD/MM/YYYY"
                                                            />
                                                        )}
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Số lượng">
                                                        <InputNumber
                                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            value={item.quantity}
                                                            name="quantity"
                                                            min={1}
                                                            className='w-100'
                                                            onChange={(value) => {
                                                                // Kiểm tra giá trị và thay thế nếu cần thiết
                                                                const validValue = value == null || value <= 0 ? 1 : value;
                                                                this.onChangeItem('quantity', validValue, item.id);
                                                            }}
                                                        />
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Giá bán">
                                                        <InputNumber
                                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            value={item.sale_price}
                                                            name="sale_price"
                                                            min={0}
                                                            className='w-100'
                                                            onChange={(value) => this.onChangeItem('sale_price', value, item.id)}
                                                        />
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Thành tiền">
                                                        <InputNumber
                                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            value={item.amount}
                                                            name="amount"
                                                            min={0}
                                                            className='w-100'
                                                            onChange={(value) => this.onChangeItem('amount', value, item.id)}
                                                        />
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Descriptions size="small" className='mt-1 mb-1'>
                                                    <Descriptions.Item label="Trạng thái">
                                                        <Radio.Group
                                                            onChange={(e) => this.onChangeItem('status', e.target.value, item.id)}
                                                            value={item.status}
                                                        >
                                                            {CustomerServiceStatus?.map((option) => (
                                                                <Radio key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </Radio>
                                                            ))}
                                                        </Radio.Group>
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Ghi chú">
                                                        <Input.TextArea
                                                            value={_newLine(item.note)}
                                                            name="note"
                                                            style={{ marginBottom: 0 }}
                                                            rows={3}
                                                            onChange={(e) => this.onChangeItem(e.target.name, e.target.value, item.id)}
                                                        />
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Row gutter={16}>
                                                    <Col xs={24} md={24}>
                                                        <Card
                                                            size='small'
                                                            title="Nhân viên tư vấn"
                                                            extra={<Button onClick={() => this.addConsultant(item.id)} type='primary' icon={<UserAddOutlined />} size='small'>Cập nhật</Button>}
                                                            className='mt-3'
                                                            headStyle={{ padding: 0, borderBottom: '1px dashed #d9d9d9' }}
                                                            bodyStyle={{ padding: '10px 0px' }}
                                                            bordered={false}
                                                        >
                                                            {item.consultant.length > 0 ? (
                                                                item.consultant.map((item, index) => {
                                                                    return (
                                                                        <>
                                                                            <Descriptions size="small" key={index}>
                                                                                <Descriptions.Item>
                                                                                    {item.full_name}
                                                                                </Descriptions.Item>
                                                                            </Descriptions>
                                                                            <Divider className="no-margin" />
                                                                        </>
                                                                    );
                                                                })
                                                            ) : (
                                                                <div style={{ textAlign: 'center', padding: 0 }} className="ant-list-empty-text">
                                                                    Chưa có dữ liệu.
                                                                </div>
                                                            )}
                                                        </Card>
                                                    </Col>

                                                    <Col xs={24} md={24} className='examination-visit'>
                                                        <Card
                                                            size='small'
                                                            title="Lần khám"
                                                            extra={<Button onClick={() => this.addExaminationVisit(item.id)} type='primary' icon={<UserAddOutlined />} size='small'>Cập nhật</Button>}
                                                            className='mt-3'
                                                            headStyle={{ padding: 0, borderBottom: '1px dashed #d9d9d9' }}
                                                            bodyStyle={{ padding: 0 }}
                                                            bordered={false}
                                                        >
                                                            {item.examination_visit.length > 0 ? (
                                                                <>
                                                                    <Collapse
                                                                        expandIconPosition="end"
                                                                        ghost
                                                                        defaultActiveKey={[]}
                                                                    >
                                                                        {
                                                                            item.examination_visit.map((item, index) => {
                                                                                return (
                                                                                    <Panel header={
                                                                                        <>
                                                                                            <span className="chingo-normal-title">
                                                                                                {isValidDate(item.time, true)}
                                                                                            </span>
                                                                                        </>
                                                                                    } key={index}>
                                                                                        <Divider className='no-margin' orientation="left" orientationMargin="0">Bác sĩ khám</Divider>
                                                                                        {item?.doctor_charge_names?.length > 0 ? (
                                                                                            <ul className='examination-visit-doctor-list'>
                                                                                                {item.doctor_charge_names.map((doctor, index) => {
                                                                                                    return (
                                                                                                        <li key={index}>{doctor}</li>
                                                                                                    );
                                                                                                })}
                                                                                            </ul>
                                                                                        ) : null}
                                                                                        <Divider className='no-margin' orientation="left" orientationMargin="0">Phụ tá</Divider>
                                                                                        {item?.assistant_doctor_names?.length > 0 ? (
                                                                                            <ul className='examination-visit-doctor-list'>
                                                                                                {item.assistant_doctor_names.map((assistant, index) => {
                                                                                                    return (
                                                                                                        <li key={index}>{assistant}</li>
                                                                                                    );
                                                                                                })}
                                                                                            </ul>
                                                                                        ) : null}
                                                                                    </Panel>
                                                                                );
                                                                            })
                                                                        }
                                                                    </Collapse>
                                                                </>
                                                            ) : (
                                                                <div style={{ textAlign: 'center', padding: 0 }} className="ant-list-empty-text">
                                                                    Chưa có dữ liệu.
                                                                </div>
                                                            )}
                                                        </Card>
                                                    </Col>
                                                </Row>
                                                <Row gutter={16} className='mt-4'>
                                                    <Col xs={24} md={12}>
                                                        <Form.Item label="Ảnh before" className='flex-upload border-dashed' style={{ padding: '5px' }}>
                                                            <CurrentImage
                                                                filedName="images_before"
                                                                currentImages={item.bulk_images_before}
                                                                itemKey={item.id}
                                                                getData={(data) => this.getImages(data)}
                                                            />
                                                            {getFieldDecorator('images_before', {
                                                                rules: [
                                                                    {
                                                                        required: false,
                                                                        message: 'Vui lòng điền!',
                                                                    },
                                                                ],
                                                                initialValue: null
                                                            })(
                                                                <UploadImage
                                                                    uploadText="Chọn ảnh"
                                                                    multiple={true}
                                                                    maxCount={50}
                                                                    onChangeData={(data) => this.onChangeImagesBefore(data, item.id)}
                                                                    isResetUpload={resetUploadImagesBefore}
                                                                />
                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} md={12}>
                                                        <Form.Item label="Ảnh after" className='flex-upload border-dashed' style={{ padding: '5px' }}>
                                                            <CurrentImage
                                                                filedName="images_after"
                                                                currentImages={item.bulk_images_after}
                                                                itemKey={item.id}
                                                                getData={(data) => this.getImages(data)}
                                                            />
                                                            {getFieldDecorator('images_after', {
                                                                rules: [
                                                                    {
                                                                        required: false,
                                                                        message: 'Vui lòng điền!',
                                                                    },
                                                                ],
                                                                initialValue: null
                                                            })(
                                                                <UploadImage
                                                                    uploadText="Chọn ảnh"
                                                                    multiple={true}
                                                                    maxCount={50}
                                                                    onChangeData={(data) => this.onChangeImagesAfter(data, item.id)}
                                                                    isResetUpload={resetUploadImagesAfter}
                                                                />
                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )
                                    }) : (
                                        <div style={{ textAlign: 'center' }}>
                                            <Empty imageStyle={{ height: 30 }} style={{ margin: '10px 0' }} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                        </div>
                                    )}
                                </Spin>
                            </Col>
                        </Row>

                        <Consultant
                            visible={this.state.visibleConsultant}
                            onCancel={() => this.setState({ visibleConsultant: false })}
                            getConsultant={this.getConsultant}
                            currentConsultant={currentConsultant}
                        />

                        <ExaminationVisit
                            visible={this.state.visibleExaminationVisit}
                            onCancel={() => this.setState({ visibleExaminationVisit: false })}
                            getExaminationVisit={this.getExaminationVisit}
                            currentExaminationVisit={currentExaminationVisit}
                        />

                    </Form>
                    : null}
            </Drawer>
        )
    }
}

function mapStateToProps(state) {
    return {
        config: state.config
    }
}

function mapDispatchToProps(dispatch) {
    return {
        updateService: (id, data) => dispatch(updateService(id, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CustomerServiceEditForm' })(CustomerServiceEditForm));
