import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DeleteOutlined, EditOutlined, QuestionCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import { Modal, Button, Tooltip, Row, Card, DatePicker, Tag } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _ from 'lodash';
import qs from 'qs';
import { checkPermission } from '../../utils/permission';
import { cleanObject, isValidDate, RenderTeams, teams, truncateDecimals } from '../../utils/helpers';

import { getCommissions, getSpecificCommission, removeCommission, getStatistical } from '../../redux/actions/Commission';
import ExpandableText from '../../components/ExpandableText';
import DynamicTable from '../../components/DynamicTable';
import NumberFormat from 'react-number-format';
import CommissionForm from './CommissionForm';
import BaseSelect from '../../components/Elements/BaseSelect';
import StatisticNumber from '../../components/StatisticNumber';
import moment from 'moment';
import { isMobile } from 'react-device-detect';

const { confirm } = Modal;

const { RangePicker } = DatePicker;

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/commission',
        breadcrumbName: <>Hoa hồng</>,
    },
]

class CommissionList extends Component {
    state = {
        isLoading: false,
        selectedRowKeys: [],
        isOpenForm: false,
        currentItem: null,
        isModalOpen: false
    }

    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.loadData(query);

        this.props.getStatistical(query);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.loadData(query);
            this.props.getStatistical(query);
        }
    }

    loadData(query) {
        this.setState({ isLoading: true });
        this.props.getCommissions(query).then(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }

        if (name === 'monthly') {
            if (value && value.length > 0) {
                query.start_date = moment(value[0]).format('YYYY-MM-DD');
                query.end_date = moment(value[1]).format('YYYY-MM-DD');
            } else {
                delete query.start_date;
                delete query.end_date;
            }
        }
        delete query.monthly;

        query = cleanObject(query);

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        });
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    toggleOpenForm(isOpenForm, currentItem = null) {
        this.setState({ isOpenForm: isOpenForm, currentItem: currentItem });
    }

    onEdit(id) {
        this.props.getSpecificCommission(id).then(res => {
            this.toggleOpenForm(true, res);
        })
    }

    onRemove() {
        this.props.removeCommission(this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: []
            });
            setTimeout(() => {
                Modal.destroyAll();
            }, 800);
            this.loadData();
        })
    }

    openAlert(id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá những bản ghi này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.removeCommission(id).then(() => {
                setTimeout(() => {
                    Modal.destroyAll();
                }, 800);
                this.loadData();
            }),
            onCancel() { },
        })
    }

    render() {
        var { commissions, pagination, authUser, users, statistical } = this.props;
        var { isLoading, selectedRowKeys, isOpenForm, currentItem, isModalOpen } = this.state;

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;

        var query = qs.parse(this.props.location.search.slice(1));

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: '50px',
                sorter: true
            },
            // {
            //     title: '#',
            //     width: '80px',
            //     align: 'center',
            //     key: 'action',
            //     render: (text, record) => (
            //         <div>
            //             {
            //                 checkPermission('commission', 'update') ? (
            //                     <>
            //                         <EditOutlined className='color-primary' style={{ cursor: 'pointer' }} onClick={() => this.onEdit(record.id)} />
            //                         <Divider type='vertical' />
            //                     </>
            //                 ) : null
            //             }
            //             {
            //                 checkPermission('commission', 'remove') ? (
            //                     <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} onClick={() => this.openAlert([record.id])} />
            //                 ) : null
            //             }
            //         </div>
            //     )
            // },
            {
                title: 'Nhân viên',
                dataIndex: 'employee',
                key: 'employee',
                width: '200px',
                render: (text, record) => {
                    return (<>{record?.employee?.full_name}</>)
                }
            },
            {
                title: 'Bộ phận',
                dataIndex: 'team',
                key: 'team',
                width: '100px',
                render: (text, record) => {
                    return (
                        <>{RenderTeams(record?.team)}</>
                    )
                }
            },
            {
                title: 'Khách hàng',
                dataIndex: 'customer',
                key: 'customer',
                width: '250px',
                render: (_, record) => {
                    if (record?.customer) {
                        return (<>{record?.customer?.full_name}</>)
                    }
                    return <Tag></Tag>;
                }
            },
            {
                title: (<>
                    <Tooltip title="Tên của dịch vụ" placement='top'>
                        Dịch vụ <QuestionCircleOutlined />
                    </Tooltip>
                </>),
                dataIndex: 'service',
                key: 'service',
                width: '200px',
                render: (_, record) => {
                    if (record?.service) {
                        return (<>{record?.service?.name}</>)
                    }
                    return <Tag></Tag>;
                }
            },
            {
                title: (<>
                    <Tooltip title={<>
                        Tổng số tiền của tất cả các dịch vụ tại thời điểm thanh toán.
                    </>} placement='top'>
                        Tổng số tiền dịch vụ <QuestionCircleOutlined />
                    </Tooltip>
                </>),
                dataIndex: 'total_service_revenue',
                key: 'total_service_revenue',
                width: '170px',
                render: (text, record) => {
                    if (text) {
                        return (<NumberFormat value={truncateDecimals(text)} displayType={'text'} suffix="đ" thousandSeparator={true} />)
                    }
                    return <Tag></Tag>;
                }
            },
            {
                title: (<>
                    <Tooltip title="Tổng số tiền khách thanh toán (bao gồm tất cả các dịch vụ) trong lần thanh toán này" placement='top'>
                        Tổng tiền thanh toán <QuestionCircleOutlined />
                    </Tooltip>
                </>),
                dataIndex: 'amount_paid',
                key: 'amount_paid',
                width: '180px',
                render: (text, record) => {
                    if (text) {
                        return (<NumberFormat value={truncateDecimals(text)} displayType={'text'} suffix="đ" thousandSeparator={true} />)
                    }
                    return <Tag></Tag>;
                }
            },
            {
                title: (<>
                    <Tooltip title="Giá dịch vụ là mức giá mà khách hàng phải trả cho dịch vụ này." placement='top'>
                        Giá dịch vụ <QuestionCircleOutlined />
                    </Tooltip>
                </>),
                dataIndex: 'service_revenue',
                key: 'service_revenue',
                width: '120px',
                render: (text, record) => {
                    if (text) {
                        return (<NumberFormat value={truncateDecimals(text)} displayType={'text'} suffix="đ" thousandSeparator={true} />)
                    }
                    return <Tag></Tag>;
                }
            },
            {
                title: (<>
                    <Tooltip title={<>
                        Tỷ lệ % của từng dịch vụ để tính số tiền thanh toán tương ứng trong lần thanh toán này.
                        <br />
                        Tỷ lệ dịch vụ = Giá dịch vụ / Tổng số tiền dịch vụ.
                    </>} placement='top'>
                        Tỷ lệ dịch vụ <QuestionCircleOutlined />
                    </Tooltip>
                </>),
                dataIndex: 'service_ratios',
                key: 'service_ratios',
                width: '120px',
                render: (text, record) => {
                    if (typeof text === "string") {
                        text = text.trim(); // Xóa khoảng trắng hai bên
                    }
                    const number = parseFloat(text);
                    if (!isNaN(number)) {
                        //return <>{(number * 100).toFixed(2)}%</>;
                        // return <>{text} - {(number * 100).toFixed(2)} - {Math.round(number * 100)}%</>;
                        //return <>{(number * 100).toFixed(4)}%</>;
                        return (<>{truncateDecimals(number * 100, 6)}%</>)
                    }
                    return <Tag></Tag>;
                }
            },
            {
                title: (<>
                    <Tooltip title={<>
                        Số tiền thực tế đã thanh toán cho dịch vụ cụ thể trong lần thanh toán này.
                        <br />
                        Được phân bổ theo Tỷ lệ dịch vụ.
                        <br />
                        Tiền TT của dịch vụ = Tổng tiền thanh toán x Tỷ lệ dịch vụ
                    </>} placement='top'>
                        Tiền TT của dịch vụ <QuestionCircleOutlined />
                    </Tooltip>
                </>),
                dataIndex: 'service_amount_paid',
                key: 'service_amount_paid',
                width: '160px',
                render: (text, record) => {
                    if (text) {
                        return (<NumberFormat value={truncateDecimals(text)} displayType={'text'} suffix="đ" thousandSeparator={true} />)
                    }
                    return <Tag></Tag>;
                }
            },
            {
                title: (<>
                    <Tooltip title={<>
                        Chi phí giảm trừ được tính riêng cho từng dịch vụ, không áp dụng chung.
                        <br />
                        Ví dụ:
                        <br />
                        Nếu khách hàng đăng ký cả Implant và Thẩm mỹ, thì chi phí xét nghiệm máu chỉ được giảm trừ cho dịch vụ Implant.
                        <br />
                        Trong khi đó, chi phí quẹt thẻ hoặc trả góp sẽ được phân bổ và chia đều cho cả hai dịch vụ theo tỷ lệ phù hợp.
                    </>} placement='top'>
                        Tổng chi phí giảm trừ <QuestionCircleOutlined />
                    </Tooltip>
                </>),
                dataIndex: 'total_additional_cost',
                key: 'total_additional_cost',
                width: '180px',
                render: (text, record) => {
                    if (text) {
                        return (<NumberFormat value={truncateDecimals(text)} displayType={'text'} suffix="đ" thousandSeparator={true} />)
                    }
                    return <Tag></Tag>;
                }
            },
            {
                title: (<>
                    <Tooltip title={<>
                        Tỷ lệ giảm trừ (%) = Tổng chi phí giảm trừ / Giá dịch vụ
                    </>} placement='top'>
                        Tỷ lệ giảm trừ <QuestionCircleOutlined />
                    </Tooltip>
                </>),
                dataIndex: 'additional_cost_rate',
                key: 'additional_cost_rate',
                width: '130px',
                render: (text, record) => {
                    if (typeof text === "string") {
                        text = text.trim(); // Xóa khoảng trắng hai bên
                    }
                    const number = parseFloat(text);
                    if (!isNaN(number)) {
                        //return <>{(number * 100).toFixed(2)}%</>;
                        // return <>{Math.round(number * 100)}%</>;
                        //return <>{(number * 100).toFixed(4)}%</>;
                        return (<>{truncateDecimals(number * 100, 6)}%</>)
                    }
                    return <Tag></Tag>;
                }
            },
            {
                title: (<>
                    <Tooltip title={<>
                        Số tiền được trừ trong mỗi lần thanh toán.
                        <br />
                        Được phân bổ chi phí giảm trừ theo Tỷ lệ giảm trừ cho từng lần thanh toán.
                        <br />
                        Số tiền giảm trừ = Tỷ lệ giảm trừ x Tiền TT của dịch vụ.
                    </>} placement='top'>
                        Số tiền giảm trừ <QuestionCircleOutlined />
                    </Tooltip>
                </>),
                dataIndex: 'partial_payment_deduction',
                key: 'partial_payment_deduction',
                width: '150px',
                render: (text, record) => {
                    if (text) {
                        return (<NumberFormat value={truncateDecimals(text)} displayType={'text'} suffix="đ" thousandSeparator={true} />)
                    }
                    return <Tag></Tag>;
                }
            },
            {
                title: (<>
                    <Tooltip title={<>
                        Số tiền sau khi trừ các chi phí.
                        <br />
                        DT sau giảm trừ = Tiền TT của dịch vụ - Số tiền giảm trừ.
                    </>} placement='top'>
                        DT sau giảm trừ <QuestionCircleOutlined />
                    </Tooltip>
                </>),
                dataIndex: 'net_revenue',
                key: 'net_revenue',
                width: '150px',
                render: (text, record) => {
                    if (text) {
                        return (<NumberFormat value={truncateDecimals(text)} displayType={'text'} suffix="đ" thousandSeparator={true} />)
                    }
                    return <Tag></Tag>;
                }
            },
            {
                title: (<>
                    <Tooltip title={<>
                        Tổng số nhân viên tham gia thực hiện dịch vụ, bao gồm nhân viên tư vấn hoặc bác sĩ trực tiếp thực hiện.
                    </>} placement='top'>
                        Số nhân viên <QuestionCircleOutlined />
                    </Tooltip>
                </>),
                dataIndex: 'num_employees',
                key: 'num_employees',
                width: '120px',
                render: (text, record) => {
                    if (text) {
                        return (<>{text}</>)
                    }
                    return <Tag></Tag>;
                }
            },
            {
                title: (<>
                    <Tooltip title={<>
                        DT bộ phận = DT sau giảm trừ / Số nhân viên
                    </>} placement='top'>
                        DT bộ phận <QuestionCircleOutlined />
                    </Tooltip>
                </>),
                dataIndex: 'employee_revenue',
                key: 'employee_revenue',
                width: '120px',
                render: (text, record) => {
                    if (text) {
                        return (<NumberFormat value={truncateDecimals(text)} displayType={'text'} suffix="đ" thousandSeparator={true} />)
                    }
                    return <Tag></Tag>;
                }
            },
            {
                title: (<>
                    <Tooltip title={<>
                        Tỷ lệ doanh thu được sử dụng để xác định doanh thu tính hoa hồng cho nhân viên.
                        <br />
                        Tỷ lệ này được thiết lập sẵn trong phần 'Cấu hình doanh thu'.
                    </>} placement='top'>
                        Tỷ lệ DT H.Hồng <QuestionCircleOutlined />
                    </Tooltip>
                </>),
                dataIndex: 'commission_revenue_rate',
                key: 'commission_revenue_rate',
                width: '150px',
                render: (text, record) => {
                    if (text) {
                        return (<>{text}%</>)
                    }
                    return <Tag></Tag>;
                }
            },
            {
                title: (<>
                    <Tooltip title={<>
                        DT H.Hồng giữ lại = DT bộ phận - DT tính hoa hồng
                    </>} placement='top'>
                        DT H.Hồng giữ lại <QuestionCircleOutlined />
                    </Tooltip>
                </>),
                dataIndex: 'retained_commission_revenue',
                key: 'retained_commission_revenue',
                width: '150px',
                render: (text, record) => {
                    if (text) {
                        return (<NumberFormat value={truncateDecimals(text)} displayType={'text'} suffix="đ" thousandSeparator={true} />)
                    }
                    return <Tag></Tag>;
                }
            },
            {
                title: (<>
                    <Tooltip title={<>
                        H.Hồng giữ lại = DT H.Hồng giữ lại x Tỷ lệ H.Hồng
                    </>} placement='top'>
                        H.Hồng giữ lại <QuestionCircleOutlined />
                    </Tooltip>
                </>),
                dataIndex: 'retained_commission',
                key: 'retained_commission',
                width: '130px',
                render: (text, record) => {
                    if (text) {
                        return (<NumberFormat value={truncateDecimals(text)} displayType={'text'} suffix="đ" thousandSeparator={true} />)
                    }
                    return <Tag></Tag>;
                }
            },
            {
                title: (<>
                    <Tooltip title={<>
                        Số tiền cuối cùng được sử dụng để tính hoa hồng cho nhân viên.
                    </>} placement='top'>
                        DT tính hoa hồng <QuestionCircleOutlined />
                    </Tooltip>
                </>),
                dataIndex: 'revenue_for_commission',
                key: 'revenue_for_commission',
                width: '150px',
                render: (text, record) => {
                    if (text) {
                        return (<NumberFormat value={truncateDecimals(text)} displayType={'text'} suffix="đ" thousandSeparator={true} />)
                    }
                    return <Tag></Tag>;
                }
            },
            {
                title: (<>
                    <Tooltip title={<>
                        Tỷ lệ này được thiết lập sẵn trong phần 'Cấu hình quy tắc'.
                    </>} placement='top'>
                        Tỷ lệ H.Hồng <QuestionCircleOutlined />
                    </Tooltip>
                </>),
                dataIndex: 'commission_rate',
                key: 'commission_rate',
                width: '120px',
                render: (text, record) => {
                    if (record.commission_rule == 'percentage') {
                        return (<>{text}%</>)
                    } else if (record.commission_rule == 'fixed') {
                        return (<NumberFormat value={text} displayType={'text'} suffix="đ" thousandSeparator={true} />)
                    }
                }
            },
            {
                title: (<>
                    <Tooltip title={<>
                        Số tiền hoa hồng mà nhân viên được nhận sau khi tính toán theo quy định.
                    </>} placement='top'>
                        H.Hồng được hưởng <QuestionCircleOutlined />
                    </Tooltip>
                </>),
                dataIndex: 'commission_amount',
                key: 'commission_amount',
                width: '170px',
                render: (text, record) => {
                    if (text) {
                        return (<NumberFormat value={truncateDecimals(text)} displayType={'text'} suffix="đ" thousandSeparator={true} />)
                    }
                    return <Tag></Tag>;
                }
            },
            {
                title: (<>
                    <Tooltip title={<>
                        500 triệu là mốc tổng doanh thu trong tháng của nhân viên sale.
                        <br />
                        Doanh thu vượt mức 500 triệu sẽ được tính hoa hồng 3%, còn phần doanh thu từ 500 triệu trở xuống sẽ tính hoa hồng theo tỷ lệ được thiết lập sẵn.
                        <br />
                        Ví dụ: Trong tháng 1, nhân viên sale đạt tổng doanh thu 700 triệu. Khi đó:
                        <br />
                        500 triệu đầu tiên được tính hoa hồng 2%.
                        <br />
                        200 triệu còn lại (phần vượt 500 triệu) được tính hoa hồng 3%.
                    </>} placement='top'>
                        {`DT > 500 triệu x 3%`} <QuestionCircleOutlined />
                    </Tooltip>
                </>),
                dataIndex: 'additional_revenue',
                key: 'additional_revenue',
                width: '170px',
                render: (text, record) => {
                    if (text) {
                        return (<NumberFormat value={truncateDecimals(text)} displayType={'text'} suffix="đ" thousandSeparator={true} />)
                    }
                    return <Tag></Tag>;
                }
            },
            {
                title: (<>
                    <Tooltip title={<>
                        Số tiền hoa hồng vượt mức 500 triệu mà nhân viên được nhận.
                    </>} placement='top'>
                        {`H.Hồng > 500 triệu`} <QuestionCircleOutlined />
                    </Tooltip>
                </>),
                dataIndex: 'additional_commission',
                key: 'additional_commission',
                width: '170px',
                render: (text, record) => {
                    if (text) {
                        return (<NumberFormat value={truncateDecimals(text)} displayType={'text'} suffix="đ" thousandSeparator={true} />)
                    }
                    return <Tag></Tag>;
                }
            },
            {
                title: 'Ghi chú',
                dataIndex: 'note',
                key: 'note',
                width: '250px',
                render: (text, record) => {
                    return (<ExpandableText text={text} rows={2} />)
                }
            },
            {
                title: 'Ngày tính hoa hồng',
                dataIndex: 'created_at',
                key: 'created_at',
                sorter: true,
                width: '200px',
                render: (text, record) => {
                    return (
                        <div>{isValidDate(text)}</div>
                    )
                }
            },
        ];

        const roles = ['ADMIN', 'THUQUY', 'KETOAN'];

        return (
            <div style={{ position: 'relative' }}>
                <PageTitle routes={routes} title="Hoa hồng" />
                <div
                    style={{ color: 'blue', position: 'absolute', top: 13, left: '170px', cursor: 'pointer' }}
                    className="table-button"
                    onClick={() => this.setState({ isModalOpen: true })}>
                    <QuestionCircleOutlined /> Xem quy trình tính hoa hồng
                </div>
                <div>
                    <TableActionBar
                        isShowAddButton={false}
                        isShowDeleteButton={checkPermission('commission', 'remove')}
                        onAdd={() => this.toggleOpenForm(true)}
                        onDelete={() => this.onRemove()}
                        disabled={!selectedRowKeys.length ? true : false}
                        showFilter={false}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                    >
                        {
                            roles.includes(authUser.role_code) && (
                                <>
                                    <BaseSelect
                                        options={users || []}
                                        optionValue="user_id"
                                        optionLabel="full_name"
                                        placeholder="Nhân viên"
                                        showSearch={true}
                                        className="table-button"
                                        style={{ width: '200px' }}
                                        defaultValue={parseInt(query.employee_id) || null}
                                        onChange={(value) => this.onChangeFilter("employee_id", value)}
                                    />

                                    <BaseSelect
                                        options={teams || []}
                                        showSearch={true}
                                        className="table-button"
                                        placeholder="Bộ phận"
                                        style={{ width: '150px' }}
                                        defaultValue={query?.team || null}
                                        onChange={(value) => this.onChangeFilter("team", value)}
                                    />
                                </>
                            )
                        }
                        <RangePicker
                            format="DD/MM/YYYY"
                            placeholder={['Từ ngày', 'đến ngày']}
                            className="table-button"
                            style={{ width: '220px' }}
                            onChange={(value) => this.onChangeFilter('monthly', value)}
                            defaultValue={[query.start_date ? moment(query.start_date, 'YYYY-MM-DD') : null, query.end_date ? moment(query.end_date, 'YYYY-MM-DD') : null]}
                        />
                        <Button className="table-button" icon={<ReloadOutlined />} loading={isLoading} onClick={() => this.loadData(query)}>Refresh</Button>

                        {
                            hasSelected ? <span>Đang chọn {selectedRowKeys.length} bản ghi</span> : ''
                        }
                    </TableActionBar>
                </div>
                <Row gutter={16} className="mb-2" justify="space-between" style={{ margin: 0 }}>
                    <Card className="commission-statistic">
                        <StatisticNumber
                            title="Doanh thu hoa hồng giữ lại"
                            value={statistical?.retained_commission_revenue ?? 0}
                            precision={0}
                            unit="đ"
                        />
                    </Card>
                    <Card className="commission-statistic">
                        <StatisticNumber
                            title="Hoa hồng giữ lại"
                            value={statistical?.retained_commission ?? 0}
                            precision={0}
                        />
                    </Card>
                    <Card className="commission-statistic">
                        <StatisticNumber
                            title="Doanh thu tính hoa hồng"
                            value={statistical?.revenue_for_commission ?? 0}
                            precision={0}
                            unit="đ"
                        />
                    </Card>
                    <Card className="commission-statistic">
                        <StatisticNumber
                            title="Hoa hồng được hưởng "
                            value={statistical?.commission_amount ?? 0}
                            precision={0}
                        />
                    </Card>
                    <Card className="commission-statistic">
                        <StatisticNumber
                            title="Hoa hồng > 500 triệu"
                            value={statistical?.additional_commission ?? 0}
                            precision={0}
                            unit="đ"
                        />
                    </Card>
                    <Card className="commission-statistic">
                        <StatisticNumber
                            title="Tổng hoa hồng được hưởng"
                            value={statistical?.total_commission ?? 0}
                            precision={0}
                            unit="đ"
                        />
                    </Card>
                </Row>
                <div className='has-custom-column-table-btn'>
                    <DynamicTable
                        rowKey="id"
                        dataSource={commissions || []}
                        columns={columns}
                        isLoading={isLoading}
                        pagination={pagination}
                        rowSelection={rowSelection}
                        moduleKey="commission"
                        onChangeTable={(pagination, sorter) => this.onChangeTable(pagination, sorter)}
                    />
                </div>
                <CommissionForm
                    visible={isOpenForm}
                    currentItem={currentItem}
                    onCancel={() => this.toggleOpenForm(false)}
                    loadData={() => this.loadData(query)}
                />

                <Modal
                    title="Quy trình tính hoa hồng"
                    open={isModalOpen}
                    onCancel={() => this.setState({ isModalOpen: false })}
                    footer={null}
                    width={isMobile ? '100%' : '50%'}
                >
                    Hoa hồng được tính ngay khi có Phiếu thu mới, với điều kiện:<br /><br />
                    Dịch vụ chốt phải có đầy đủ thông tin về nhân viên tư vấn và bác sĩ khám.<br />
                    Mỗi khi khách hàng thanh toán (phát sinh Phiếu thu mới), hệ thống sẽ thực hiện các bước sau:<br /><br />
                    1. Xác định tỷ lệ của từng dịch vụ với số tiền thanh toán.<br />
                    2. Phân bổ số tiền thanh toán của khách hàng theo tỷ lệ đã xác định.<br />
                    3. Khấu trừ các khoản phí giảm trừ cho từng dịch vụ trước khi tính hoa hồng.<br />
                    4. Tính doanh thu cá nhân cho từng nhân viên liên quan.<br />
                    5. Xác định doanh thu hoa hồng giữ lại theo quy định.<br />
                    6. Tính toán hoa hồng cho nhân viên tư vấn và bác sĩ.<br />
                    7. Hoa hồng vượt mức:<br />
                    Đối với bộ phận tư vấn, nếu tổng hoa hồng trong tháng vượt 500 triệu, hệ thống sẽ tự động tính khoản vượt mức vào ngày cuối cùng của tháng.<br />
                    Khoản vượt mức này sẽ được cộng vào Hoa hồng được hưởng của nhân viên.

                </Modal>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        commissions: state.Commission.commissions,
        pagination: state.Commission.pagination,
        authUser: state.auth.authUser,
        users: state.config.users,
        statistical: state.Commission.statistical
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getCommissions: (filter) => dispatch(getCommissions(filter)),
        getSpecificCommission: (id) => dispatch(getSpecificCommission(id)),
        removeCommission: (ids) => dispatch(removeCommission(ids)),
        getStatistical: filter => dispatch(getStatistical(filter)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommissionList);
