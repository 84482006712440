import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DeleteOutlined, EditOutlined, CheckCircleTwoTone, CloseCircleTwoTone, ReloadOutlined } from '@ant-design/icons';
import { Modal, Divider, Tag, Button, Tooltip, Row, Col, Alert } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _ from 'lodash';
import qs from 'qs';
import { checkPermission } from '../../utils/permission';
import { cleanObject, isValidDate } from '../../utils/helpers';
import { getCommissionRevenue, getSpecificCommissionRevenue, removeCommissionRevenue } from '../../redux/actions/CommissionRevenue';
import ExpandableText from '../../components/ExpandableText';
import DynamicTable from '../../components/DynamicTable';
import AssignmentsServiceForm from './AssignmentsServiceForm';
import AssignmentsEmployeeForm from './AssignmentsEmployeeForm';
import CommissionRevenueForm from './CommissionRevenueForm';

const { confirm } = Modal;

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/commission-revenue',
        breadcrumbName: 'Cấu hình doanh thu tính hoa hồng',
    },
]

class CommissionRevenueList extends Component {
    state = {
        isLoading: false,
        selectedRowKeys: [],
        isOpenForm: false,
        currentItem: null,
        openAssignServiceForm: false,
        openAssignEmployeeForm: false
    }

    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.loadData(query);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.loadData(query);
        }
    }

    loadData(query) {
        this.setState({ isLoading: true });
        this.props.getCommissionRevenue(query).then(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }

        query = cleanObject(query);

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        });
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    toggleOpenForm(isOpenForm, currentItem = null) {
        this.setState({ isOpenForm: isOpenForm, currentItem: currentItem });
    }

    onEdit(id) {
        this.props.getSpecificCommissionRevenue(id).then(res => {
            this.toggleOpenForm(true, res);
        })
    }

    toggleOpenAssignServiceForm(openAssignServiceForm, currentItem = null) {
        this.setState({ openAssignServiceForm: openAssignServiceForm, currentItem: currentItem });
    }

    onOpenAssignServiceForm(id) {
        this.props.getSpecificCommissionRevenue(id).then(res => {
            this.toggleOpenAssignServiceForm(true, res);
        })
    }

    toggleOpenAssignEmployeeForm(openAssignEmployeeForm, currentItem = null) {
        this.setState({ openAssignEmployeeForm: openAssignEmployeeForm, currentItem: currentItem });
    }

    onOpenAssignEmployeeForm(id) {
        this.props.getSpecificCommissionRevenue(id).then(res => {
            this.toggleOpenAssignEmployeeForm(true, res);
        })
    }

    onRemove() {
        this.props.removeCommissionRevenue(this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: []
            });
            setTimeout(() => {
                Modal.destroyAll();
            }, 800);
            this.loadData();
        })
    }

    openAlert(id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá những bản ghi này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.removeCommissionRevenue(id).then(() => {
                setTimeout(() => {
                    Modal.destroyAll();
                }, 800);
                this.loadData();
            }),
            onCancel() { },
        })
    }

    render() {
        var { commission_revenue, pagination } = this.props;
        var { isLoading, selectedRowKeys, isOpenForm, currentItem, openAssignServiceForm, openAssignEmployeeForm } = this.state;

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;

        var query = qs.parse(this.props.location.search.slice(1));

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: '50px',
                sorter: true
            },
            {
                title: '#',
                width: '80px',
                align: 'center',
                key: 'action',
                render: (text, record) => (
                    <div>
                        {
                            checkPermission('commission_revenue', 'update') ? (
                                <>
                                    <EditOutlined className='color-primary' style={{ cursor: 'pointer' }} onClick={() => this.onEdit(record.id)} />
                                    <Divider type='vertical' />
                                </>
                            ) : null
                        }
                        {
                            checkPermission('commission_revenue', 'remove') ? (
                                <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} onClick={() => this.openAlert([record.id])} />
                            ) : null
                        }
                    </div>
                )
            },
            {
                title: 'Tên',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: 'Tỷ lệ',
                dataIndex: 'rate',
                key: 'rate',
                width: '80px',
                render: (text, record) => {
                    return (<>{text}%</>)
                }
            },
            {
                title: 'Dịch vụ áp dụng',
                dataIndex: 'services',
                key: 'services',
                width: '250px',
                render: (_, record) => {
                    return (<>
                        {record?.services
                            ?.filter(item => item.service) // Lọc những mục hợp lệ
                            .map((item, index) => (
                                <Tag style={{ border: 'none' }} color="blue" key={index}>{item.service.name}</Tag>
                            )) || '-'}

                        <Tooltip title="Bấm để cập nhật" placement="left">
                            <EditOutlined className='color-primary' style={{ cursor: 'pointer' }} onClick={() => this.onOpenAssignServiceForm(record.id)} />
                        </Tooltip>
                    </>)
                }
            },
            {
                title: 'Nhân viên áp dụng',
                dataIndex: 'employees',
                key: 'employees',
                width: '250px',
                render: (_, record) => {
                    return (<>
                        {
                            record?.employees
                                ?.filter(item => item.employee) // Lọc những mục hợp lệ
                                .map((item, index) => (
                                    <Tag style={{ border: 'none' }} color="blue" key={index}>{item.employee.full_name}</Tag>
                                )) || '-'
                        }
                        <Tooltip title="Bấm để cập nhật" placement="left">
                            <EditOutlined className='color-primary' style={{ cursor: 'pointer' }} onClick={() => this.onOpenAssignEmployeeForm(record.id)} />
                        </Tooltip>
                    </>)
                }
            },
            {
                title: 'Ghi chú',
                dataIndex: 'description',
                key: 'description',
                width: '200px',
                render: (text, record) => {
                    return (<ExpandableText text={text} rows={2} />)
                }
            },
            {
                title: 'Trạng thái',
                dataIndex: 'status',
                key: 'status',
                width: '100px',
                align: 'center',
                render: (text, record) => {
                    if (text == 1) return <CheckCircleTwoTone twoToneColor="#52c41a" />;
                    if (text == 0) return <CloseCircleTwoTone twoToneColor="#e74c3c" />;
                }
            },
            {
                title: 'Người tạo',
                dataIndex: 'created_by',
                key: 'created_by',
                sorter: true,
                width: '200px',
                render: (text, record) => {
                    return (<>{record?.created_user?.full_name}</>)
                }
            },
            {
                title: 'Ngày tạo',
                dataIndex: 'created_at',
                key: 'created_at',
                sorter: true,
                width: '140px',
                render: (text, record) => {
                    return (
                        <div>{isValidDate(text)}</div>
                    )
                }
            },
        ];

        return (
            <div>
                <PageTitle routes={routes} title="Cấu hình doanh thu tính hoa hồng" />
                <div>
                    <TableActionBar
                        isShowAddButton={checkPermission('commission_revenue', 'create')}
                        isShowDeleteButton={checkPermission('commission_revenue', 'remove')}
                        onAdd={() => this.toggleOpenForm(true)}
                        onDelete={() => this.onRemove()}
                        disabled={!selectedRowKeys.length ? true : false}
                        showFilter={false}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                    >
                        <Button className="table-button" icon={<ReloadOutlined />} loading={isLoading} onClick={() => this.loadData(query)}>Refresh</Button>
                        {
                            hasSelected ? <span>Đang chọn {selectedRowKeys.length} bản ghi</span> : ''
                        }
                    </TableActionBar>
                </div>
                <Row gutter={16}>
                    <Col xs={24} md={24} className='mb-3'>
                        <Alert
                            description={<><strong>Lưu ý: </strong>
                                Một cấu hình có thể được áp dụng cho nhiều nhân viên và nhiều dịch vụ.
                                Tuy nhiên, mỗi nhân viên chỉ có thể áp dụng một cấu hình duy nhất cho một dịch vụ cụ thể.
                            </>}
                            type="warning"
                        />
                    </Col>
                </Row>
                <div className='has-custom-column-table-btn'>
                    <DynamicTable
                        rowKey="id"
                        dataSource={commission_revenue || []}
                        columns={columns}
                        isLoading={isLoading}
                        pagination={pagination}
                        rowSelection={rowSelection}
                        moduleKey="commission_revenue"
                        onChangeTable={(pagination, sorter) => this.onChangeTable(pagination, sorter)}
                    />
                </div>
                <CommissionRevenueForm
                    visible={isOpenForm}
                    currentItem={currentItem}
                    onCancel={() => this.toggleOpenForm(false)}
                    loadData={() => this.loadData(query)}
                />

                <AssignmentsServiceForm
                    visible={openAssignServiceForm}
                    currentItem={currentItem}
                    onCancel={() => this.toggleOpenAssignServiceForm(false)}
                    loadData={() => this.loadData(query)}
                />

                <AssignmentsEmployeeForm
                    visible={openAssignEmployeeForm}
                    currentItem={currentItem}
                    onCancel={() => this.toggleOpenAssignEmployeeForm(false)}
                    loadData={() => this.loadData(query)}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        commission_revenue: state.CommissionRevenue.commission_revenue,
        pagination: state.CommissionRevenue.pagination,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getCommissionRevenue: (filter) => dispatch(getCommissionRevenue(filter)),
        getSpecificCommissionRevenue: (id) => dispatch(getSpecificCommissionRevenue(id)),
        removeCommissionRevenue: (ids) => dispatch(removeCommissionRevenue(ids))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommissionRevenueList);
