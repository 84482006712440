import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Drawer, Button, Input, Space, Row, Col, InputNumber, Alert } from 'antd';
import { isMobile } from 'react-device-detect';
import BaseSelect from '../../components/Elements/BaseSelect';
import { _newLine } from '../../utils/helpers';
import { createAdditionalCost, updateAdditionalCost } from '../../redux/actions/AdditionalCost';
import { getCustomerServices } from '../../redux/actions/CustomerServicesActions'

class AdditionalCostForm extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps?.customer_id != this.props?.customer_id) {
            this.props.getCustomerServices(this.props?.customer_id);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.currentItem != this.props.currentItem) {
            this.setState({
                payment_method: nextProps?.currentItem?.payment_method,
            });
        }
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isLoading: true });
                try {
                    values.customer_id = this.props?.customer_id;
                    if (this.props.currentItem) {
                        await this.props.updateAdditionalCost(this.props.currentItem?.id, values);
                    } else {
                        await this.props.createAdditionalCost(values);
                    }
                    console.log(values)

                    this.props.reloadData();
                    this.onClose();
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onClose() {
        this.props.onCancel();
        this.props.form.resetFields();
        this.setState({ isLoading: false });
    }

    render() {
        var { isLoading } = this.state;
        var { visible, currentItem, customer_services } = this.props;
        const { getFieldDecorator } = this.props.form;

        return (
            <Drawer
                visible={visible}
                title={`${currentItem ? "Cập nhật" : "Thêm mới"}`}
                onOk={(e) => this.submit(e)}
                onClose={() => this.onClose()}
                maskClosable={false}
                width={isMobile ? '100%' : '50%'}
                footer={
                    <div style={{ textAlign: 'right', }}>
                        <Space>
                            <Button key="back" onClick={() => this.onClose()}>
                                Huỷ
                            </Button>
                            <Button
                                loading={isLoading}
                                key="submit"
                                type="primary"
                                onClick={(e) => this.submit(e)}
                            >
                                {currentItem ? "Cập nhật" : "Thêm mới"}
                            </Button>
                        </Space>
                    </div>
                }
            >
                {visible ?
                    <>
                        <Row gutter={16}>
                            <Col xs={24} md={24} className='mb-3'>
                                <Alert
                                    description={<>
                                        Chi phí giảm trừ được tính riêng cho từng dịch vụ, không áp dụng chung.
                                        <br />
                                        Ví dụ:
                                        <br />
                                        Nếu khách hàng đăng ký cả Implant và Thẩm mỹ, thì chi phí xét nghiệm máu chỉ được giảm trừ cho dịch vụ Implant.
                                        <br />
                                        Trong khi đó, chi phí quẹt thẻ hoặc trả góp sẽ được phân bổ và chia đều cho cả hai dịch vụ theo tỷ lệ phù hợp.
                                        <br />
                                    </>}
                                    type="warning"
                                />
                            </Col>
                        </Row>
                        <Form layout='vertical'>
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <Form.Item label="Tên chi phí">
                                        {getFieldDecorator('name', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Vui lòng chọn!',
                                                },
                                            ],
                                            initialValue: currentItem?.name ?? null
                                        })(<Input placeholder='Ví dụ: Chi phí xét nghiệm' />)}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Form.Item label="Số tiền">
                                        {getFieldDecorator('amount', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Vui lòng chọn!',
                                                },
                                            ],
                                            initialValue: currentItem?.amount ?? null
                                        })(<InputNumber step={1000} min={0} className='w-100' formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />)}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Form.Item
                                        label="Dịch vụ áp dụng"
                                        extra="Để trống nếu chi phí này áp dụng cho tất cả các dịch vụ."
                                    >
                                        {getFieldDecorator('customer_service_ids', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: 'Vui lòng chọn!',
                                                },
                                            ],
                                            initialValue: currentItem?.customer_service_ids ?? []
                                        })(
                                            <BaseSelect
                                                options={customer_services || []}
                                                optionValue="id"
                                                optionLabel="service_name"
                                                showSearch
                                                mode='multiple'
                                                placeholder="Chọn một hoặc nhiều bản ghi"
                                                style={{ width: '100%' }}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Form.Item label="Ghi chú">
                                        {getFieldDecorator("note", {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng chọn!",
                                                },
                                            ],
                                            initialValue: _newLine(currentItem?.note) ?? null
                                        })(<Input.TextArea rows={3} placeholder="Ghi chú" />)}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </>
                    : null}
            </Drawer>
        )
    }
}

function mapStateToProps(state) {
    return {
        customer_services: state.CustomerServicesReducer.customer_services
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createAdditionalCost: (data) => dispatch(createAdditionalCost(data)),
        updateAdditionalCost: (id, data) => dispatch(updateAdditionalCost(id, data)),
        getCustomerServices: (customer_id) => dispatch(getCustomerServices(customer_id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'AdditionalCostForm' })(AdditionalCostForm));
