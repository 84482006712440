import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DownOutlined, ReloadOutlined, UserOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { Modal, Tooltip, Tag, DatePicker, TreeSelect, Avatar, Popover, Typography, Tabs, Button, Card } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _, { } from 'lodash';
import qs from 'qs';
import { Form } from '@ant-design/compatible';
import TextTruncate from 'react-text-truncate';
import CustomerAddForm from '../customer/CustomerAddForm';
import CustomerDetail from '../customer/CustomerDetail';
import { checkPermission } from '../../utils/permission';
import { cleanObject, stripHtml, makeTree, getFirstLetterOfWords, repeatPhoneNumber, CustomerServiceStatus } from '../../utils/helpers';
import HtmlParser from 'react-html-parser';
import BaseSelect from '../../components/Elements/BaseSelect';
import CustomerScheduleDetail from '../customer/CustomerScheduleDetail';
import HandlePhoneNumber from '../../components/HandlePhoneNumber';
import moment from 'moment';
import { getSpecificCustomer, removeCustomer } from '../../redux/actions/CustomerActions';
import { getListProvinces } from '../../redux/actions/RegionsActions';
import { getCustomerSourceActive } from '../../redux/actions/CustomerSourceActions';
import { getBranchActive } from '../../redux/actions/BranchActions';
import { getListLookupCustomer } from '../../redux/actions/LookupCustomerActions';
import DynamicTable from '../../components/DynamicTable';
import DropdownActions from '../customer/DropdownActions';
import TelesaleAssignedToStaffForm from '../../components/TelesaleAssignedToStaffForm';

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const { Paragraph } = Typography;
const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/lookup-customer',
        breadcrumbName: 'Tra cứu khách hàng',
    },
]

class LookupCustomerList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            selectedRowKeys: [],
            isOpenAddForm: false,
            currentItem: null,
            isOpenDetail: false,
            currentData: null,
            loadingDetail: false,
            visibleAssignedToStaff: false
        }
    }

    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getListLookupCustomer(query).then(() => {
            this.setState({ isLoading: false });
        })
        this.props.getListProvinces();
        this.props.getCustomerSourceActive();
        this.props.getBranchActive();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getListLookupCustomer(query).then(() => {
                this.setState({
                    isLoading: false
                })
            })
        }
    }

    onChangeFilter(name, value) {
        let query = qs.parse(this.props.location.search.slice(1));

        const handleDateRange = (startKey, endKey, dateValue) => {
            if (dateValue && dateValue.length > 0) {
                query[startKey] = moment(dateValue[0]).format('YYYY-MM-DD');
                query[endKey] = moment(dateValue[1]).format('YYYY-MM-DD');
            } else {
                delete query[startKey];
                delete query[endKey];
            }
        };

        if (name === 'created_at') {
            handleDateRange('created_date_start', 'created_date_end', value);
        } else if (name === 'appointment_schedule') {
            handleDateRange('appointment_schedule_start', 'appointment_schedule_end', value);
        } else {
            query[name] = value;
        }

        delete query.created_at;
        delete query.appointment_schedule;

        query = cleanObject(query);
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        });
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    openAddForm(isOpenAddForm) {
        this.setState({ isOpenAddForm: isOpenAddForm })
    }

    onRemove() {
        this.props.removeCustomer(this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: []
            });
            setTimeout(() => {
                Modal.destroyAll();
            }, 800);
        })
    }

    toggleOpenDetail(isOpenDetail, data = null) {
        this.setState({ isOpenDetail: isOpenDetail, currentData: data });
    }

    onDetail(id) {
        this.setState({ current_id: id, loadingDetail: true })
        this.props.getSpecificCustomer(id).then(res => {
            this.setState({ loadingDetail: false })
            this.toggleOpenDetail(true, res);
        })
    }

    openScheduleDetail(customer_id) {
        this.props.getSpecificCustomer(customer_id).then(res => {
            this.setState({ isOpenScheduleDetail: true, currentData: res });
        })
    }

    reloadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getListLookupCustomer(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    onCancel() {
        this.props.form.resetFields();
        this.setState({
            visibleAssignedToStaff: false,
            selectedRowKeys: []
        })
    }

    render() {
        var { pagination,
            customerClassActiveList,
            customerStatusActiveList,
            service_active_list,
            customerInteractiveTypeActiveList,
            customerInteractiveStatusActiveList,
            list_provinces,
            customer_source_active_list,
            branchActiveList,
            lookup_customerList,
            users, doctors
        } = this.props;
        var { isLoading, selectedRowKeys,
            isOpenAddForm,
            isOpenDetail,
            currentData, current_id,
            loadingDetail,
            isOpenScheduleDetail,
            visibleAssignedToStaff
        } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;

        var query = qs.parse(this.props.location.search.slice(1));
        const service = makeTree(service_active_list || [], 0);

        const columns = [
            {
                title: '#',
                align: 'center',
                width: '50px',
                dataIndex: 'actions',
                key: 'actions',
                render: (text, record) => {
                    return (<DropdownActions record={record} reloadData={() => this.reloadData()} />);
                }
            },
            {
                title: 'Họ và tên',
                dataIndex: 'full_name',
                key: 'full_name',
                width: '250px',
                render: (text, record) => {
                    //if (record.full_name) {
                    if (checkPermission('customer', 'detail')) {
                        return (
                            <Tooltip title="Xem hồ sơ" placement="right">
                                <div className='color-primary item-action-btn' onClick={() => this.onDetail(record.id)}>
                                    {
                                        record.avatar ? (
                                            <Avatar size='small' icon={<UserOutlined />} src={record.avatar} />
                                        ) : (
                                            <Avatar
                                                style={{
                                                    backgroundColor: '#31cdf5',
                                                    verticalAlign: 'middle'
                                                }}
                                                size="small">
                                                {record.full_name ? getFirstLetterOfWords(record.full_name) : ''}
                                            </Avatar>
                                        )
                                    }
                                    &nbsp;{record.full_name}
                                </div>
                            </Tooltip>
                        )
                    } else {
                        return (
                            <>
                                {
                                    record.avatar ? (
                                        <Avatar size='small' icon={<UserOutlined />} src={record.avatar} />
                                    ) : (
                                        <Avatar
                                            style={{
                                                backgroundColor: '#31cdf5',
                                                verticalAlign: 'middle'
                                            }}
                                            size="small">
                                            {record.full_name ? getFirstLetterOfWords(record.full_name) : ''}
                                        </Avatar>
                                    )
                                }
                                &nbsp;{record.full_name}
                            </>
                        )
                    }
                    // } else {
                    //     return <>N/A</>
                    // }
                }
            },
            {
                title: 'Mã KH',
                dataIndex: 'code',
                key: 'code',
                width: '140px',
                sorter: true,
                render: (text, record) => {
                    if (text) {
                        return (
                            <Paragraph style={{ margin: 0 }} copyable={
                                {
                                    text: record ? record.code : null,
                                    tooltips: false
                                }
                            }>
                                {text}
                            </Paragraph>
                        )
                    }
                }
            },
            {
                title: 'Số sổ khám',
                dataIndex: 'medical_book_number',
                key: 'medical_book_number',
                width: '110px',
                sorter: true,
                render: (text, record) => {
                    if (text) {
                        return (
                            <Paragraph style={{ margin: 0 }} copyable={
                                {
                                    text: record ? record.medical_book_number : null,
                                    tooltips: false
                                }
                            }>
                                {text}
                            </Paragraph>
                        )
                    }
                }
            },
            {
                title: 'Mã thẻ',
                dataIndex: 'card_number',
                key: 'card_number',
                width: '140px',
                sorter: true,
                render: (text, record) => {
                    if (text) {
                        return (
                            <Paragraph style={{ margin: 0 }} copyable={
                                {
                                    text: record ? record.card_number : null,
                                    tooltips: false
                                }
                            }>
                                {text}
                            </Paragraph>
                        )
                    }
                }
            },
            {
                title: 'Điện thoại',
                dataIndex: 'phone',
                width: '120px',
                key: 'phone',
                render: (text, record) => {
                    return (
                        <>
                            <HandlePhoneNumber
                                permission={checkPermission('customer', 'phonenumber')}
                                inputPinCode={checkPermission('customer', 'input_pin_code')}
                                customer_id={record?.id}
                                hiddenPhoneNumber={repeatPhoneNumber(text)}
                                fullPhoneNumber={text}
                            />
                            {record.customer_family?.length > 0 && record.customer_family.map((item, index) => {
                                return (
                                    <div>
                                        <HandlePhoneNumber
                                            permission={checkPermission('customer', 'phonenumber')}
                                            inputPinCode={checkPermission('customer', 'input_pin_code')}
                                            customer_id={item?.related_customer?.id}
                                            hiddenPhoneNumber={repeatPhoneNumber(item.related_customer?.phone)}
                                            fullPhoneNumber={item?.related_customer?.phone}
                                            key={index}
                                        />
                                    </div>
                                )
                            })}
                        </>
                    )
                }
            },
            {
                title: 'Lịch hẹn',
                dataIndex: 'appointment_schedule',
                key: 'appointment_schedule',
                width: '180px',
                render: (text, record) => {
                    if (text && Array.isArray(text) && text.length) {
                        let content = text.map(item => {
                            return (
                                <div style={{ padding: '5px 0' }}>
                                    {moment(item.time).format('DD/MM/YYYY HH:mm')}
                                </div>
                            )
                        })
                        let first_item = text[0]?.time;
                        return (
                            <div className='item-action-btn' onClick={() => this.openScheduleDetail(record.id)}>
                                <Popover placement="right" content={content}>
                                    {moment(first_item).format('DD/MM/YYYY HH:mm')}&nbsp;
                                    <DownOutlined />
                                </Popover>
                            </div>
                        )
                    } else {
                        return (<></>)
                    }
                }
            },
            {
                title: 'Trạng thái',
                dataIndex: 'customer_status',
                key: 'customer_status',
                width: '200px',
                render: (text, record) => {
                    if (record.customer_status_color) {
                        return (
                            <Tag color={record.customer_status_color}>{text}</Tag>
                        )
                    } else {
                        return (<Tag>{text}</Tag>)
                    }
                }
            },
            {
                title: 'Ghi chú',
                dataIndex: 'note',
                key: 'note',
                width: '250px',
                render: (text, record) => {
                    return (
                        <div style={{ wordBreak: "break-all" }}>
                            <Tooltip title={HtmlParser(text)} placement="topLeft">
                                <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: 'Cơ sở',
                dataIndex: 'branch_name',
                key: 'branch_name',
                width: '130px',
                render: (text, record) => {
                    return (<>{text}</>)
                }
            },
            {
                title: 'Nguồn',
                dataIndex: 'customer_source',
                key: 'customer_source',
                width: '100px',
                render: (text, record) => {
                    return (<>{text}</>)
                }
            },
            {
                title: 'Ngày tạo',
                dataIndex: 'created_at',
                key: 'created_at',
                width: '100px',
                sorter: true,
                render: (text, record) => {
                    if (text) {
                        return (
                            <div>{moment(text).format('DD/MM/YYYY')}</div>
                        )
                    } else {
                        return (<Tag>N/A</Tag>)
                    }
                }
            }
        ];

        const tables = <DynamicTable
            dataSource={lookup_customerList || []}
            columns={columns}
            isLoading={isLoading}
            pagination={pagination}
            rowSelection={rowSelection}
            moduleKey="lookup_customer"
            onChangeTable={(pagination, sorter) => this.onChangeTable(pagination, sorter)}
        />

        return (
            <div>
                <PageTitle routes={routes} title="Khách hàng" />
                <div>
                    <TableActionBar
                        isShowAddButton={checkPermission('customer', 'create')}
                        isShowDeleteButton={checkPermission('customer', 'remove')}
                        onAdd={() => this.openAddForm(true)}
                        onDelete={() => this.onRemove()}
                        disabled={!selectedRowKeys.length ? true : false}
                        showFilter={true}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                        placeholderSearch="Mã, tên, 3 số cuối sđt của khách hàng"
                        activeFilter={
                            query.created_date_start ||
                            query.appointment_schedule_start ||
                            query.customer_class_id ||
                            query.customer_status_id ||
                            query.interested_service_id ||
                            query.selected_service_id ||
                            query.fb_Interactive_type_id ||
                            query.fb_Interactive_status_id ||
                            query.province_code ||
                            query.branch_id ||
                            query.customer_source ||
                            query.service_status ||
                            query.doctor_charge ||
                            query.consultant_staff
                        }
                        filters={
                            [
                                <BaseSelect
                                    options={branchActiveList || []}
                                    optionValue="id"
                                    optionLabel="name"
                                    placeholder="Chọn cơ sở"
                                    className="w-100"
                                    onChange={(value) => this.onChangeFilter('branch_id', value)}
                                    defaultValue={query.branch_id ? parseInt(query.branch_id) : null}
                                />,
                                <RangePicker
                                    format="DD/MM/YYYY"
                                    placeholder={['Ngày tạo', 'Đến ngày']}
                                    className="w-100"
                                    onChange={(value) => this.onChangeFilter('created_at', value)}
                                    defaultValue={[query.created_date_start ? moment(query.created_date_start, 'YYYY-MM-DD') : null, query.created_date_end ? moment(query.created_date_end, 'YYYY-MM-DD') : null]}
                                />,
                                <RangePicker
                                    format="DD/MM/YYYY"
                                    placeholder={['Lịch hẹn', 'Đến ngày']}
                                    className="w-100"
                                    onChange={(value) => this.onChangeFilter('appointment_schedule', value)}
                                    defaultValue={[query.appointment_schedule_start ? moment(query.appointment_schedule_start, 'YYYY-MM-DD') : null, query.appointment_schedule_end ? moment(query.appointment_schedule_end, 'YYYY-MM-DD') : null]}
                                />,
                                <BaseSelect
                                    options={customerClassActiveList || []}
                                    optionValue="id"
                                    optionLabel="name"
                                    showSearch
                                    placeholder="Hạng khách hàng"
                                    onChange={(value) => this.onChangeFilter('customer_class_id', value)}
                                    className="w-100"
                                    defaultValue={query.customer_class_id ? parseInt(query.customer_class_id) : null}
                                />,
                                <BaseSelect
                                    options={customerStatusActiveList || []}
                                    optionValue="id"
                                    optionLabel="name"
                                    showSearch
                                    placeholder="Trạng thái"
                                    onChange={(value) => this.onChangeFilter('customer_status_id', value)}
                                    className="w-100"
                                    defaultValue={query.customer_status_id ? parseInt(query.customer_status_id) : null}
                                />,
                                <TreeSelect
                                    showSearch
                                    className="w-100"
                                    dropdownStyle={{ maxHeight: 450, overflow: 'auto' }}
                                    treeData={service}
                                    treeNodeFilterProp='title'
                                    allowClear
                                    placeholder="Dịch vụ quan tâm"
                                    treeDefaultExpandAll
                                    defaultValue={query.interested_service_id ? parseInt(query.interested_service_id) : null}
                                    onChange={(value) => this.onChangeFilter('interested_service_id', value)}
                                />,
                                <TreeSelect
                                    showSearch
                                    className="w-100"
                                    dropdownStyle={{ maxHeight: 450, overflow: 'auto' }}
                                    treeData={service}
                                    treeNodeFilterProp='title'
                                    allowClear
                                    placeholder="Dịch vụ chốt"
                                    treeDefaultExpandAll
                                    defaultValue={query.selected_service_id ? parseInt(query.selected_service_id) : null}
                                    onChange={(value) => this.onChangeFilter('selected_service_id', value)}
                                />,
                                <BaseSelect
                                    options={CustomerServiceStatus || []}
                                    optionValue="value"
                                    optionLabel="label"
                                    showSearch
                                    placeholder="Trạng thái dịch vụ chốt"
                                    onChange={(value) => this.onChangeFilter('service_status', value)}
                                    className="w-100"
                                    defaultValue={query.service_status ? query.service_status : null}
                                />,
                                <BaseSelect
                                    options={customerInteractiveTypeActiveList || []}
                                    optionValue="id"
                                    optionLabel="name"
                                    showSearch
                                    placeholder="Loại tương tác"
                                    onChange={(value) => this.onChangeFilter('fb_Interactive_type_id', value)}
                                    className="w-100"
                                    defaultValue={query.fb_Interactive_type_id ? parseInt(query.fb_Interactive_type_id) : null}
                                />,
                                <BaseSelect
                                    options={customerInteractiveStatusActiveList || []}
                                    optionValue="id"
                                    optionLabel="name"
                                    showSearch
                                    placeholder="Trạng thái tương tác"
                                    onChange={(value) => this.onChangeFilter('fb_Interactive_status_id', value)}
                                    className="w-100"
                                    defaultValue={query.fb_Interactive_status_id ? parseInt(query.fb_Interactive_status_id) : null}
                                />,
                                <BaseSelect
                                    options={list_provinces || []}
                                    optionValue="code"
                                    optionLabel="name"
                                    showSearch
                                    placeholder="Tỉnh/Thành phố"
                                    className="w-100"
                                    onChange={(value) => {
                                        this.onChangeFilter('province_code', value);
                                        this.loadDistricts(value)
                                    }}
                                    defaultValue={query.province_code ? query.province_code : null}
                                />,
                                <BaseSelect
                                    options={customer_source_active_list || []}
                                    optionValue="id"
                                    optionLabel="name"
                                    showSearch
                                    placeholder="Nguồn khách hàng"
                                    className="w-100"
                                    onChange={(value) => this.onChangeFilter('customer_source_id', value)}
                                    defaultValue={query.customer_source_id ? parseInt(query.customer_source_id) : null}
                                />,
                                <BaseSelect
                                    options={users || []}
                                    optionValue="user_id"
                                    optionLabel="full_name"
                                    showSearch
                                    placeholder="Người tạo"
                                    className="w-100"
                                    onChange={(value) => this.onChangeFilter('created_user_id', value)}
                                    defaultValue={query.created_user_id ? parseInt(query.created_user_id) : null}
                                />,
                                <BaseSelect
                                    options={doctors || []}
                                    optionValue="user_id"
                                    optionLabel="full_name"
                                    showSearch
                                    placeholder="Bác sĩ thực hiện"
                                    className="w-100"
                                    onChange={(value) => this.onChangeFilter('doctor_charge', value)}
                                    defaultValue={query.doctor_charge ? parseInt(query.doctor_charge) : null}
                                />,
                                <BaseSelect
                                    options={users || []}
                                    optionValue="user_id"
                                    optionLabel="full_name"
                                    showSearch
                                    placeholder="Nhân viên tư vấn"
                                    className="w-100"
                                    onChange={(value) => this.onChangeFilter('consultant_staff', value)}
                                    defaultValue={query.consultant_staff ? parseInt(query.consultant_staff) : null}
                                />
                            ]
                        }
                    >
                        {
                            checkPermission('lookup_customer', 'transfer_to_staff') ? (
                                <Button icon={<UserSwitchOutlined />} className="table-button" type='primary' disabled={!selectedRowKeys.length ? true : false} onClick={() => this.setState({ visibleAssignedToStaff: true })}>Giao cho telesale</Button>
                            ) : null
                        }
                        <Button className="table-button" icon={<ReloadOutlined />} loading={isLoading} onClick={() => this.reloadData()}>Refresh</Button>
                        {
                            hasSelected ? <span>Đang chọn {selectedRowKeys.length} bản ghi</span> : ''
                        }
                    </TableActionBar>
                </div>

                <Card size='small' bodyStyle={{ padding: 0 }}>
                    <Tabs onChange={(value) => this.onChangeFilter('tab', value)} defaultActiveKey={query.tab} type="card" size="small">
                        <TabPane tab={<span style={{ fontWeight: 600 }}>Toàn bộ khách hàng</span>} key="all">
                            {tables}
                        </TabPane>
                        <TabPane tab={<span style={{ fontWeight: 600 }}>Khách hàng hôm nay</span>} key="today">
                            {tables}
                        </TabPane>
                    </Tabs>
                </Card>

                <CustomerAddForm
                    visible={isOpenAddForm}
                    onCancel={() => this.openAddForm(false)}
                />
                <CustomerDetail
                    visible={isOpenDetail}
                    currentItem={currentData}
                    reloadData={() => this.onDetail(current_id)}
                    onCancel={() => this.toggleOpenDetail(false)}
                    loadingDetail={loadingDetail}
                />
                <CustomerScheduleDetail
                    visible={isOpenScheduleDetail}
                    currentItem={currentData}
                    onCancel={() => this.setState({ isOpenScheduleDetail: false })}
                />
                <TelesaleAssignedToStaffForm
                    visible={visibleAssignedToStaff}
                    customer_ids={selectedRowKeys}
                    onCancel={() => this.onCancel()}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        lookup_customerList: state.LookupCustomerReducer.lookup_customerList,
        pagination: state.LookupCustomerReducer.pagination,
        customerClassActiveList: state.CustomerClassReducer.customerClassActiveList,
        customerStatusActiveList: state.CustomerStatusReducer.customerStatusActiveList,
        service_active_list: state.ServiceReducer.service_active_list,
        customerInteractiveTypeActiveList: state.CustomerInteractiveTypeReducer.customerInteractiveTypeActiveList,
        customerInteractiveStatusActiveList: state.CustomerInteractiveStatusReducer.customerInteractiveStatusActiveList,
        list_provinces: state.RegionsReducer.list_provinces,
        customer_source_active_list: state.CustomerSourceReducer.customer_source_active_list,
        branchActiveList: state.BranchReducer.branchActiveList,
        users: state.config.users,
        doctors: state.config.doctors,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getListLookupCustomer: (filter) => dispatch(getListLookupCustomer(filter)),
        getSpecificCustomer: (id) => dispatch(getSpecificCustomer(id)),
        removeCustomer: (ids) => dispatch(removeCustomer(ids)),
        getListProvinces: () => dispatch(getListProvinces()),
        getCustomerSourceActive: () => dispatch(getCustomerSourceActive()),
        getBranchActive: () => dispatch(getBranchActive())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'LookupCustomerList' })(LookupCustomerList));

