import api from '../../utils/api';
import {
    GET_LIST_COMMISSION_REVENUE,
    GET_SPECIFIC_COMMISSION_REVENUE,
    UPDATE_SPECIFIC_COMMISSION_REVENUE,
    CREATE_NEW_COMMISSION_REVENUE,
    REMOVE_COMMISSION_REVENUE,
} from './types';
import { NotificationManager } from 'react-notifications';

export const getCommissionRevenue = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/commission-revenue', { params: filter }).then(res => {
            dispatch({ type: GET_LIST_COMMISSION_REVENUE, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getSpecificCommissionRevenue = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/commission-revenue/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_COMMISSION_REVENUE, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createCommissionRevenue = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/commission-revenue', data).then(res => {
            dispatch({ type: CREATE_NEW_COMMISSION_REVENUE, payload: res.data.data });
            NotificationManager.success("Thêm mới bản ghi thành công!");
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updateCommissionRevenue = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/commission-revenue/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_COMMISSION_REVENUE, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updateAssignment = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/commission-revenue/assignment/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_COMMISSION_REVENUE, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            //NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeCommissionRevenue = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/commission-revenue`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_COMMISSION_REVENUE, payload: ids });
            NotificationManager.success("Xoá bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}
