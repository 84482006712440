import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import Home from './home';
import UserPermission from './user/UserPermission';
import UserList from './user/UserList';
import Profile from './auth/Profile';
import Config from './config';
import ServiceList from './services/ServiceList';
import CustomerList from './customer/CustomerList';
import CustomerClassList from './customer-class/CustomerClassList';
import CustomerStatusList from './customer-status/CustomerStatusList';
import CustomerInteractiveStatusList from './customer-interactive-status/CustomerInteractiveStatusList';
import CustomerInteractiveTypeList from './customer-interactive-type/CustomerInteractiveTypeList';
import PageManagerList from './page-manager/PageManagerList';
import messenger from './messenger';
import BranchList from './branch/BranchList';
import RoleGroup from './role-group/RoleGroupList';
import RoleList from './role/RoleList';
import ListDiseasesList from './list-diseases/ListDiseasesList';
import BloodTypeList from './blood-type/BloodTypeList';
import CustomerServicesList from './customer-services/CustomerServicesList';
import ProductList from './product/ProductList';
import VoucherList from './voucher/VoucherList';
import ProductGroupList from './product-group/ProductGroupList';
import ProductSupplierList from './product-supplier/ProductSupplierList';
import ProductWarehouseList from './product-warehouse/ProductWarehouseList';
import ProductReceiptImportList from './product-receipt-import/ProductReceiptImportList';
import CustomerSourceList from './customer-source/CustomerSourceList';
import PrivilegeError from './error/PrivilegeError';
import CustomerScheduleList from './customer-schedule/CustomerScheduleList';
import ProductReceiptExportList from './product-receipt-export/ProductReceiptExportList';
import UnitList from './unit/UnitList';
import GuideList from './guide/GuideList';
import GuideDetail from './guide/GuideDetail';
import WorkReportList from './work-report/WorkReportList';
import TelegramList from './telegram/TelegramList';
import ProductStatusList from './product-status/ProductStatusList';
import LookupCustomerList from './lookup-customer/LookupCustomerList';
import CustomertTelesaleList from './customer-telesale/CustomerTelesaleList';
import CustomerConsultantDirectList from './customer-consultant-direct/CustomerConsultantDirectList';
import CustomerPotentialList from './customer-potential/CustomerPotentialList';
import CashReceiptList from './cash-receipt/CashReceiptList';
import PaymentVoucherList from './payment-voucher/PaymentVoucherList';
import RewardSpinList from './reward-spin/RewardSpinList';
import RewardSpinConfig from './reward-spin/RewardSpinConfig';
import RewardSpinCustomerHasList from './reward-spin/RewardSpinCustomerHasList';
import PostsList from './posts/PostsList';
import ViewPhonenumberList from './view-phonenumber/ViewPhonenumberList';
import ProjectList from './project/ProjectList';
import ProjectBasedJobList from './project-based-job/ProjectBasedJobList';
import DoctorAssistantStatusList from './doctor-assistant-status/DoctorAssistantStatusList';
import DentalPatientWaitingList from './dental-patient-waiting/DentalPatientWaitingList';
import FunctionalAreaList from './functional-area/FunctionalAreaList';
import FunctionalList from './functional/FunctionalList';
import JobsList from './jobs/JobsList';
import ShiftSchedulingList from './shift-scheduling/ShiftSchedulingList';
import HistoryList from './history/HistoryList';
import CashBookList from './cash-book/CashBookList';
import CustDocAssistList from './cust-doc-assist/CustDocAssistList';
import CardTypeList from './card-type/CardTypeList';
import CardReleaseTrackingList from './card-release-tracking/CardReleaseTrackingList';
import CardIssuedCountList from './card-issued-count/CardIssuedCountList';
import CardPendingCustomerList from './card-pending-customer/CardPendingCustomerList';
//import Stringee from './stringee';
import CustomerData from './customer-data/CustomerData';
import RelationshipTypeList from './relationship-types/RelationshipTypeList';
import MediaList from './media/MediaList';
import MediaLibraryList from './media-library/MediaList';
import { Typography } from 'antd';
import MediaCategoriesList from './media-categories/MediaCategoriesList';
import DocumentCategoriesList from './document-categories/DocumentCategoriesList';
import DocumentsList from './documents/DocumentsList';
import CustomerGroupList from './customer-group/CustomerGroupList';
import CommissionRuleList from './commission-rule/CommissionRuleList';
import CommissionRevenueList from './commission-revenue/CommissionRevenueList';
import CommissionList from './commission/CommissionList';

const { Title } = Typography;

const ProtectedRoute = ({ authUser, permission, component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) => {
            if (authUser) {
                let availablePermissions = Object.keys(authUser.permissions);
                let isAccess = false;
                if (authUser.role_code == 'ADMIN') isAccess = true;
                else {
                    if (!permission || availablePermissions.indexOf(permission) >= 0) {
                        isAccess = true;
                    }
                }

                if (isAccess) return <Component {...props} />;
                else return (
                    <div className="text-center mt-4">
                        <Title>Permission access is denied</Title>
                    </div>
                )
            } else {
                return <Redirect to='/login' />;
            }
        }}
    />
)

const routes = [
    {
        path: '/',
        component: Home,
        permission: null
    },
    {
        path: '/error/403', // Không có quyền truy cập
        component: PrivilegeError,
        permission: null
    },
    {
        path: '/profile',
        component: Profile,
        permission: null
    },
    {
        path: '/users',
        component: UserList,
        permission: 'users'
    },
    {
        path: '/permissions',
        component: UserPermission,
        permission: 'permissions'
    },
    // config
    {
        path: '/config',
        component: Config,
        permission: 'config'
    },
    {
        path: '/service',
        component: ServiceList,
        permission: 'service'
    },
    {
        path: '/customer',
        component: CustomerList,
        permission: 'customer'
    },
    {
        path: '/customer-class',
        component: CustomerClassList,
        permission: 'customer_class'
    },
    {
        path: '/customer-status',
        component: CustomerStatusList,
        permission: 'customer_status'
    },
    {
        path: '/customer-interactive-status',
        component: CustomerInteractiveStatusList,
        permission: 'customer_interactive_status'
    },
    {
        path: '/customer-interactive-type',
        component: CustomerInteractiveTypeList,
        permission: 'customer_interactive_type'
    },
    {
        path: '/page-list',
        component: PageManagerList,
        permission: 'page_manager'
    },
    {
        path: '/messenger',
        component: messenger,
        permission: 'messenger'
    },
    {
        path: '/branches',
        component: BranchList,
        permission: 'branch'
    },
    {
        path: '/role-group',
        component: RoleGroup,
        permission: 'role_group'
    },
    {
        path: '/roles',
        component: RoleList,
        permission: 'roles'
    },
    {
        path: '/list-diseases',
        component: ListDiseasesList,
        permission: 'list_diseases'
    },
    {
        path: '/blood-type',
        component: BloodTypeList,
        permission: 'blood_type'
    },
    {
        path: '/customer-services',
        component: CustomerServicesList,
        permission: 'customer_services'
    },
    {
        path: '/voucher-list',
        component: VoucherList,
        permission: 'voucher'
    },
    {
        path: '/product-list',
        component: ProductList,
        permission: 'product'
    },
    {
        path: '/product-group',
        component: ProductGroupList,
        permission: 'product_group'
    },
    {
        path: '/product-supplier',
        component: ProductSupplierList,
        permission: 'product_supplier'
    },
    {
        path: '/warehouse',
        component: ProductWarehouseList,
        permission: 'product_warehouse'
    },
    {
        path: '/receipt-import',
        component: ProductReceiptImportList,
        permission: 'product_receipt_import'
    },
    {
        path: '/receipt-export',
        component: ProductReceiptExportList,
        permission: 'product_receipt_export'
    },
    {
        path: '/customer-source',
        component: CustomerSourceList,
        permission: 'customer_source'
    },
    {
        path: '/customer-schedule',
        component: CustomerScheduleList,
        permission: 'customer_schedule'
    },
    {
        path: '/unit',
        component: UnitList,
        permission: 'unit'
    },
    {
        path: '/guide',
        component: GuideList,
        permission: 'guide'
    },
    {
        path: '/guide/:id',
        component: GuideDetail,
        permission: 'guide'
    },
    {
        path: '/work-report',
        component: WorkReportList,
        permission: 'work_report'
    },
    {
        path: '/telegram',
        component: TelegramList,
        permission: 'telegram'
    },
    {
        path: '/product-status',
        component: ProductStatusList,
        permission: 'product_status'
    },
    {
        path: '/lookup-customer',
        component: LookupCustomerList,
        permission: 'lookup_customer'
    },
    {
        path: '/customer-telesale',
        component: CustomertTelesaleList,
        permission: 'customer_telesale'
    },
    {
        path: '/customer-consultant-direct',
        component: CustomerConsultantDirectList,
        permission: 'customer_consultant_direct'
    },
    {
        path: '/customer-potential',
        component: CustomerPotentialList,
        permission: 'customer_potential'
    },
    {
        path: '/cash-receipt',
        component: CashReceiptList,
        permission: 'cash_receipt'
    },
    {
        path: '/payment-voucher',
        component: PaymentVoucherList,
        permission: 'payment_voucher'
    },
    {
        path: '/reward-histories',
        component: RewardSpinList,
        permission: 'reward_spin'
    },
    {
        path: '/reward-config',
        component: RewardSpinConfig,
        permission: 'reward_spin'
    },
    {
        path: '/reward-customer-has',
        component: RewardSpinCustomerHasList,
        permission: 'reward_spin'
    },
    {
        path: '/posts',
        component: PostsList,
        permission: 'posts'
    },
    {
        path: '/view-phonenumber',
        component: ViewPhonenumberList,
        permission: 'view_phonenumber'
    },
    {
        path: '/project',
        component: ProjectList,
        permission: 'project'
    },
    {
        path: '/project-based-job',
        component: ProjectBasedJobList,
        permission: 'project_based_job'
    },
    {
        path: '/doctor-assistant-status',
        component: DoctorAssistantStatusList,
        permission: 'doctor_assistant_status'
    },
    {
        path: '/dental-patient-waiting',
        component: DentalPatientWaitingList,
        permission: 'dental_patient_cases'
    },
    {
        path: '/functional-area',
        component: FunctionalAreaList,
        permission: 'functional_area'
    },
    {
        path: '/functional',
        component: FunctionalList,
        permission: 'functional'
    },
    {
        path: '/jobs',
        component: JobsList,
        permission: 'jobs'
    },
    {
        path: '/shift-scheduling',
        component: ShiftSchedulingList,
        permission: 'shift_scheduling'
    },
    {
        path: '/history',
        component: HistoryList,
        permission: 'history'
    },
    {
        path: '/cash-book',
        component: CashBookList,
        permission: 'cash_book'
    },
    {
        path: '/customer-doctor-assistant',
        component: CustDocAssistList,
        permission: 'customer_doctor_assistant'
    },
    {
        path: '/card-type',
        component: CardTypeList,
        permission: 'card_type'
    },
    {
        path: '/card-release-tracking',
        component: CardReleaseTrackingList,
        permission: 'card_release_tracking'
    },
    {
        path: '/card-issued-count',
        component: CardIssuedCountList,
        permission: 'card_issued_count'
    },
    {
        path: '/card-pending-customers',
        component: CardPendingCustomerList,
        permission: 'card_pending_customers'
    },
    {
        path: '/customer-data',
        component: CustomerData,
        permission: 'customer_data'
    },
    {
        path: '/relationship-types',
        component: RelationshipTypeList,
        permission: 'relationship_types'
    },
    {
        path: '/media',
        component: MediaList,
        permission: 'media'
    },
    {
        path: '/media-library',
        component: MediaLibraryList,
        permission: 'media_library'
    },
    {
        path: '/media-categories',
        component: MediaCategoriesList,
        permission: 'media_categories'
    },
    {
        path: '/document-categories',
        component: DocumentCategoriesList,
        permission: 'document_categories'
    },
    {
        path: '/documents',
        component: DocumentsList,
        permission: 'documents'
    },
    {
        path: '/customer-group',
        component: CustomerGroupList,
        permission: 'customer_group'
    },
    {
        path: '/commission-rule',
        component: CommissionRuleList,
        permission: 'commission_rule'
    },
    {
        path: '/commission-revenue',
        component: CommissionRevenueList,
        permission: 'commission_revenue'
    },
    {
        path: '/commission',
        component: CommissionList,
        permission: 'commission'
    },
    // {
    //     path: '/stringee',
    //     component: Stringee,
    //     // permission: 'card_pending_customers'
    // }
];

//console.log(routes);  

class Routes extends Component {

    render() {
        var { authUser } = this.props;

        return (
            <Switch>
                {
                    routes.map((route, index) => {
                        return (
                            <ProtectedRoute exact authUser={authUser} permission={route.permission} path={route.path} component={route.component} key={index} />
                        )
                    })
                }
            </Switch>
        )
    }
}

function mapStateToProps(state) {
    //console.log(state);
    return {
        authUser: state.auth.authUser
    }
}

export default connect(mapStateToProps, null)(Routes);

