import React, { useEffect, useState } from 'react';
import { Input, Button, Popconfirm, DatePicker, Card, Row, Col, Form, Table, Divider, Alert } from "antd";
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

const TreatmentSheetItems = (props) => {

    const { form } = props;
    const [data, setData] = useState([]);

    useEffect(() => {
        setData([{
            date_time: moment(),
            illness_evolution: "",
            doctor_instructions: ""
        }]);
        form.setFieldsValue({ treatment_sheet_items: data });
    }, [form]);

    const columns = [
        {
            title: "Ngày giờ",
            dataIndex: "date_time",
            width: '180px',
            render: (_, record, index) => (
                <Form.Item name={[index, "date_time"]} initialValue={record.date_time} rules={[{ required: true, message: "Bắt buộc nhập" }]}>
                    <DatePicker
                        style={{ width: '100%' }}
                        showTime={{ format: 'HH:mm' }}
                        format="DD/MM/YYYY HH:mm"
                        placeholder='Chọn ngày giờ'
                        allowClear={false}
                    />
                </Form.Item>
            )
        },
        {
            title: <>Diễn biến bệnh<span className='ant-form-item-extra-custom'>(Viết diễn biến theo cấu trúc như SOAP)</span></>,
            dataIndex: "illness_evolution",
            render: (_, record, index) => (
                <Form.Item name={[index, "illness_evolution"]} rules={[{ required: true, message: "Bắt buộc nhập" }]}>
                    <Input.TextArea rows={1} placeholder="Nhập diễn biến bệnh" />
                </Form.Item>
            )
        },
        {
            title: "Chỉ định",
            dataIndex: "doctor_instructions",
            render: (_, record, index) => (
                <Form.Item name={[index, "doctor_instructions"]} rules={[{ required: true, message: "Bắt buộc nhập" }]}>
                    <Input.TextArea rows={1} placeholder="Nhập y lệnh" />
                </Form.Item>
            )
        },
        {
            title: "#",
            dataIndex: "actions",
            width: '30px',
            align: "center",
            render: (_, __, index) => (
                <Popconfirm title="Xóa dòng này?" onConfirm={() => removeRow(index)} placement="topLeft">
                    <DeleteOutlined className='delete-icon' />
                </Popconfirm>
            )
        }
    ];


    const addRow = () => {
        const newRow = { date_time: moment(), illness_evolution: "", doctor_instructions: "" };
        setData([...data, newRow]);
    };

    const removeRow = (index) => {
        setData(data.filter((_, i) => i !== index));
    };

    return (
        <div style={{ paddingBottom: 100 }}>
            <Row gutter={16}>
                <Col xs={24} md={8}>
                    <Form.Item
                        label="Khoa"
                        name="treatment_department_name"
                        rules={[{ required: true, message: 'Bắt buộc' }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                    <Form.Item
                        label="Phòng"
                        name="treatment_hospital_room"
                        rules={[{ required: true, message: 'Bắt buộc' }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                    <Form.Item
                        label="Giường"
                        name="treatment_bed_number"
                        rules={[{ required: true, message: 'Bắt buộc' }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} md={24}>
                    <Form.Item
                        label="Chẩn đoán"
                        name="treatment_diagnosis"
                        rules={[{ required: true, message: 'Bắt buộc' }]}
                    >
                        <Input.TextArea rows={1} />
                    </Form.Item>
                </Col>
                <Col xs={24} md={24}>
                    <Form.Item
                        label="Chẩn đoán phân biệt"
                        name="treatment_differential_diagnosis"
                        rules={[{ required: true, message: 'Bắt buộc' }]}
                    >
                        <Input.TextArea rows={1} />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16} className='mt-5'>
                <Col xs={24} md={24}>
                    <Form.List name="treatment_sheet_items">
                        {() => (
                            <>
                                <Table
                                    dataSource={data}
                                    columns={columns}
                                    rowKey={(record, index) => index}
                                    pagination={false}
                                    size='small'
                                    bordered={false}
                                    locale={{ emptyText: "Chưa có dữ liệu" }}
                                    scroll={{
                                        x: 'max-content'
                                    }}
                                />
                                <Button type="primary" size='default' className='w-100 mt-5' onClick={addRow} icon={<PlusOutlined />}>
                                    Thêm dòng mới
                                </Button>
                            </>
                        )}
                    </Form.List>
                </Col>
            </Row>
            <Row gutter={16} className='mt-5'>
                <Col xs={24} md={24}>
                    <Alert
                        message="Hướng dẫn cách ghi chép theo cấu trúc (SOAP"
                        description={<>
                            - S (Hỏi bệnh): ghi lại các thông tin của người bệnh tự khai như triệu chứng, bệnh sử, bối cảnh xuất hiện bệnh, tiến triển…<br />
                            - O (Kết quả khám): ghi lại các thông tin do bác sỹ thăm khám như các dấu hiệu sinh tồn, các kết quả xét nghiệm...<br />
                            - A (Đánh giá): Đánh giá, phân tích kết quả và chẩn đoán trên cơ sở thông tin tự khai của người bệnh và kết quả khám bệnh.<br />
                            -  P (Kế hoạch điều trị): tóm tắt tình hình, diễn biến bệnh, đưa ra nhận định, đưa ra hướng xử trí tiếp theo.<br />
                            - Chỉ định: cụ thể hóa kế hoạch điều trị như các vấn đề cần theo dõi (theo dõi thân nhiệt, nhịp thở, huyết áp...), các loại thuốc sử dụng, các thủ thuật cần làm...
                        </>}
                        type="success"
                    />
                </Col>
            </Row>
        </div>
    );
};

export default TreatmentSheetItems;