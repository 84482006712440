import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Drawer, Descriptions, Divider, Row, Col, Table, Typography, Image, Space, Tag, Card, Alert } from 'antd'
import { checkPermission } from '../../utils/permission';
import { isMobile } from 'react-device-detect';
import NumberFormat from 'react-number-format';
import HtmlParser from 'react-html-parser';
import { isValidDate, RenderCustomerServiceStatus, repeatPhoneNumber } from '../../utils/helpers';
import HandlePhoneNumber from '../../components/HandlePhoneNumber';

const { Paragraph } = Typography;

class CustomerServicesDetail extends Component {
    constructor() {
        super();
        this.state = {

        };
    }

    render() {
        var { visible, currentItem } = this.props;

        const consultant_columns = [
            {
                title: 'Nhân viên tư vấn',
                dataIndex: 'consultant_user',
                key: 'consultant_user',
                width: '30%'
            },
            {
                title: 'Thời điểm tư vấn',
                dataIndex: 'consultant_date',
                key: 'consultant_date',
                width: '30%',
                render: (text, record) => {
                    return (
                        <div>{isValidDate(text)}</div>
                    )
                }
            },
            {
                title: 'Ghi chú',
                dataIndex: 'note',
                key: 'note',
                width: '40%'
            }
        ];

        const examination_visit_columns = [
            {
                title: 'Thời điểm khám',
                dataIndex: 'time',
                key: 'time',
                width: '20%',
                render: (text, record) => {
                    return (
                        <div>{isValidDate(text)}</div>
                    )
                }
            },
            {
                title: 'Bác sĩ khám',
                dataIndex: 'doctor_charge',
                key: 'doctor_charge',
                width: '25%'
            },
            {
                title: 'Phụ tá',
                dataIndex: 'assistant_doctor',
                key: 'assistant_doctor',
                width: '25%'
            },
            {
                title: 'Ghi chú',
                dataIndex: 'note',
                key: 'note',
                width: '30%'
            }
        ];

        return (
            <>
                <Drawer
                    visible={visible}
                    title='Chi tiết dịch vụ'
                    onClose={() => this.props.onCancel()}
                    maskClosable={false}
                    width={isMobile ? '100%' : '50%'}
                >
                    {visible ? (
                        <>
                            {
                                currentItem && currentItem.type == 'QUANTAM' ? (
                                    <div>
                                        <Row gutter={16}>
                                            <Col xs={24} md={24}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Tên khách hàng">
                                                        {currentItem ? currentItem.customer_name : null}
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Divider className="no-margin" />
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Mã khách hàng">
                                                        <Paragraph style={{ margin: 0 }} copyable={
                                                            {
                                                                text: currentItem ? currentItem.customer_code : null,
                                                                tooltips: false
                                                            }
                                                        }>
                                                            <Tag color='#1890ff'>{currentItem ? currentItem.customer_code : null}</Tag>
                                                        </Paragraph>
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Divider className="no-margin" />
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Số điện thoại">
                                                        {currentItem ? (
                                                            <HandlePhoneNumber
                                                                permission={checkPermission('customer', 'phonenumber')}
                                                                inputPinCode={checkPermission('customer', 'input_pin_code')}
                                                                customer_id={currentItem.customer_id}
                                                                hiddenPhoneNumber={repeatPhoneNumber(currentItem?.customer_phone)}
                                                                fullPhoneNumber={currentItem?.customer_phone}
                                                            />
                                                        ) : null}
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Divider className="no-margin" />
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Nguồn khách hàng">
                                                        {currentItem ? currentItem.customer_source : null}
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Divider className="no-margin" />
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Tên dịch vụ">
                                                        {currentItem ? currentItem.service_name : null}
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Divider className="no-margin" />
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Loại dịch vụ">
                                                        <Tag color="#ffb406">Dịch vụ quan tâm</Tag>
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Divider className="no-margin" />
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Tình trạng hiện tại">
                                                        {currentItem ? currentItem.current_status : null}
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Divider className="no-margin" />
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Ngày tạo dịch vụ">
                                                        {currentItem ? isValidDate(currentItem.created_at) : null}
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Divider className="no-margin" />
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Người tạo">
                                                        {currentItem ? currentItem.created_user : null}
                                                    </Descriptions.Item>
                                                </Descriptions>
                                            </Col>
                                        </Row>
                                        <Row gutter={16} className='mt-3'>
                                            <Col xs={24} md={24}>
                                                <Alert
                                                    message={<span style={{ fontWeight: '600' }}>Ghi chú</span>}
                                                    description={currentItem ? HtmlParser(currentItem.note) : null}
                                                    type="info"
                                                    className='fix-alert-element'
                                                />
                                            </Col>
                                        </Row>
                                        <Table
                                            className='mt-4'
                                            size='small'
                                            dataSource={currentItem.consultant || []}
                                            columns={consultant_columns}
                                            pagination={false}
                                            locale={{ emptyText: "Chưa có dữ liệu" }}
                                        />
                                    </div>
                                ) : null
                            }
                            {
                                currentItem && currentItem.type == 'CHOT' ? (
                                    <div>
                                        <Row gutter={16}>
                                            <Col xs={24} md={24}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Tên khách hàng">
                                                        {currentItem ? currentItem.customer_name : null}
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Divider className="no-margin" />
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Mã khách hàng">
                                                        <Paragraph style={{ margin: 0 }} copyable={
                                                            {
                                                                text: currentItem ? currentItem.customer_code : null,
                                                                tooltips: false
                                                            }
                                                        }>
                                                            <Tag color='#1890ff'>{currentItem ? currentItem.customer_code : null}</Tag>
                                                        </Paragraph>
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Divider className="no-margin" />
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Số điện thoại">
                                                        {currentItem ? (
                                                            <HandlePhoneNumber
                                                                permission={checkPermission('customer', 'phonenumber')}
                                                                inputPinCode={checkPermission('customer', 'input_pin_code')}
                                                                customer_id={currentItem.customer_id}
                                                                hiddenPhoneNumber={repeatPhoneNumber(currentItem?.customer_phone)}
                                                                fullPhoneNumber={currentItem?.customer_phone}
                                                            />
                                                        ) : null}
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Divider className="no-margin" />
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Nguồn khách hàng">
                                                        {currentItem ? currentItem.customer_source : null}
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Divider className="no-margin" />
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Tên dịch vụ">
                                                        {currentItem ? currentItem.service_name : null}
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Divider className="no-margin" />
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Loại dịch vụ">
                                                        <Tag color='#03C03C'>Dịch vụ chốt</Tag>
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Divider className="no-margin" />
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Trạng thái">
                                                        {RenderCustomerServiceStatus(currentItem ? currentItem.status : null)}
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Divider className="no-margin" />
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Giá gốc">
                                                        <NumberFormat value={currentItem ? currentItem.regular_price : 0} displayType={'text'} suffix="đ" thousandSeparator={true} />
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Divider className="no-margin" />
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Giá bán">
                                                        <NumberFormat value={currentItem ? currentItem.sale_price : 0} displayType={'text'} suffix="đ" thousandSeparator={true} />
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Divider className="no-margin" />
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Ngày chốt dịch vụ">
                                                        {currentItem ? isValidDate(currentItem.selected_at, false) : null}
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Divider className="no-margin" />
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Ngày tạo dịch vụ">
                                                        {currentItem ? isValidDate(currentItem.created_at) : null}
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Divider className="no-margin" />
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Người tạo">
                                                        {currentItem ? currentItem.created_user : null}
                                                    </Descriptions.Item>
                                                </Descriptions>
                                            </Col>
                                        </Row>
                                        <Row gutter={16} className='mt-3'>
                                            <Col xs={24} md={24}>
                                                <Alert
                                                    message={<span style={{ fontWeight: '600' }}>Ghi chú</span>}
                                                    description={currentItem ? HtmlParser(currentItem.note) : null}
                                                    type="info"
                                                    className='fix-alert-element'
                                                />
                                            </Col>
                                        </Row>
                                        <Row gutter={16} className='mt-4'>
                                            <Col xs={24} md={12} className='mb-2'>
                                                <Card size='small' className='border-dashed'>
                                                    <h6 className='h6-title'>Ảnh before</h6>
                                                    {
                                                        Array.isArray(currentItem?.condition_before) && currentItem.condition_before.length ? (
                                                            <Image.PreviewGroup>
                                                                <Space wrap>
                                                                    {
                                                                        currentItem.condition_before.map((item, index) => {
                                                                            return (
                                                                                <Image
                                                                                    key={index}
                                                                                    height={40}
                                                                                    width={40}
                                                                                    src={item?.src}
                                                                                    style={{
                                                                                        border: '1px solid #dedede',
                                                                                        objectFit: 'cover',
                                                                                        objectPosition: 'center',
                                                                                    }}
                                                                                />
                                                                            )
                                                                        })
                                                                    }
                                                                </Space>
                                                            </Image.PreviewGroup>
                                                        ) : null
                                                    }
                                                </Card>
                                            </Col>
                                            <Col xs={24} md={12}>
                                                <Card size='small' className='border-dashed'>
                                                    <h6 className='h6-title'>Ảnh after</h6>
                                                    {
                                                        Array.isArray(currentItem.condition_after) && currentItem.condition_after.length ? (
                                                            <Image.PreviewGroup>
                                                                <Space wrap>
                                                                    {
                                                                        currentItem.condition_after.map((item, index) => {
                                                                            return (
                                                                                <Image
                                                                                    key={index}
                                                                                    height={40}
                                                                                    width={40}
                                                                                    src={item?.src}
                                                                                    style={{
                                                                                        border: '1px solid #dedede',
                                                                                        objectFit: 'cover',
                                                                                        objectPosition: 'center',
                                                                                    }}
                                                                                />
                                                                            )
                                                                        })
                                                                    }
                                                                </Space>
                                                            </Image.PreviewGroup>
                                                        ) : null
                                                    }
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Table
                                            className='mt-4'
                                            size='small'
                                            dataSource={currentItem.examination_visit || []}
                                            columns={examination_visit_columns}
                                            pagination={false}
                                            locale={{ emptyText: "Chưa có dữ liệu" }}
                                        />
                                    </div>
                                ) : null
                            }
                        </>

                    ) : null}

                </Drawer>
            </>
        )
    }
}
function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CustomerServicesDetail' })(CustomerServicesDetail));
