import React, { useEffect, useState } from 'react';
import { Input, Button, Popconfirm, Table, Form, Radio, InputNumber } from "antd";
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

const PatientAllergyInfo = (props) => {
    const { form } = props;
    const [occurrenceVisible, setOccurrenceVisible] = useState({});
    const [data, setData] = useState([]);

    useEffect(() => {
        setData([
            { content: "Loại thuốc hoặc dị nguyên nào đã gây ra dị ứng?", allergen_name: "", occurrence_count: "", has_occurrence: "no", clinical_signs: "", isNew: false },
            { content: "Dị ứng với loại côn trùng nào?", allergen_name: "", occurrence_count: "", has_occurrence: "no", clinical_signs: "", isNew: false },
            { content: "Dị ứng với loại thực phẩm nào?", allergen_name: "", occurrence_count: "", has_occurrence: "no", clinical_signs: "", isNew: false },
            { content: "Dị ứng với các tác nhân nào: phấn hoa, bụi nhà, hóa chất, mỹ phẩm...?", allergen_name: "", occurrence_count: "", has_occurrence: "no", clinical_signs: "", isNew: false },
            { content: "Tiền sử cá nhân có bệnh dị ứng nào? (viêm mũi dị ứng, hen phế quản...)", allergen_name: "", occurrence_count: "", has_occurrence: "no", clinical_signs: "", isNew: false },
            { content: "Tiền sử gia đình có bệnh dị ứng nào? (Bố mẹ, con, anh chị em ruột, có ai bị các bệnh dị ứng trên không)", allergen_name: "", occurrence_count: "", has_occurrence: "no", clinical_signs: "", isNew: false }
        ]);
        form.setFieldsValue({ PatientAllergyInfo: data });
    }, [form]);

    const columns = [
        {
            title: "STT",
            dataIndex: "key",
            width: '50px',
            align: "center",
            render: (_, record, index) => (
                <span>{index + 1}</span>
            )
        },
        {
            title: "Nội dung",
            dataIndex: "content",
            width: '250px',
            render: (_, record, index) => (
                record.isNew ? (
                    <Form.Item name={[index, "content"]}>
                        <Input placeholder="Nhập nội dung" />
                    </Form.Item>
                ) : (
                    <span>
                        {record.content}
                        <Form.Item name={[index, "content"]} initialValue={record.content} hidden>
                            <Input />
                        </Form.Item>
                    </span>
                )
            )
        },
        {
            title: "Tên thuốc, dị nguyên",
            dataIndex: "allergen_name",
            width: '300px',
            render: (_, record, index) => (
                <Form.Item name={[index, "allergen_name"]} rules={[{ required: true, message: "Bắt buộc nhập" }]}>
                    <Input.TextArea rows={1} placeholder="Nhập tên thuốc, dị nguyên" />
                </Form.Item>
            )
        },
        {
            title: "Dị ứng?",
            dataIndex: "has_occurrence",
            width: '90px',
            render: (_, record, index) => (
                <Form.Item name={[index, "has_occurrence"]} initialValue={record.has_occurrence}>
                    <Radio.Group
                        onChange={(e) => {
                            setOccurrenceVisible(prev => ({ ...prev, [index]: e.target.value === "yes" }));
                        }}
                    >
                        <Radio value="yes">Có</Radio>
                        <Radio value="no">Không</Radio>
                    </Radio.Group>
                </Form.Item>
            )
        },
        {
            title: "Số lần",
            dataIndex: "occurrence_count",
            width: '150px',
            render: (_, record, index) => (
                occurrenceVisible[index] && (
                    <Form.Item name={[index, "occurrence_count"]} rules={[{ required: true, message: "Bắt buộc nhập" }]}>
                        <InputNumber min={1} className='w-100' placeholder="Nhập số lần" />
                    </Form.Item>
                )
            )
        },
        {
            title: "Biểu hiện lâm sàng - xử trí",
            dataIndex: "clinical_signs",
            width: '300px',
            render: (_, record, index) => (
                <Form.Item name={[index, "clinical_signs"]} rules={[{ required: true, message: "Bắt buộc nhập" }]}>
                    <Input.TextArea rows={1} placeholder="Nhập biểu hiện lâm sàng" />
                </Form.Item>
            )
        },
        {
            title: "#",
            dataIndex: "actions",
            width: '50px',
            align: "center",
            render: (_, __, index) => (
                <Popconfirm title="Xóa dòng này?" onConfirm={() => removeRow(index)} placement="topLeft">
                    <DeleteOutlined className='delete-icon' />
                </Popconfirm>
            )
        }
    ];


    const addRow = () => {
        const newRow = { content: "", allergen_name: "", occurrence_count: "", has_occurrence: "no", clinical_signs: "", isNew: true };
        setData([...data, newRow]);
    };

    const removeRow = (index) => {
        setData(data.filter((_, i) => i !== index));
        setOccurrenceVisible(prev => {
            const newState = { ...prev };
            delete newState[index];
            return newState;
        });
    };

    return (
        <div style={{ paddingBottom: 100 }}>
            <Form.List name="PatientAllergyInfo">
                {() => (
                    <>
                        <Table
                            dataSource={data}
                            columns={columns}
                            rowKey={(record, index) => index}
                            pagination={false}
                            size='small'
                            bordered={false}
                            scroll={{
                                x: 'max-content'
                            }}
                        />
                        <Button type="primary" size='default' className='w-100 mt-5' onClick={addRow} icon={<PlusOutlined />}>
                            Thêm dòng mới
                        </Button>
                    </>
                )}
            </Form.List>
        </div>
    );
};

export default PatientAllergyInfo;
