import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Drawer, Table, Popover, Divider, Modal, Button } from 'antd';
import { isMobile } from 'react-device-detect';
import { isValidDate, RenderPaymentMethods, RenderPaymentStatus } from '../../utils/helpers';
import { DeleteOutlined, DownOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import NumberFormat from 'react-number-format';
import { checkPermission } from '../../utils/permission';
import { removeCustomerPayment, getSpecificCustomerPayment } from '../../redux/actions/CustomerPayment';
import CustomerPaymentForm from './CustomerPaymentForm';
import ExpandableText from '../../components/ExpandableText';

const { confirm } = Modal;

class CustomerPayment extends Component {
    constructor() {
        super();
        this.state = {
            isOpenForm: false,
            currentItem: null
        };
    }

    toggleOpenForm(isOpenForm, currentItem = null) {
        this.setState({ isOpenForm: isOpenForm, currentItem: currentItem });
    }

    onEdit(id) {
        this.props.getSpecificCustomerPayment(id).then(res => {
            this.toggleOpenForm(true, res);
        })
    }

    onRemove(id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá bản ghi này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.removeCustomerPayment(id).then(() => {
                this.reloadData();
                setTimeout(() => {
                    Modal.destroyAll();
                }, 800);
            }),
            onCancel() { },
        })
    }

    onClose() {
        this.props.onCancel();
    }

    reloadData() {
        this.props.reloadCustomerPayment();
        this.props.reloadCustomer();
    }

    render() {
        const { isOpenForm, currentItem } = this.state;
        var { visible, data, customer_id } = this.props;

        const columns = [
            {
                title: '#',
                width: '80px',
                align: 'center',
                render: (text, record) => (
                    <div>
                        {
                            checkPermission('customer_payment', 'update') ? (
                                <>
                                    <EditOutlined className='color-primary' style={{ cursor: 'pointer' }} onClick={() => this.onEdit(record.id)} />
                                    <Divider type='vertical' />
                                </>
                            ) : null
                        }
                        {
                            checkPermission('customer_payment', 'remove') ? (
                                <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} onClick={() => this.onRemove([record.id])} />
                            ) : null
                        }
                    </div>
                )
            },
            {
                title: 'Hình thức',
                dataIndex: 'payment_method',
                key: 'payment_method',
                width: '140px',
                render: (text, record) => {
                    return (
                        <div>
                            {RenderPaymentMethods(record.payment_method)}
                        </div>
                    )
                }
            },
            {
                title: 'Ngày bắt đầu',
                dataIndex: 'start_at',
                key: 'start_at',
                width: '120px',
                render: (text, record) => {
                    return (
                        <>
                            {isValidDate(text, false)}
                        </>
                    )
                }
            },
            {
                title: 'Dịch vụ áp dụng',
                dataIndex: 'payment_services',
                key: 'payment_services',
                render: (_, record) => {
                    const services = record?.payment_services;
                    if (!services?.length) return null;

                    const content = services.map((item, index) => (
                        <div key={index} style={{ padding: '5px 0' }}>
                            {item.customer_service?.service?.name}
                        </div>
                    ));

                    return (
                        <div className='item-action-btn'>
                            <Popover placement="right" content={content}>
                                {services[0]?.customer_service?.service?.name} <DownOutlined />
                            </Popover>
                        </div>
                    );
                }
            },
            {
                title: 'Tổng số tiền',
                dataIndex: 'total_amount',
                key: 'total_amount',
                width: '120px',
                render: (text, record) => {
                    return (
                        <div>
                            <NumberFormat value={text} displayType={'text'} suffix="đ" thousandSeparator={true} />
                        </div>
                    )
                }
            },
            {
                title: 'Số tháng',
                dataIndex: 'installment_months',
                key: 'installment_months',
                width: '80px',
                render: (text, record) => {
                    return (
                        <div>
                            {text}
                        </div>
                    )
                }
            },
            {
                title: 'Trả góp/tháng',
                dataIndex: 'monthly_payment',
                key: 'monthly_payment',
                width: '120px',
                render: (text, record) => {
                    return (
                        <div>
                            <NumberFormat value={text} displayType={'text'} suffix="đ" thousandSeparator={true} />
                        </div>
                    )
                }
            },
            {
                title: 'Trạng thái',
                dataIndex: 'status',
                key: 'status',
                width: '100px',
                render: (text, record) => {
                    return (
                        <div>{RenderPaymentStatus(text)}</div>
                    )
                }
            },
            {
                title: 'Ghi chú',
                dataIndex: 'note',
                key: 'note',
                width: '180px',
                render: (text, record) => {
                    return (
                        <ExpandableText text={text} rows={1} />
                    )
                }
            },
            {
                title: 'Ngày tạo',
                dataIndex: 'created_at',
                key: 'created_at',
                width: '150px',
                render: (text, record) => {
                    return (
                        <>
                            {isValidDate(text, true)}
                        </>
                    )
                }
            },
            {
                title: 'Người tạo',
                dataIndex: 'created_user',
                key: 'created_user',
                width: '200px',
                render: (text, record) => {
                    return (
                        <>{record?.created_user?.full_name} </>
                    )
                }
            },
        ];

        return (
            <>
                <Drawer
                    visible={visible}
                    title="Hình thức thanh toán"
                    onOk={(e) => this.submit(e)}
                    onClose={() => this.onClose()}
                    maskClosable={false}
                    width={isMobile ? '100%' : '75%'}
                >
                    {visible ?
                        <div>
                            <Button
                                type='primary'
                                icon={<PlusOutlined />}
                                onClick={() => this.toggleOpenForm(true)}
                                className='mb-2'
                                disabled={!checkPermission('customer_payment', 'add')}
                            >Thêm mới</Button>
                            <Table
                                size='small'
                                rowKey='key'
                                tableLayout="auto"
                                columns={columns}
                                dataSource={data || []}
                                pagination={false}
                                scroll={{
                                    x: 'max-content'
                                }}
                                className='mb-4 w-100'
                                locale={{ emptyText: "Chưa có dữ liệu" }}
                            />
                        </div>
                        : null}
                </Drawer>

                <CustomerPaymentForm
                    visible={isOpenForm}
                    customer_id={customer_id}
                    data={data}
                    onCancel={() => this.toggleOpenForm(false)}
                    currentItem={currentItem}
                    reloadData={() => this.reloadData()}
                />
            </>
        )
    }
}

function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
        removeCustomerPayment: (ids) => dispatch(removeCustomerPayment(ids)),
        getSpecificCustomerPayment: (id) => dispatch(getSpecificCustomerPayment(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPayment);
