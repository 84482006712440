import React, { useState } from 'react';
import { Drawer, Space, Button, Tabs, Form, message } from 'antd';
import { isMobile } from 'react-device-detect';
import TreatmentSheetItems from './items/TreatmentSheetItems';
import PatientAllergyInfo from './items/PatientAllergyInfo';
import MedicalOutpatient from './items/MedicalOutpatient';
import Interaction from './items/Interaction';


const CustomerForm = (props) => {

    const { visible, onCancel } = props;
    const [form] = Form.useForm();
    const formRef = React.createRef();
    const [isLoading, setIsLoading] = useState(false);

    const initialValues = {
        province_code: null,
        district_code: null,
        wards_code: null
    }

    const handleSubmit = (values) => {
        console.log("Dữ liệu submit:", values);
        // Xử lý dữ liệu tại đây
    };

    const handleClose = () => {
        onCancel();
        form.resetFields();
        setIsLoading(false);
    }

    const handleError = (error) => {
        console.log(error);
        message.error('Vui lòng điền đầy đủ các trường bắt buộc');
    }

    return (
        <div>

            <Drawer
                visible={visible}
                title="Thêm bệnh nhân"
                onClose={handleClose}
                closable={false}
                maskClosable={false}
                width={isMobile ? '100%' : '85%'}
                forceRender={true}
                bodyStyle={{ paddingTop: 0 }}
                {...(isMobile
                    ? {
                        footer: (
                            <div style={{ textAlign: "right" }}>
                                <Space>
                                    <Button key="back" onClick={handleClose}>
                                        Đóng
                                    </Button>
                                    <Button loading={isLoading} key="submit" type="primary" onClick={() => form.submit()}>
                                        Lưu
                                    </Button>
                                </Space>
                            </div>
                        ),
                    }
                    : {
                        extra: (
                            <div style={{ textAlign: "right" }}>
                                <Space>
                                    <Button key="back" onClick={handleClose}>
                                        Đóng
                                    </Button>
                                    <Button loading={isLoading} key="submit" type="primary" onClick={() => form.submit()}>
                                        Lưu
                                    </Button>
                                </Space>
                            </div>
                        ),
                    })}
            >

                <Form
                    form={form}
                    layout="vertical"
                    className='form-customer'
                    initialValues={initialValues}
                    ref={formRef}
                    onFinish={(values) => handleSubmit(values)}
                    onFinishFailed={(values) => handleError(values)}
                >
                    <Tabs
                        type="line"
                        size="small"
                        defaultActiveKey="1"
                        tabBarStyle={{
                            position: "sticky",
                            top: 0,
                            zIndex: 1000,
                            background: "white",
                        }}
                        className='tabs-custom-customer'
                    >
                        <Tabs.TabPane tab={<span style={{ fontWeight: 500 }}>BỆNH ÁN NGOẠI TRÚ</span>} key="1" forceRender>
                            <MedicalOutpatient form={form} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={<span style={{ fontWeight: 500 }}>THEO DÕI ĐIỀU TRỊ</span>} key="3" forceRender>
                            <TreatmentSheetItems form={form} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={<span style={{ fontWeight: 500 }}>PHIẾU KHAI THÁC TIỀN SỬ DỊ ỨNG</span>} key="4" forceRender>
                            <PatientAllergyInfo form={form} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={<span style={{ fontWeight: 500 }}>THÔNG TIN KHÁC</span>} key="5" forceRender>
                            <Interaction form={form} />
                        </Tabs.TabPane>
                    </Tabs>
                </Form>
            </Drawer>

        </div>
    );
}

export default CustomerForm;