import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Drawer, Button, Input, Space, Row, Col, DatePicker, InputNumber } from 'antd';
import { isMobile } from 'react-device-detect';
import BaseSelect from '../../components/Elements/BaseSelect';
import { _newLine, isValidDateForm, PaymentMethods, PaymentStatus } from '../../utils/helpers';
import { createCustomerPayment, updateCustomerPayment } from '../../redux/actions/CustomerPayment';
import BaseRadioList from '../../components/Elements/BaseRadios';
import { getCustomerServices } from '../../redux/actions/CustomerServicesActions'
import moment from 'moment';

class CustomerPaymentForm extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            payment_method: null
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps?.customer_id != this.props?.customer_id) {
            this.props.getCustomerServices(this.props?.customer_id);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.currentItem != this.props.currentItem) {
            this.setState({
                payment_method: nextProps?.currentItem?.payment_method,
            });
        }
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isLoading: true });
                try {
                    values.customer_id = this.props?.customer_id;
                    if (this.props.currentItem) {
                        await this.props.updateCustomerPayment(this.props.currentItem?.id, values);
                    } else {
                        await this.props.createCustomerPayment(values);
                    }
                    console.log(values)

                    this.props.reloadData();
                    this.onClose();
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    handleChangePaymentMethod = (value) => {
        this.setState({ payment_method: value });
    }

    onClose() {
        this.props.onCancel();
        this.props.form.resetFields();
        this.setState({ isLoading: false, payment_method: null });
    }

    render() {
        var { isLoading, payment_method } = this.state;
        var { visible, currentItem, customer_services } = this.props;
        const { getFieldDecorator } = this.props.form;

        return (
            <Drawer
                visible={visible}
                title={`${currentItem ? "Cập nhật" : "Thêm mới"}`}
                onOk={(e) => this.submit(e)}
                onClose={() => this.onClose()}
                maskClosable={false}
                width={isMobile ? '100%' : '50%'}
                footer={
                    <div style={{ textAlign: 'right', }}>
                        <Space>
                            <Button key="back" onClick={() => this.onClose()}>
                                Huỷ
                            </Button>
                            <Button
                                loading={isLoading}
                                key="submit"
                                type="primary"
                                onClick={(e) => this.submit(e)}
                            >
                                {currentItem ? "Cập nhật" : "Thêm mới"}
                            </Button>
                        </Space>
                    </div>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Form.Item label="Hình thức thanh toán">
                                    {getFieldDecorator('payment_method', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vui lòng chọn!',
                                            },
                                        ],
                                        initialValue: currentItem?.payment_method ?? null
                                    })(
                                        <BaseSelect
                                            options={PaymentMethods || []}
                                            optionValue="value"
                                            optionLabel="label"
                                            showSearch
                                            placeholder="Chọn một bản ghi"
                                            style={{ width: '100%' }}
                                            onChange={(value) => this.handleChangePaymentMethod(value)}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Ngày bắt đầu">
                                    {getFieldDecorator('start_at', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentItem && currentItem?.start_at ? isValidDateForm(currentItem?.start_at, false) : moment()
                                    })(
                                        <DatePicker
                                            format="DD/MM/YYYY"
                                            style={{ width: '100%' }}
                                            placeholder="Ngày bắt đầu"
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            {["tragoptainhakhoa", "tragoptainganhang"].includes(payment_method) ? (
                                <>
                                    <Col xs={24} md={12}>
                                        <Form.Item label="Tổng số tiền">
                                            {getFieldDecorator('total_amount', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Vui lòng chọn!',
                                                    },
                                                ],
                                                initialValue: currentItem?.total_amount ?? null
                                            })(<InputNumber step={1000} min={0} className='w-100' formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />)}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Form.Item label="Số tháng">
                                            {getFieldDecorator('installment_months', {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: 'Vui lòng chọn!',
                                                    },
                                                ],
                                                initialValue: currentItem?.installment_months ?? null
                                            })(<InputNumber min={0} className='w-100' />)}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Form.Item label="Số tiền trả hàng tháng">
                                            {getFieldDecorator('monthly_payment', {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: 'Vui lòng chọn!',
                                                    },
                                                ],
                                                initialValue: currentItem?.monthly_payment ?? null
                                            })(<InputNumber step={1000} min={0} className='w-100' formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />)}
                                        </Form.Item>
                                    </Col>
                                </>
                            ) : null}

                            <Col xs={24} md={24}>
                                <Form.Item label="Trạng thái">
                                    {getFieldDecorator('status', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vui lòng chọn!',
                                            },
                                        ],
                                        initialValue: currentItem?.status ?? null
                                    })(
                                        <BaseRadioList
                                            options={PaymentStatus || []}
                                            optionValue="value"
                                            optionLabel="label"
                                            showSearch
                                            placeholder="Chọn một bản ghi"
                                            style={{ width: '100%' }}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Dịch vụ áp dụng">
                                    {getFieldDecorator('customer_service_ids', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Vui lòng chọn!',
                                            },
                                        ],
                                        initialValue: currentItem?.customer_service_ids ?? []
                                    })(
                                        <BaseSelect
                                            options={customer_services || []}
                                            optionValue="id"
                                            optionLabel="service_name"
                                            showSearch
                                            mode='multiple'
                                            placeholder="Chọn một hoặc nhiều bản ghi"
                                            style={{ width: '100%' }}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Ghi chú">
                                    {getFieldDecorator("note", {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: _newLine(currentItem?.note) ?? null
                                    })(<Input.TextArea rows={3} placeholder="Ghi chú" />)}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    : null}
            </Drawer>
        )
    }
}

function mapStateToProps(state) {
    return {
        customer_services: state.CustomerServicesReducer.customer_services
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createCustomerPayment: (data) => dispatch(createCustomerPayment(data)),
        updateCustomerPayment: (id, data) => dispatch(updateCustomerPayment(id, data)),
        getCustomerServices: (customer_id) => dispatch(getCustomerServices(customer_id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CustomerPaymentForm' })(CustomerPaymentForm));
