import React, { useEffect, useState } from 'react';
import { Checkbox, Col, Collapse, DatePicker, Form, Input, Row, Tag, Typography } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import InputMask from "react-input-mask";
import moment from 'moment';
import { getListProvinces, getListDistricts, getListWards } from '../../../redux/actions/RegionsActions';
import { useDispatch, useSelector } from 'react-redux';
import BaseRadioList from '../../../components/Elements/BaseRadios';
import { customerGender, customerTypes } from '../../../utils/helpers';
import BaseSelect from '../../../components/Elements/BaseSelect';
import CheckDetailForm from './CheckDetailForm';
import TreatmentCourseItems from './TreatmentCourseItems';

const { Title } = Typography;

const MedicalOutpatient = (props) => {

    const { form } = props;

    const [activeKey, setActiveKey] = useState(['1', '2', '3', '4']);
    const [districts, setDistricts] = useState([]);
    const [wards, setWards] = useState([]);

    const provinces = useSelector((state) => state.RegionsReducer.list_provinces);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getListProvinces());
    }, []);

    const handleBirthdayChange = (e) => {
        const value = e.target.value;
        const date = moment(value, "DD/MM/YYYY", true);

        if (date.isValid()) {
            const age = moment().diff(date, "years");
            form.setFieldsValue({ age });
        } else {
            form.setFieldsValue({ age: "" });
        }
    };

    const validatePhone = (rule, value, callback) => {
        const phoneRegex = /^\d{3}-\d{3}-\d{4}$/; // Kiểm tra số điện thoại theo dạng 000-000-0000
        if (value && !phoneRegex.test(value)) {
            callback("Số điện thoại không hợp lệ!");
        } else {
            callback();
        }
    };

    const loadDistricts = async (province_code) => {
        if (province_code) {
            try {
                const res = await dispatch(getListDistricts({ province_code }));
                form.setFieldsValue({ district_code: undefined, wards_code: undefined });
                setDistricts(res.data);
                setWards([]);
            } catch (error) {
                console.error("Lỗi khi tải danh sách quận/huyện:", error);
            }
        }
    };

    const loadWards = async (district_code) => {
        if (district_code) {
            try {
                const res = await dispatch(getListWards({ district_code })); // Gọi action
                form.setFieldsValue({ wards_code: undefined });
                setWards(res.data);
            } catch (error) {
                console.error("Lỗi khi tải danh sách phường/xã:", error);
            }
        }
    };

    const handleExpand = (key, event) => {
        event.stopPropagation(); // Ngăn chặn sự kiện lan sang header

        setActiveKey((prevActiveKey) =>
            prevActiveKey.includes(key)
                ? prevActiveKey.filter((k) => k !== key) // Nếu key đã có, loại bỏ nó
                : [...prevActiveKey, key] // Nếu chưa có, thêm vào danh sách
        );
    };

    return (
        <div>
            <Collapse
                ghost
                activeKey={activeKey}
                expandIconPosition="end"
                className="customer-detail-collapse mt-3"
                expandIcon={({ isActive, panelKey }) => (
                    <DownOutlined
                        rotate={isActive ? 180 : 0}
                        onClick={(e) => handleExpand(panelKey, e)}
                        style={{
                            color: '#fff',
                            fontSize: 'larger',
                            padding: '5px',
                            borderRadius: '5px',
                            background: '#183940'
                        }}
                    />
                )}
            >
                <Collapse.Panel
                    header={<><Tag color='#0b2b33'>A</Tag> THÔNG TIN CHUNG</>}
                    key="1"
                    forceRender
                    className="customer-detail-collapse-panel"
                >
                    <Row gutter={16}>
                        <Col xs={24} md={6}>
                            <Form.Item
                                label="1. Họ và tên"
                                name='full_name'
                                rules={[{ required: true, message: "Bắt buộc nhập" }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={6}>
                            <Form.Item
                                label="2. Ngày sinh"
                                name='birthday'
                                rules={[{ required: true, message: "Bắt buộc nhập" }]}
                            >
                                <InputMask
                                    mask="99/99/9999"
                                    placeholder="dd/mm/yyyy"
                                    onChange={handleBirthdayChange}
                                >
                                    {(inputProps) => <Input {...inputProps} />}
                                </InputMask>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={6}>
                            <Form.Item
                                label="Tuổi"
                                name='age'
                                rules={[{ required: true, message: "Bắt buộc nhập" }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={6}>
                            <Form.Item
                                label="3. Giới tính"
                                name='gender'
                                rules={[{ required: true, message: "Bắt buộc chọn" }]}
                            >
                                <BaseRadioList
                                    options={customerGender || []}
                                    name="gender"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={24} md={6}>
                            <Form.Item
                                label="4. Số điện thoại"
                                name="phone"
                                rules={[
                                    { required: true, message: "Bắt buộc nhập" },
                                    { validator: validatePhone }
                                ]}
                            >
                                <InputMask mask="999-999-9999" placeholder="Nhập số điện thoại">
                                    {(inputProps) => <Input {...inputProps} />}
                                </InputMask>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={6}>
                            <Form.Item
                                label="5. Nghề nghiệp"
                                name="occupation"
                                rules={[{ required: true, message: "Bắt buộc nhập" }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={6}>
                            <Form.Item
                                label="6. Dân tộc"
                                name="ethnicity"
                                rules={[{ required: true, message: "Bắt buộc nhập" }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={6}>
                            <Form.Item
                                label="7. Quốc tích"
                                name="nationality"
                                rules={[{ required: false, message: "Bắt buộc nhập" }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={6}>
                            <Form.Item
                                label="8. Tỉnh, thành phố"
                                name="province_code"
                                rules={[{ required: true, message: "Bắt buộc chọn" }]}
                            >
                                <BaseSelect
                                    options={provinces || []}
                                    optionValue="code"
                                    optionLabel="name"
                                    showSearch
                                    placeholder="Chọn một bản ghi"
                                    style={{ width: '100%' }}
                                    onChange={(code) => loadDistricts(code)}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={6}>
                            <Form.Item
                                label="Huyện (Q. Tx)"
                                name="district_code"
                                rules={[{ required: true, message: "Bắt buộc chọn" }]}
                            >
                                <BaseSelect
                                    options={districts || []}
                                    optionValue="code"
                                    optionLabel="name"
                                    showSearch
                                    placeholder="Chọn một bản ghi"
                                    style={{ width: '100%' }}
                                    onChange={(code) => loadWards(code)}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={6}>
                            <Form.Item
                                label="Xã, phường"
                                name="wards_code"
                                rules={[{ required: true, message: "Bắt buộc chọn" }]}
                            >
                                <BaseSelect
                                    options={wards || []}
                                    optionValue="code"
                                    optionLabel="name"
                                    showSearch
                                    placeholder="Chọn một bản ghi"
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={6}>
                            <Form.Item
                                label="Thôn, phố"
                                name="hamlet"
                                rules={[{ required: true, message: "Bắt buộc nhập" }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label="Địa chỉ: Số nhà"
                                name="house_number"
                                rules={[{ required: true, message: "Bắt buộc nhập" }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label="9. Đối tượng"
                                name="customer_type"
                                rules={[{ required: true, message: "Bắt buộc chọn" }]}
                            >
                                <BaseRadioList
                                    options={customerTypes || []}
                                    optionValue="value"
                                    optionLabel="label"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label="10. Số thẻ BHYT"
                                name="bhyt_number"
                                rules={[{ required: false, message: "Bắt buộc nhập" }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label="11. Số Căn cước/Hộ chiếu/Số định danh cá nhân"
                                name="identity_number"
                                rules={[{ required: true, message: "Bắt buộc chọn" }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={18}>
                            <Form.Item
                                label="12. Họ và tên thân nhân của NB khi cần báo tin"
                                name="relative_info"
                                rules={[{ required: false, message: "Bắt buộc nhập" }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={6}>
                            <Form.Item
                                label="Điện thoại"
                                name="relative_info_phone"
                                rules={[
                                    { required: false, message: "Bắt buộc nhập" },
                                    { validator: validatePhone }
                                ]}
                            >
                                <InputMask mask="999-999-9999" placeholder="Nhập số điện thoại">
                                    {(inputProps) => <Input {...inputProps} />}
                                </InputMask>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item
                                label="Ghi chú"
                                name="note"
                                rules={[{ required: false, message: 'Bắt buộc nhập' }]}
                            >
                                <Input.TextArea rows={2} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Collapse.Panel>
                <Collapse.Panel
                    header={<><Tag color='#0b2b33'>B</Tag> THÔNG TIN KHÁM BỆNH</>}
                    key="2"
                    forceRender
                    className="customer-detail-collapse-panel"
                >
                    <Title level={5}>I. LÝ DO VÀO VIỆN, VẤN ĐỀ SỨC KHỎE</Title>
                    <Row gutter={16}>
                        <Col xs={24} md={24}>
                            <Form.Item
                                name="admission_reason"
                                rules={[{ required: true, message: 'Bắt buộc nhập' }]}
                            >
                                <Input.TextArea rows={1} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Title className='mt-3' level={5}>II. HỎI BỆNH</Title>
                    <Row gutter={16}>
                        <Col xs={24} md={24}>
                            <Form.Item
                                label={<>1. Quá trình bệnh lý và diễn biến lâm sàng <span className='ant-form-item-extra-custom'>(Đặc điểm khởi phát, các triệu chứng lâm sàng, diễn biến bệnh...)</span></>}
                                name="medical_history"
                                rules={[{ required: true, message: 'Bắt buộc nhập' }]}
                            >
                                <Input.TextArea rows={1} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item label="2. Tiền sử có liên quan đến tình trạng bệnh lần này:">
                                <CheckDetailForm
                                    listName="related_medical_history"
                                    data={[
                                        { key: "allergy", label: "Dị ứng", occurrence_detail: "", has_occurrence: "no" },
                                        { key: "personal", label: "Bản thân", occurrence_detail: "", has_occurrence: "no" },
                                        { key: "family", label: "Gia đình", occurrence_detail: "", has_occurrence: "no" }
                                    ]}
                                    columns={[{
                                        title: "",
                                        dataIndex: "label",
                                        width: '100px',
                                        render: (_, record, index) => (
                                            <>
                                                <span> {record.label}</span >
                                                <Form.Item name={[index, "label"]} initialValue={record.label} hidden>
                                                    <Input />
                                                </Form.Item>
                                            </>
                                        )
                                    }]}
                                    requiredColWidth='200px'
                                    radioLabels={{ yes: "Có, ghi rõ", no: "Không" }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Title className='mt-3' level={5}>III. KHÁM BỆNH</Title>
                    <Row gutter={16}>
                        <Col xs={24} md={4}>
                            <Form.Item
                                label="Mạch (lần/phút)"
                                name="pulse_bpm"
                                rules={[{ required: true, message: 'Bắt buộc nhập' }]}
                            >
                                <Input placeholder='lần/phút' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={4}>
                            <Form.Item
                                label="Nhiệt độ (°C)"
                                name="temp_celsius"
                                rules={[{ required: true, message: 'Bắt buộc nhập' }]}
                            >
                                <Input placeholder='°C' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={4}>
                            <Form.Item
                                label="Huyết áp (.../...mmHg)"
                                name="bp_reading"
                                rules={[{ required: true, message: 'Bắt buộc nhập' }]}
                            >
                                <Input placeholder='.../...mmHg' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={4}>
                            <Form.Item
                                label="Nhịp thở (lần/phút)"
                                name="respiratory_rate"
                                rules={[{ required: true, message: 'Bắt buộc nhập' }]}
                            >
                                <Input placeholder='.....lần/phút' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={4}>
                            <Form.Item
                                label="Cân nặng (kg)"
                                name="body_weight"
                                rules={[{ required: true, message: 'Bắt buộc nhập' }]}
                            >
                                <Input placeholder='kg' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={24} md={24}>
                            <Form.Item label="1. Toàn thân">
                                <CheckDetailForm
                                    listName="full_body_checkup"
                                    data={[{ occurrence_detail: "", has_occurrence: "no" }]}
                                    radioLabels={{ yes: "Bất thường, ghi rõ", no: "Bình thường" }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item label="2. Khám chuyên khoa">
                                <CheckDetailForm
                                    listName="specialized_exam"
                                    data={[
                                        { key: "extraoral_exams", label: "Ngoài miệng", occurrence_detail: "", has_occurrence: "no" },
                                        { key: "intraoral_tests", label: "Trong miệng", occurrence_detail: "", has_occurrence: "no" },
                                    ]}
                                    columns={[{
                                        title: "",
                                        dataIndex: "label",
                                        width: '100px',
                                        render: (_, record, index) => (
                                            <>
                                                <span> {record.label}</span >
                                                <Form.Item name={[index, "label"]} initialValue={record.label} hidden>
                                                    <Input />
                                                </Form.Item>
                                            </>
                                        )
                                    }]}
                                    radioLabels={{ yes: "Bất thường, ghi rõ", no: "Bình thường" }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item label="3. Các xét nghiệm, cận lâm sàng cần làm">
                                <CheckDetailForm
                                    listName="required_tests"
                                    data={[{ occurrence_detail: "", has_occurrence: "no" }]}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item
                                label="4. Tóm tắt bệnh án"
                                name="diagnostic_summary"
                                rules={[{ required: true, message: 'Bắt buộc nhập' }]}
                            >
                                <Input.TextArea rows={2} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Title className='mt-3' level={5}>IV. CHẨN ĐOÁN (Tên bệnh kèm theo mã ICD)</Title>
                    <Row gutter={16}>
                        <Col xs={24} md={24}>
                            <Form.Item label="Bệnh chính">
                                <Form.Item
                                    name="main_disease"
                                    rules={[{ required: true, message: "Bắt buộc nhập" }]}
                                    style={{ display: 'inline-block', width: 'calc(70% - 8px)' }}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name="main_disease_code"
                                    rules={[{ required: true, message: "Bắt buộc nhập" }]}
                                    style={{ display: 'inline-block', width: 'calc(30% - 8px)', margin: '0 8px' }}
                                >
                                    <Input placeholder='Mã ICD' />
                                </Form.Item>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item label="Bệnh kèm theo (nếu có):">
                                <Form.Item
                                    name="associated_diseases"
                                    rules={[{ required: false, message: "Bắt buộc nhập" }]}
                                    style={{ display: 'inline-block', width: 'calc(70% - 8px)' }}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name="associated_diseases_code"
                                    rules={[{ required: false, message: "Bắt buộc nhập" }]}
                                    style={{ display: 'inline-block', width: 'calc(30% - 8px)', margin: '0 8px' }}
                                >
                                    <Input placeholder='Mã ICD' />
                                </Form.Item>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item label="Biến chứng (nếu có):">
                                <Form.Item
                                    name="complications"
                                    rules={[{ required: false, message: "Bắt buộc nhập" }]}
                                    style={{ display: 'inline-block', width: 'calc(70% - 8px)' }}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name="complications_code"
                                    rules={[{ required: false, message: "Bắt buộc nhập" }]}
                                    style={{ display: 'inline-block', width: 'calc(30% - 8px)', margin: '0 8px' }}
                                >
                                    <Input placeholder='Mã ICD' />
                                </Form.Item>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Title className='mt-3' level={5}>V. KẾ HOẠCH ĐIỀU TRỊ</Title>
                    <Row gutter={16}>
                        <Col xs={24} md={24}>
                            <Form.Item
                                name="treatment_plan"
                                rules={[{ required: true, message: "Bắt buộc nhập" }]}
                            >
                                <Input.TextArea rows={2} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Title className='mt-3' level={5}>VI. QUÁ TRÌNH ĐIỀU TRỊ </Title>
                    <Row gutter={16}>
                        <Col xs={24} md={24}>
                            <TreatmentCourseItems form={form} />
                        </Col>
                    </Row>
                    <Title className='mt-3' level={5}>VII. THỜI GIAN ĐIỀU TRỊ </Title>
                    <Row gutter={16}>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label="Từ ngày"
                                name="treatment_date_start"
                                rules={[{ required: true, message: 'Bắt buộc nhập' }]}
                            >
                                <DatePicker
                                    style={{ width: '100%' }}
                                    format="DD/MM/YYYY"
                                    placeholder="dd/mm/yyyy"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label="Đến ngày"
                                name="treatment_date_end"
                                rules={[{ required: true, message: 'Bắt buộc nhập' }]}
                            >
                                <DatePicker
                                    style={{ width: '100%' }}
                                    format="DD/MM/YYYY"
                                    placeholder="dd/mm/yyyy"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Collapse.Panel>
                <Collapse.Panel
                    header={<><Tag color='#0b2b33'>C</Tag>TỔNG KẾT BỆNH ÁN</>}
                    key="3"
                    forceRender
                    className="customer-detail-collapse-panel"
                >
                    <Row gutter={16} style={{ paddingBottom: 100 }}>
                        <Col xs={24} md={24}>
                            <Form.Item
                                label={<>1. Tóm tắt quá trình bệnh lý và diễn biến lâm sàng <span className='ant-form-item-extra-custom'>(Đặc điểm khởi phát, các triệu chứng lâm sàng, diễn biến bệnh...)</span></>}
                                name="disease_progress_summary"
                                rules={[{ required: true, message: 'Bắt buộc nhập' }]}
                            >
                                <Input.TextArea rows={2} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item
                                label={<>2. Những dấu hiệu lâm sàng chính được ghi nhận <span className='ant-form-item-extra-custom'>(có giá trị chẩn đoán trong quá trình điều trị)</span></>}
                                name="primary_clinical_signs"
                                rules={[{ required: true, message: 'Bắt buộc nhập' }]}
                            >
                                <Input.TextArea rows={2} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item
                                label={<>3. Tóm tắt kết quả xét nghiệm, cận lâm sàng có giá trị chẩn đoán </>}
                                name="diagnostic_test_summary"
                                rules={[{ required: true, message: 'Bắt buộc nhập' }]}
                            >
                                <Input.TextArea rows={2} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item label="4. Phương pháp điều trị (tương ứng với chẩn đoán)">
                                <CheckDetailForm
                                    listName="treatment_method"
                                    data={[
                                        { key: "internal_medicine", label: "Nội khoa", occurrence_detail: "", has_occurrence: "no" },
                                        { key: "surgery_and_procedures", label: "Phẫu thuật, thủ thuật", occurrence_detail: "", has_occurrence: "no" },
                                    ]}
                                    placeholder="Nhập phương pháp điều trị"
                                    columns={[{
                                        title: "",
                                        dataIndex: "label",
                                        width: '150px',
                                        render: (_, record, index) => (
                                            <>
                                                <span> {record.label}</span >
                                                <Form.Item name={[index, "label"]} initialValue={record.label} hidden>
                                                    <Input />
                                                </Form.Item>
                                            </>
                                        )
                                    }]}
                                    requiredColWidth='200px'
                                    radioLabels={{ yes: "Có, ghi rõ", no: "Không" }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item
                                label="5. Tình trạng ra viện"
                                name="discharge_status"
                                rules={[{ required: true, message: 'Bắt buộc chọn' }]}
                            >
                                <Checkbox.Group
                                    options={[
                                        { label: 'Khỏi', value: 'recovered' },
                                        { label: 'Đỡ', value: 'improved' },
                                        { label: 'Không thay đổi', value: 'unchanged' },
                                        { label: 'Nặng hơn', value: 'worsened' },
                                        { label: 'Tử vong', value: 'deceased' },
                                        { label: 'Tiên lượng nặng xin về', value: 'severe_deterioration' },
                                        { label: 'Khác', value: 'other' }
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item
                                label={<>6. Hướng điều trị và các chế độ tiếp theo </>}
                                name="plan_and_follow_up"
                                rules={[{ required: true, message: 'Bắt buộc nhập' }]}
                            >
                                <Input.TextArea rows={2} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Collapse.Panel>
            </Collapse>
        </div >
    )
}

export default MedicalOutpatient;