import { combineReducers } from 'redux';
import AuthReducer from './AuthReducer';
import UserReducer from "./UserReducer";
import PermissionReducer from "./PermissionReducer";
import RoleReducer from "./RoleReducer";
import ConfigReducer from "./ConfigReducer";
import ServiceReducer from './ServiceReducer';
import CustomerClassReducer from './CustomerClassReducer';
import CustomerStatusReducer from './CustomerStatusReducer';
import CustomerInteractiveStatusReducer from './CustomerInteractiveStatusReducer';
import CustomerInteractiveTypeReducer from './CustomerInteractiveTypeReducer';
import CustomerReducer from './CustomerReducer';
import LookupCustomerReducer from './LookupCustomerReducer';
import PageManagerReducer from './PageManagerReducer';
import RegionsReducer from './RegionsReducer';
import BranchReducer from './BranchReducer'
import RoleGroupReducer from './RoleGroupReducer';
import ListDiseasesReducer from './ListDiseasesReducer';
import BloodTypeReducer from './BloodTypeReducer';
import CustomerScheduleReducer from './CustomerScheduleReducer';
import CashReceiptReducer from './CashReceiptReducer';
import ProductReducer from './ProductReducer';
import VoucherReducer from './VoucherReducer';
import ProductGroupReducer from './ProductGroupReducer';
import ProductSupplierReducer from './ProductSupplierReducer';
import ProductWarehouseReducer from './ProductWarehouseReducer';
import ProductReceiptImportReducer from './ProductReceiptImportReducer';
import CustomerSourceReducer from './CustomerSourceReducer';
import ProductReceiptExportReducer from './ProductReceiptExportReducer';
import UnitReducer from './UnitReducer';
import GuideReducer from './GuideReducer';
import WorkReportReducer from './WorkReportReducer';
import TelegramReducer from './TelegramReducer';
import ProductStatusReducer from './ProductStatusReducer';
import CustomerTelesaleReducer from './CustomerTelesaleReducer';
import CustomerConsultantDirectReducer from './CustomerConsultantDirectReducer';
import CustomerPotentialReducer from './CustomerPotentialReducer';
import PaymentVoucherReducer from './PaymentVoucherReducer';
import RewardSpinReducer from './RewardSpinReducer';
import PostsReducer from './PostsReducer';
import ViewPhonenumberReducer from './ViewPhonenumberReducer';
import NotificationsReducer from './NotificationsReducer';
import ProjectReducer from './ProjectReducer';
import ProjectBasedJobReducer from './ProjectBasedJobReducer';
import DoctorAssistantStatusReducer from './DoctorAssistantStatusReducer';
import DentalPatientCasesReducer from './DentalPatientCasesReducer';
import FunctionalAreaReducer from './FunctionalAreaReducer';
import FunctionalReducer from './FunctionalReducer';
import JobsReducer from './JobsReducer';
import ShiftSchedulingReducer from './ShiftSchedulingReducer';
import CustomerMedicalReducer from './CustomerMedicalReducer';
import HistoryReducer from './HistoryReducer';
import CashBookReducer from './CashBookReducer';
import CustDocAssistReducer from './CustDocAssistReducer';
import CardTypeReducer from './CardTypeReducer';
import CardReleaseTrackingReducer from './CardReleaseTrackingReducer';
import CardIssuedCountReducer from './CardIssuedCountReducer';
import CardPendingCustomerReducer from './CardPendingCustomerReducer';
import CustomerServicesReducer from './CustomerServicesReducer';
import AssistantReducer from './AssistantReducer';
import DoctorReducer from './DoctorReducer';
import StatisticReducer from './StatisticReducer';
import RelationshipTypeReducer from './RelationshipTypeReducer';
import MediaReducer from './MediaReducer';
import MediaLibraryReducer from './MediaLibraryReducer';
import MediaCategoriesReducer from './MediaCategoriesReducer';
import DocumentCategories from './DocumentCategories';
import Documents from './Documents';
import CustomerGroup from './CustomerGroup';
import CommissionRule from './CommissionRule';
import CommissionRevenue from './CommissionRevenue';
import Commission from './Commission';

const rootReducer = combineReducers({
    auth: AuthReducer,
    user: UserReducer,
    permission: PermissionReducer,
    role: RoleReducer,
    config: ConfigReducer,
    ServiceReducer: ServiceReducer,
    CustomerReducer: CustomerReducer,
    LookupCustomerReducer: LookupCustomerReducer,
    CustomerClassReducer: CustomerClassReducer,
    CustomerStatusReducer: CustomerStatusReducer,
    CustomerInteractiveStatusReducer: CustomerInteractiveStatusReducer,
    CustomerInteractiveTypeReducer: CustomerInteractiveTypeReducer,
    PageManagerReducer: PageManagerReducer,
    RegionsReducer: RegionsReducer,
    BranchReducer: BranchReducer,
    RoleGroupReducer: RoleGroupReducer,
    ListDiseasesReducer: ListDiseasesReducer,
    BloodTypeReducer: BloodTypeReducer,
    CustomerScheduleReducer: CustomerScheduleReducer,
    CashReceiptReducer: CashReceiptReducer,
    ProductReducer: ProductReducer,
    VoucherReducer: VoucherReducer,
    ProductGroupReducer: ProductGroupReducer,
    ProductSupplierReducer: ProductSupplierReducer,
    ProductWarehouseReducer: ProductWarehouseReducer,
    ProductReceiptImportReducer: ProductReceiptImportReducer,
    CustomerSourceReducer: CustomerSourceReducer,
    ProductReceiptExportReducer: ProductReceiptExportReducer,
    UnitReducer: UnitReducer,
    WorkReportReducer: WorkReportReducer,
    Guide: GuideReducer,
    TelegramReducer: TelegramReducer,
    ProductStatusReducer: ProductStatusReducer,
    CustomerTelesaleReducer: CustomerTelesaleReducer,
    CustomerConsultantDirectReducer: CustomerConsultantDirectReducer,
    CustomerPotentialReducer: CustomerPotentialReducer,
    PaymentVoucherReducer: PaymentVoucherReducer,
    RewardSpinReducer: RewardSpinReducer,
    PostsReducer: PostsReducer,
    ViewPhonenumberReducer: ViewPhonenumberReducer,
    NotificationsReducer: NotificationsReducer,
    ProjectReducer: ProjectReducer,
    ProjectBasedJobReducer: ProjectBasedJobReducer,
    DoctorAssistantStatusReducer: DoctorAssistantStatusReducer,
    DentalPatientCasesReducer: DentalPatientCasesReducer,
    FunctionalAreaReducer: FunctionalAreaReducer,
    FunctionalReducer: FunctionalReducer,
    JobsReducer: JobsReducer,
    ShiftSchedulingReducer: ShiftSchedulingReducer,
    CustomerMedicalReducer: CustomerMedicalReducer,
    HistoryReducer: HistoryReducer,
    CashBookReducer: CashBookReducer,
    CustDocAssistReducer: CustDocAssistReducer,
    CardTypeReducer: CardTypeReducer,
    CardReleaseTrackingReducer: CardReleaseTrackingReducer,
    CardIssuedCountReducer: CardIssuedCountReducer,
    CardPendingCustomerReducer: CardPendingCustomerReducer,
    CustomerServicesReducer: CustomerServicesReducer,
    AssistantReducer: AssistantReducer,
    DoctorReducer: DoctorReducer,
    StatisticReducer: StatisticReducer,
    RelationshipTypeReducer: RelationshipTypeReducer,
    MediaReducer: MediaReducer,
    MediaLibraryReducer: MediaLibraryReducer,
    MediaCategoriesReducer: MediaCategoriesReducer,
    DocumentCategories: DocumentCategories,
    Documents: Documents,
    CustomerGroup: CustomerGroup,
    CommissionRule: CommissionRule,
    CommissionRevenue: CommissionRevenue,
    Commission: Commission
});

export default rootReducer;