import api from '../../utils/api';
import {
    GET_LIST_CUSTOMER_SCHEDULE,
    GET_SPECIFIC_CUSTOMER_SCHEDULE,
    UPDATE_SPECIFIC_CUSTOMER_SCHEDULE,
    CREATE_NEW_CUSTOMER_SCHEDULE,
    REMOVE_CUSTOMER_SCHEDULE,
    UPDATE_SPECIFIC_CUSTOMER_SCHEDULE_STATUS,
    TRANSFER_SCHEDULE_TO_STAFF,
    GET_SCHEDULE_LIST_BY_CUSTOMER,
    CHANGE_CUSTOMER_SCHEDULE,
    UPDATE_SPECIFIC_AFTER_SERVICE_CARE
} from './types';
import { NotificationManager } from 'react-notifications';

export const getListCustomerSchedule = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/customer-schedule', { params: filter }).then(res => {
            dispatch({ type: GET_LIST_CUSTOMER_SCHEDULE, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getSpecificCustomerSchedule = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/customer-schedule/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_CUSTOMER_SCHEDULE, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createCustomerSchedule = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/customer-schedule', data).then(res => {
            dispatch({ type: CREATE_NEW_CUSTOMER_SCHEDULE, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updateCustomerSchedule = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/customer-schedule/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_CUSTOMER_SCHEDULE, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updateCustomerScheduleStatus = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/customer-schedule/status/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_CUSTOMER_SCHEDULE_STATUS, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeCustomerSchedule = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/customer-schedule`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_CUSTOMER_SCHEDULE, payload: ids });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const transferToStaff = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/customer-schedule/transfer-to-staff', data).then(res => {
            dispatch({ type: TRANSFER_SCHEDULE_TO_STAFF, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getScheduleByCustomer = (customer_id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/customer-schedule/customer/${customer_id}`).then(res => {
            dispatch({ type: GET_SCHEDULE_LIST_BY_CUSTOMER, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const changeSchedule = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/customer-schedule/change-schedule', data).then(res => {
            dispatch({ type: CHANGE_CUSTOMER_SCHEDULE, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updateAfterServiceCare = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/customer-schedule/after-service-care/update/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_AFTER_SERVICE_CARE, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}