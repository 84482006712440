import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Button, Input, Space, Row, Col, DatePicker, Drawer } from 'antd';
import { UserOutlined } from "@ant-design/icons";
import { isMobile } from 'react-device-detect';
import BaseRadios from '../../components/Elements/BaseRadios';
import BaseSelect from '../../components/Elements/BaseSelect';
import { updateCustomerScheduleStatus } from '../../redux/actions/CustomerScheduleActions';
import { _newLine, CustomerScheduleStatus, CustomerScheduleTeleStatus, isValidDateForm } from '../../utils/helpers';

class CustomerScheduleUpdateStatusForm extends Component {
    constructor() {
        super();
        this.state = {
            require_time: false,
            switch_status: false
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.currentItem != this.props.currentItem) {
            if (nextProps?.currentItem?.status) {
                if (nextProps?.currentItem?.status == 'dagoi' || nextProps?.currentItem?.status == 'daden') {
                    this.setState({ require_time: true })
                } else {
                    this.setState({ require_time: false })
                }
                if (nextProps?.currentItem?.name == 'GOILAI') {
                    this.setState({ switch_status: true })
                } else {
                    this.setState({ switch_status: false })
                }
            }
        }
    }

    async onchangeStatus(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isLoading: true });
                try {
                    values.time_to = values.time_to ? values.time_to.format("YYYY-MM-DD HH:mm") : null;
                    await this.props.updateCustomerScheduleStatus(this.props.currentItem.id, values);
                    this.props.reloadData();
                    this.onCancel();
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onCancel() {
        this.props.form.resetFields();
        this.props.onCancel();
        this.setState({ isLoading: false });
    }

    changeStatus(value) {
        if (value == 'daden' || value == 'dagoi') {
            this.setState({ require_time: true })
        } else {
            this.setState({ require_time: false })
            this.props.form.setFieldsValue({ time_to: null })
        }
    }

    render() {
        var { isLoading, require_time, switch_status } = this.state;
        var { visible, currentItem, users, customerStatusActiveList } = this.props;
        const { getFieldDecorator } = this.props.form;

        return (
            <Drawer
                visible={visible}
                title="Cập nhật trạng thái lịch hẹn"
                onClose={() => this.onCancel()}
                maskClosable={false}
                width={isMobile ? '100%' : '40%'}
                footer={
                    <div style={{ textAlign: 'right', }}>
                        <Space>
                            <Button key="back" onClick={() => this.onCancel()}>
                                Huỷ
                            </Button>
                            <Button
                                loading={isLoading}
                                key="submit"
                                type="primary"
                                onClick={(e) => this.onchangeStatus(e)}
                            >
                                Cập nhật
                            </Button>
                        </Space>
                    </div>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={24} className='mb-3'>
                                <h6><UserOutlined /> Khách hàng: <span className='color-primary'>{currentItem?.customer_name} - {currentItem?.customer_code}</span></h6>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Form.Item label="Trạng thái">
                                    {getFieldDecorator('status', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vui lòng chọn trạng thái!',
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.status : 'chuaden'
                                    })(
                                        <BaseRadios
                                            onChange={(value) => this.changeStatus(value)}
                                            options={switch_status ? CustomerScheduleTeleStatus : CustomerScheduleStatus}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            {
                                require_time ? (
                                    <>
                                        <Col xs={24} md={24}>
                                            <Form.Item label={`Thời điểm ${switch_status ? 'gọi' : 'đến'}`}>
                                                {getFieldDecorator('time_to', {
                                                    rules: [
                                                        {
                                                            required: require_time,
                                                            message: 'Vui lòng điền!',
                                                        },
                                                    ],
                                                    initialValue: isValidDateForm(currentItem.time_to, true)
                                                })(
                                                    <DatePicker
                                                        style={{ width: '100%' }}
                                                        showTime={{ format: 'HH:mm' }}
                                                        format="DD/MM/YYYY HH:mm"
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        {
                                            currentItem && currentItem?.name == 'TUVAN' ? (
                                                <Col xs={24} md={24}>
                                                    <Form.Item label="Nhân viên tư vấn trực tiếp">
                                                        {getFieldDecorator("consultant_direct_staff_ids", {
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: "Vui lòng chọn!",
                                                                },
                                                            ],
                                                            initialValue: currentItem ? currentItem?.staff_consultant_direct_ids : []
                                                        })(
                                                            <BaseSelect
                                                                options={users ?? []}
                                                                optionValue="user_id"
                                                                optionLabel="full_name"
                                                                placeholder="Chọn 1 hoặc nhiều nhân viên tư vấn"
                                                                defaultText="Chọn nhân viên tư vấn"
                                                                showSearch={true}
                                                                mode="multiple"
                                                                className="w-100"
                                                            />
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                            ) : null
                                        }
                                    </>
                                ) : null
                            }
                            <Col xs={24} md={24}>
                                <Form.Item label='Trạng thái khách hàng'>
                                    {getFieldDecorator("customer_status_id", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.customer_status_id : null,
                                        normalize: value => value !== undefined ? value : null // Trường hợp value = undefined thì tên field vẫn được truyền lên server
                                    })(
                                        <BaseSelect
                                            options={customerStatusActiveList || []}
                                            optionValue="id"
                                            optionLabel="name"
                                            showSearch
                                            placeholder="Chọn một bản ghi"
                                            style={{ width: '100%' }}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Ghi chú">
                                    {getFieldDecorator('note', {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng điền!",
                                            },
                                        ],
                                        initialValue: currentItem ? _newLine(currentItem.note) : ""
                                    })(
                                        <Input.TextArea rows={8} />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    : null}
            </Drawer>
        )
    }
}

function mapStateToProps(state) {
    return {
        users: state.config.users,
        customerStatusActiveList: state.CustomerStatusReducer.customerStatusActiveList
    }
}

function mapDispatchToProps(dispatch) {
    return {
        updateCustomerScheduleStatus: (id, data) => dispatch(updateCustomerScheduleStatus(id, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CustomerScheduleUpdateStatusForm' })(CustomerScheduleUpdateStatusForm));
