import React, { useEffect, useState } from 'react';
import { Input, Button, Popconfirm, DatePicker, Card, Row, Col, Form, Table, Divider } from "antd";
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

const TreatmentCourseItems = (props) => {

    const { form } = props;
    const [data, setData] = useState([]);

    useEffect(() => {
        setData([{
            date: moment(),
            illness_evolution: "",
            treatment_actions: "",
            note: ""
        }]);
        form.setFieldsValue({ treatment_course_items: data });
    }, [form]);

    const columns = [
        {
            title: "Ngày",
            dataIndex: "date",
            width: '150px',
            render: (_, record, index) => (
                <Form.Item name={[index, "date"]} initialValue={record.date} rules={[{ required: true, message: "Bắt buộc nhập" }]}>
                    <DatePicker
                        style={{ width: '100%' }}
                        format="DD/MM/YYYY"
                        placeholder='Chọn ngày'
                        allowClear={false}
                    />
                </Form.Item>
            )
        },
        {
            title: "Diễn biến bệnh",
            dataIndex: "illness_evolution",
            render: (_, record, index) => (
                <Form.Item name={[index, "illness_evolution"]} rules={[{ required: true, message: "Bắt buộc nhập" }]}>
                    <Input.TextArea rows={1} placeholder="Nhập diễn biến bệnh" />
                </Form.Item>
            )
        },
        {
            title: "Xử trí",
            dataIndex: "treatment_actions",
            render: (_, record, index) => (
                <Form.Item name={[index, "treatment_actions"]} rules={[{ required: true, message: "Bắt buộc nhập" }]}>
                    <Input.TextArea rows={1} placeholder="Nhập xử trí" />
                </Form.Item>
            )
        },
        {
            title: "Ghi chú",
            dataIndex: "note",
            render: (_, record, index) => (
                <Form.Item name={[index, "note"]} rules={[{ required: false, message: "Bắt buộc nhập" }]}>
                    <Input.TextArea rows={1} placeholder="Nhập ghi chú" />
                </Form.Item>
            )
        },
        {
            title: "#",
            dataIndex: "actions",
            width: '30px',
            align: "center",
            render: (_, __, index) => (
                <Popconfirm title="Xóa dòng này?" onConfirm={() => removeRow(index)} placement="topLeft">
                    <DeleteOutlined className='delete-icon' />
                </Popconfirm>
            )
        }
    ];


    const addRow = () => {
        const newRow = {
            date: moment(),
            illness_evolution: "",
            treatment_actions: "",
            note: ""
        };
        setData([...data, newRow]);
    };

    const removeRow = (index) => {
        setData(data.filter((_, i) => i !== index));
    };

    return (
        <div>
            <Row gutter={16}>
                <Col xs={24} md={24}>
                    <Form.List name="treatment_course_items">
                        {() => (
                            <>
                                <Table
                                    dataSource={data}
                                    columns={columns}
                                    rowKey={(record, index) => index}
                                    pagination={false}
                                    size='small'
                                    bordered={false}
                                    scroll={{
                                        x: 'max-content'
                                    }}
                                    locale={{ emptyText: "Chưa có dữ liệu" }}
                                />
                                <Button type="primary" size='default' className='w-100' onClick={addRow} icon={<PlusOutlined />}>
                                    Thêm dòng mới
                                </Button>
                            </>
                        )}
                    </Form.List>
                </Col>
            </Row>
        </div>
    );
};

export default TreatmentCourseItems;