export default {
    API_URL: 'https://erp.chingodental.vn/backend/admin',
    MEDIA_URL: 'https://erp.chingodental.vn/backend',

    // ====  Api local  ======
    //API_URL: 'http://localhost/ChingoDental/v7/backend/admin',
    //MEDIA_URL: 'http://localhost/ChingoDental/v7/backend',

    // Facebook App
    APP_ID: "640973837756811", // production
    APP_SECRET: "2dd808be57bffe9956dacb26d10ccb0e", // production

    //APP_ID: "370982328868348", // devlopment
    //APP_SECRET: "2db29e43940af59e7ffa705d2d765310", // devlopment

    // Server NodeJS, nhận dữ liệu từ webhook facebook
    SERVER_NODEJS_URL: "https://webhook.chingodental.vn", // production
    //SERVER_NODEJS_URL: "http://localhost:5000", // devlopment

    // Server Webauthn
    SERVER_WEBAUTHN_URL: "https://webauthn.chingodental.vn", // production
    //SERVER_WEBAUTHN_URL: "http://localhost:3000", // devlopment

    // Graph facebook api
    GRAPH_API: "https://graph.facebook.com",
    GRAPH_VERSION: "v15.0"
}

// firebase config
export const vapidKey = "BAaRZLBfYHD01ziaqieSF3aDKUsNOBv6EeM0TIsbw28dCdGn8W_RMTy7DO3NCL_4k2zG1D2xR2Ca7ZKTT3jvqu0";

export const firebaseConfig = {
    apiKey: "AIzaSyBQLkQcq5syOCyWqv8VatK_aX-_MQBMdss",
    authDomain: "push-notification-chingodental.firebaseapp.com",
    databaseURL: "your-code",
    projectId: "push-notification-chingodental",
    storageBucket: "push-notification-chingodental.appspot.com",
    messagingSenderId: "732081962722",
    appId: "1:732081962722:web:d9012555c5db16eafb68bb"
};